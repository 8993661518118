
import React, { Component } from "react";
import { withCookies } from 'react-cookie';
import { 
        Alert, Button, InputGroup,
        FormGroup, Form, Row, Col, Grid
    } from 'react-bootstrap';
import { NavLink, Redirect } from "react-router-dom";


class Login extends Component {
    state = {
        credentials: {
            username: '',
            password: ''
        },
        inputError: false,
        loggedIn: false
    }

    inputChanged = evt => {
        let credentials = this.state.credentials;
        credentials[evt.target.name] = evt.target.value;
        this.setState({credentials: credentials});
    }

    login = evt => {   
        evt.preventDefault();
        this.setState({inputError: false});
        fetch(`${process.env.REACT_APP_API_URL}/auth/`, {
            method: 'POST',
            headers: {'Content-type': 'application/JSON'},
            body: JSON.stringify(this.state.credentials)
        }).then(resp => {
            if( resp.status === 200 ){
                return resp.json();
            } else {
                throw 'Bad login or password';
            }
        }).then(res => {    
            this.props.cookies.set('token', res.token, { path: '/' });
            this.setState({loggedIn: true});          
        })
        .catch(() => this.setState({inputError: true}));
    }

    render(){
        return (            
            <Grid>
                {this.state.loggedIn && <Redirect to="/" /> }
                <Row>            
                    <Col>
                        <div id="login-container">
                            <div className="login-title text-center">
                                <h1><strong>Вход в систему</strong></h1>
                            </div>
                            <div className="block push-bit">                
                                <Form bsStyle="horizontal" onSubmit={this.login} className="form-horizontal form-bordered form-control-borderless">
                                    
                                    {this.state.inputError? 
                                        <Col xs={12}>
                                            <Alert bsStyle='danger'>
                                                Невозможно войти с предоставленными учетными данными
                                            </Alert>
                                        </Col>
                                    :''}
                                    
                                    <FormGroup>
                                        <Col xs={12}>
                                            <InputGroup>
                                                <span className="input-group-addon"><i className="gi gi-user"></i></span>
                                                <input
                                                    type="text" id="username"
                                                    name="username" className="form-control input-lg"
                                                    autoComplete="username"
                                                    value={this.state.credentials.username} placeholder="Имя пользователя"
                                                    onChange={this.inputChanged}/>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col xs={12}>
                                            <InputGroup>
                                                <span className="input-group-addon"><i className="gi gi-asterisk"></i></span>
                                                <input 
                                                    type="password" id="password"
                                                    name="password" className="form-control input-lg"
                                                    autoComplete="current-password"
                                                    value={this.state.credentials.password} placeholder="Пароль"
                                                    onChange={this.inputChanged}/>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="form-actions">
                                        <Col xs={12} className="text-right">
                                            <Button bsStyle="primary" bsSize="sm" type="submit">
                                                <i className="fa fa-angle-right"></i> Войти
                                            </Button>
                                        </Col>                        
                                    </FormGroup>
                                    <FormGroup>
                                        <Col xs={12} className="text-center">
                                            <NavLink to="/password_reset">
                                                <small>Забыли пароль?</small>
                                            </NavLink>
                                        </Col>
                                    </FormGroup>
                                    
                                </Form>                
                            </div>            
                        </div>
                    </Col>
                </Row>
            </Grid>    
        );
    }
}
export default withCookies(Login);