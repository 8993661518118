import serializeArgs from '../tools/serializeargs';

export default function(page, sizePerPage, filters, searchText, sortField, sortOrder){

    const offset = (page - 1) * sizePerPage;
    var argobj = {limit: sizePerPage, offset: offset}

    if(filters && filters.hasOwnProperty('is_admin')){
        filters['groups__name'] = filters['is_admin'];
        delete filters['is_admin'];
    }

    if(searchText){
        argobj['search'] = searchText;
    }

    if(sortField){
        argobj['ordering'] = (sortOrder === 'asc' ? '': '-') + sortField;
    }

    for(let fk in filters){
        if(filters.hasOwnProperty(fk)){                
            argobj[fk] = filters[fk]['filterVal'];
        }
    }

    return serializeArgs(argobj);
}