import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';
import { 
    Row, Col,
    FormGroup, FormControl,
    ControlLabel, Button, Alert } from 'react-bootstrap';
import getDataRequest from '../tools/datarequest';
import ExcelDropZone from '../tools/exceldropzone';
import sp_price_import from '../../docs/sp_price_import.xls';


class SparePartsImport extends Component{

    state = {
        items: [],
        brandChoice: 1,
        datafile: null,
        currencyChoice: 'EUR',
        invalidMessages: [],
        isLoading: false
    }

    changeBrandHandler = (evt) => {
        this.setState({brandChoice: evt.target.value});
    }

    changeCurrencyHandler = (evt) => {
        this.setState({currencyChoice: evt.target.value});
    }

    changeFileHandler = (file) => {
        this.setState({datafile: file, invalidMessages: []})
    }

    handleDismiss = (e, i) => {
        let mes = [...this.state.invalidMessages];
        mes.splice(i, 1);
        this.setState({invalidMessages: mes});
    }

 
    buttonHandler = (evt) => {        
        evt.preventDefault();
        let messages = [];
        if(!this.state.brandChoice){
            messages.push({
                title: "Укажите бренд",
                message: "Поле бренд обязательно для заполнения",
                variant: "danger"
            });
        }
    
        if(!this.state.datafile){
            messages.push({
                title: "Необходим файл",
                message: "Загрузите файл для импорта",
                variant: "danger"
            });
        }
        
        if(messages.length){
            this.setState({invalidMessages: messages});
        } else {
            this.setState({invalidMessages: []});
            this.formSend();
        }       
    }

    formSend = () => {
        let data = {
            datafile: this.state.datafile,
            brand: this.state.brandChoice,
            currency: this.state.currencyChoice
        }
        this.setState({isLoading: true});
        getDataRequest(
            this.props.cookies, '/api/spareparts/dataimport/', '', 'POST', data
        ).then(
            resp => {                
                if(resp.status >= 500){
                    throw "Произошла непредвиденная ошибка. Исправьте файл импорта согласно шаблону.";
                }
                if(resp.status === 403){
                    throw "У вас нет прав на совершение данной операции.";
                }
                return resp.json();
            }
        ).then(
            res => {             
                let messages = [];
                if(res.status === 'error'){                    
                    let mes = {
                        title: res.message,
                        variant: 'danger',
                        message: (res.detail.map((m, i) => (<span key={i}>{ m }<br/></span>))),
                    }
                    messages.push(mes);                    
                } else {
                    messages.push(
                        {
                            title: "Поздравляем",
                            message: "Импорт завершен удачно"
                        }                    
                    ); 
                }
                this.setState({invalidMessages: messages});
            }
        ).catch(
            error => {                
                this.setState({ invalidMessages: [{
                    title: "Произошла ошибка", variant: 'danger', message: error}] });
            }
        ).finally(
            () =>  this.setState({isLoading: false})
        );
    }

    render(){

        const currencyList = [
            {value: 'EUR', label: '€'},
            {value: 'USD', label: '$'},
            {value: 'RUR', label: '₽'}
        ];
    
        return (
            <>
            <PageHeader title="Импорт ЗИП" subtitle="Форма импорта ЗИП" icon="gi gi-file_import"/>
            <Breadcrumbs crumbs={['ЗИП', 'Импорт']}/>
            <Row>
                <Col xs={12} md={6} mdOffset={3}>
                    <div className="block full">            
                        <div className="block-title">
                            <h2>Импорт ЗИП</h2>
                        </div>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <ControlLabel>Бренд</ControlLabel>
                                    <FormControl componentClass="select" onChange={ this.changeBrandHandler } value={this.state.brandChoice}>                                        
                                        { this.props.brands.map(b => (
                                                    <option key={ b.id } value={ b.id }>
                                                        { b.name }
                                                    </option>
                                                )
                                            )
                                        }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <ControlLabel>Валюта</ControlLabel>
                                    <FormControl componentClass="select" onChange={ this.changeCurrencyHandler } value={this.state.currencyChoice}>                                        
                                        { currencyList.map(c => (
                                                    <option key={ c.value } value={ c.value }>
                                                        { c.label }
                                                    </option>
                                                )
                                            )
                                        }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <ExcelDropZone 
                                        pushFile={ (file) => this.changeFileHandler(file) } 
                                        fileExample={sp_price_import}/>
                                </FormGroup>
                            </Col>
                            
                            {this.state.invalidMessages.length ?
                                <Col xs={12}>
                                    {this.state.invalidMessages.map((m, i) => (
                                        <Alert key={i} bsStyle={ m.variant } onDismiss={ (e) => this.handleDismiss(e, i) } style={{marginTop: '20px'}} >
                                            <h4>{ m.title }</h4>
                                            <p>{ m.message }</p>
                                        </Alert>
                                    ))}
                                </Col>
                                : ''   
                            }
                            <Col xs={12} className="text-center">
                                <Button bsSize="large" bsStyle="info"  disabled={ this.state.isLoading } onClick={ this.buttonHandler }>
                                    {this.state.isLoading ?
                                        <i className="fa fa-spinner fa-spin"></i> :
                                        <i className="fa fa-upload"></i>
                                    } Импорт
                                </Button>
                            </Col>
                                
                        </Row>
                        
                    </div>
                </Col>
            </Row>
            </>
        )
    }
}

export default withCookies(SparePartsImport);