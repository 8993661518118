import React, { useState } from 'react';
import { withCookies } from 'react-cookie';
import { Modal, Button, OverlayTrigger, Tooltip, Form, Alert } from 'react-bootstrap';
import { getPasswordState, getConfirmPasswordState } from './passvalidator';
import getDataRequest from '../tools/datarequest';
import  FieldGroup from '../tools/fieldgroup';


function ChangePasswordButton(props) {

    const [show, setShow] = useState(false);
    
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(null);
    const [confirm, setConfirm] = useState('');
    const [confirmValid, setConfirmValid] = useState(null);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false)

    const isAllValid = () => {
        let key = 'success';
        return passwordValid === key && confirmValid === key;
    }

    const handleClose = () => {      
        setShow(false);
        setPassword('');
        setPasswordValid(null);
        setConfirm('');
        setConfirmValid(null);
    }
    
    const handleShow = evt => {
        evt.currentTarget.blur();
        setShow(true);
    }

    const passwordHandler = evt => {    
        setPassword( evt.target.value );
        setPasswordValid( getPasswordState(evt.target.value) );
        setConfirmValid( getConfirmPasswordState(confirm, evt.target.value) ); 
    }

    const confirmHandler = evt => {        
        setConfirm( evt.target.value );
        setConfirmValid( getConfirmPasswordState(evt.target.value, password) );
    }

    const addRequest = (evt) => {
        let data = {password};
        setLoading(true);
        setErrors([]);
        getDataRequest(
            props.cookies, `/api/users/${props.id}/changepassword/`, '', 'POST', data
        ).then(
            resp => resp.json()   
        ).then(
            res => {                
                if(res.hasOwnProperty('id')){
                    handleClose();
                } else {                       
                    let ers = [];
                    for(let i in res){
                        ers.push(res[i]);
                    }
                    setErrors(ers); 
                }
                setLoading(false);
            }
        ).catch(error => {
            setLoading(false);
            console.log('error', error)
        });
    }

    return (
        <>
        <OverlayTrigger placement='left' overlay={<Tooltip id='tooltip-adduser'>Измененить пароль</Tooltip>}> 
            <Button bsStyle="primary" bsSize="small" onClick={ handleShow }>
                <i className="fa fa-key"></i>
            </Button>
        </OverlayTrigger>
        <Modal show={show} onHide={ handleClose }>
        <Modal.Header closeButton>
            <Modal.Title>Изменение пароля</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4 style={{marginBottom: '30px'}}>Изменение пароля для пользователя <b>{props.username}</b></h4>
            <Form>
                {errors.map((error, i) => (
                        <Alert key={i} bsStyle='danger' >{ error }</Alert>
                    )
                )}                
                <FieldGroup 
                    id="formControlsPassword"
                    label="Новый пароль"
                    type="password"
                    value={ password }
                    onChange={ passwordHandler }
                    autoComplete="new-password"
                    validationState={ passwordValid }
                    help="Пароль должен быть длиной не менее 9 символов,
                            содержать минимум одну цифру и символы разного регистра"
                />
                <FieldGroup 
                    id="formControlsPasswordConfirm"
                    label="Подтверждение пароля"
                    type="password"
                    autoComplete="new-password"
                    value={ confirm }
                    onChange={ confirmHandler }
                    validationState={ confirmValid }
                />
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={ handleClose }>Отменить</Button>
            <Button bsStyle="primary" onClick={ addRequest } disabled={ !isAllValid() || loading }>Изменить пароль</Button>
        </Modal.Footer>
        </Modal>
        
        </>
    );
}
export default withCookies(ChangePasswordButton);