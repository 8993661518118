import React, { useRef } from 'react';
import { withCookies } from 'react-cookie';
import avatar from '../../img/placeholders/avatars/avatar.jpg';
import { HiddenCropper } from "../image_cropper";


function EditableAvatar(props){
    const triggerRef = useRef();

    const handleCropped = (blob) => {
        props.onChange(props.id, {photo: {blob: blob, filename: 'image.jpeg'}});     
    }

    let img = avatar;
    if(props.img){
        if(props.img.indexOf('http') === 0 || props.img.indexOf('data:') === 0){
            img = `${props.img}`;
        } else {
            img = `${process.env.REACT_APP_API_URL}${props.img}`;
        }        
    }
    return (
        <>      
            <div className='profile_image_editor__wrap'>
                <div className={"profile_image_editor " + ( props.mode ? `profile_image_editor--${props.mode}` : '' )}
                    style={{backgroundImage: `url(${ img })` }}
                    onClick={() => triggerRef.current.trigger()}>
                    <div className="profile_image_editor__overlay" >
                        <i className="fa fa-pencil fa-4x"></i>
                    </div>
                </div>
            </div>
            <HiddenCropper
            triggerRef={triggerRef}
            onCropped={handleCropped}
            cropOptions={{ aspect: 1, maxZoom: 10 }}
            outputOptions={{ maxWidth: 100, maxHeight: 100 }}
            previewOptions={{ width: 300, height: 300 }}
            displayOptions={
                {
                    title: "Обрежьте изображение",
                    removeButtonText: "Удалить",
                    confirmButtonText: "Подтвердить"
                }
            }/>
        </>
    
    );
}
export default withCookies(EditableAvatar);