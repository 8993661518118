import serializeArgs from '../tools/serializeargs';

export default function(page, sizePerPage, filters, searchText, sortField, sortOrder){

    const offset = (page - 1) * sizePerPage;
    var argobj = {limit: sizePerPage, offset: offset}

    if(filters && filters.hasOwnProperty('brand_name')){
        filters['brand'] = {...filters['brand_name']};
        delete filters['brand_name'];
    } else if(filters && filters.hasOwnProperty('brand')){
        delete filters['brand'];
    }

    if(filters && filters.hasOwnProperty('updated')){
        if(filters['updated']['filterVal']['date']){
            let value = filters['updated']['filterVal'];
            let date = value['date'].toISOString();
            let comparator = value['comparator'];
            let key = 'updated__date' + (comparator === '>=' ? '__gte' : '__lt'); 
            date = date.slice(0, date.indexOf('T'));

            filters[key] = {'filterVal': date};
            delete filters['updated'];
        }                       
    }

    if(searchText){
        argobj['search'] = searchText;
    }
    
    if(sortField){
        argobj['ordering'] = (sortOrder === 'asc' ? '': '-') + sortField;
    }

    for(let fk in filters){
        if(filters.hasOwnProperty(fk) && fk !== 'updated'){                
            argobj[fk] = filters[fk]['filterVal'];
        }
    }

    return serializeArgs(argobj);
}