import React from 'react';
import { NavLink } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { selectFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import genBagesFormatter from '../table/badges-formatter-generator';
import genCsvBagesFormatter from '../table/badges-csv-formatter-generator';
import { Button } from 'react-bootstrap'; 
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');

export default class ColumnsManager{
    
    filters = {}

    getFilters = () => {
        return this.filters;
    }

    cleanFilters = () => {
        var filters = this.getFilters();
        for(var f in filters){
            if(filters.hasOwnProperty(f)){
                if(f !== 'updated'){
                    filters[f]('');
                } else {
                    filters[f]({date: '', comparator: null});
                }                
            }
        }
    }

    getColumns = (brands, badges, downloadPackage) => {
        const selectBrandOptions = brands.map(b => ({value: b.id, label: b.name}));
    
        const dateFormatter = (cell, row) => {
            if(cell){
                return moment(cell).format('L');
            } else {
                return moment(row.created).format('L');
            }            
        }
        
        var that = this;
        return [
            {   dataField: 'brand_name',
                text: 'Бренд',
                filter: selectFilter({
                    options: selectBrandOptions,
                    placeholder: 'Вce',
                    getFilter: (filter) => {
                        that.filters['brand_name'] = filter;
                    }
                }),              
                searchable: false,
                editable: false,
            },

            {   dataField: 'type_code',
                text: 'Название',
                editable: false,
                sort: true,
                formatter: (col, row) => <NavLink to={`/packages/${row.id}`}>{ReactHtmlParser(col)}</NavLink>
            },
            
            {   dataField: 'material_types',
                text: 'Комплекты ТО',
                editable: false,
                sort: false,
                formatter: (col, row) => {                    
                    let data = col.sort((a, b) => {                        
                        return parseInt(a.replace('PM', '')) - parseInt(b.replace('PM', ''));
                    });                    
                    return (data.map((d, i)=>(
                        <NavLink key={ i } to={`/packages/${row.id}/${d}`}
                            style={{paddingRight: '7px'}}>
                                {d}
                        </NavLink>
                    )
                ));
                }
            },

            {   dataField: 'serial_number',
                text: 'Серийный номер',
                editable: false,
                sort: true
            },
            
            {   dataField: 'updated',
                text: 'Обновлено',
                formatter: dateFormatter,
                filter: dateFilter({
                    comparators: [Comparator.GE, Comparator.LT],
                    withoutEmptyComparatorOption: true,
                    getFilter: (filter) => {
                        that.filters['updated'] = filter;
                    }
                }),
                searchable: false,
                editable: false,
                sort: true,
                csvFormatter: dateFormatter
            },

            {   dataField: 'comments_count',
                text: 'Коментарии',
                headerFormatter: () => (<i className="fa fa-comment"></i>),
                formatter: (cell, row) => {
                    if(cell === 0){
                        return '';
                    }
                    return (
                        <NavLink 
                            className="badge label-info comment_count"
                            to={`/packages/${row.id}/#comments_block`}>
                            {cell}
                        </NavLink>
                    )
                },
                searchable: false,
                editable: false,
            },

            {   dataField: 'badges',
                text: 'Статус',
                formatter: genBagesFormatter(badges),
                csvFormatter: genCsvBagesFormatter(badges),
                filter: selectFilter({
                        options: badges,
                        placeholder: 'Вce',
                        getFilter: (filter) => {
                            that.filters['badges'] = filter;
                        }
                    }),
                searchable: false,
                editable: false,
            },

            {   dataField: 'df',
                dummyField: true,
                text: 'Действия',
                formatter: (cell, row) => {
                    return (
                        <div className="btn-group" data-toggle="buttons">                            
                            <NavLink className="btn btn-default btn-sm" to={`/packages/${row.id}`}>
                                <i className="fa fa-eye"></i>
                            </NavLink>                                    
                            <Button bsSize="small" onClick={ () => downloadPackage(row.id) }>
                                <i className="fa fa-download"></i>
                            </Button>
                        </div>
                    )
                },
                searchable: false,
                editable: false,
            }, 
            
        ];
    }
}