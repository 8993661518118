import React from 'react';
import { selectFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import genBagesFormatter from '../table/badges-formatter-generator';
import genCsvBagesFormatter from '../table/badges-csv-formatter-generator';
import CommentTableCell from '../comment/table_cell';
import EditableCell from '../table/editable-cell';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');

export default class ColumnsManager{
    
    filters = {}

    getFilters = () => {
        return this.filters;
    }

    cleanFilters = () => {
        var filters = this.getFilters();
        for(var f in filters){
            if(filters.hasOwnProperty(f)){
                if(f !== 'updated'){
                    filters[f]('');
                } else {
                    filters[f]({date: '', comparator: null});
                }                
            }
        }
    }

    getColumns = (brands, badges) => {
        const selectBrandOptions = brands.map(b => ({value: b.id, label: b.name}));
    
        const dateFormatter = cell => {
            return moment(cell).format('L');
        }

        const nameRuFormatter = cell => {
            let value = cell
            if( cell.length > 0){
                value = (<><i className="fa fa-check text-success"></i>&nbsp;{cell}</>);
            }            
            return (<EditableCell cell={ value }/>)
        }

        const nameRuOptions = [
            {value: 'noempty', label: 'Переведено'},
            {value: 'empty', label: 'Не переведено'}
        ]

        var that = this;
        return [
            {   dataField: 'brand_name',
                text: 'Бренд',
                filter: selectFilter({
                    options: selectBrandOptions,
                    placeholder: 'Вce',
                    getFilter: (filter) => {
                        that.filters['brand_name'] = filter;
                    }
                }),              
                searchable: false,
                editable: false,
            },

            {   dataField: 'serie',
                text: 'Серия',
                editable: false,
                sort: true
            },

            {   dataField: 'code',
                text: 'Код',
                editable: false,
                sort: true
            },
            
            {   dataField: 'name',
                text: 'Название',
                editable: false,
                sort: true
            },
            
            {   dataField: 'price',
                text: 'Цена без НДС',
                searchable: false,
                editable: false,
                sort: true
            },
            
            {   dataField: 'currency',
                text: 'Валюта',
                editable: false,
                searchable: false
            },

            {   dataField: 'updated',
                text: 'Обновлено',
                formatter: dateFormatter,
                filter: dateFilter({
                    comparators: [Comparator.GE, Comparator.LT],
                    withoutEmptyComparatorOption: true,
                    getFilter: (filter) => {
                        that.filters['updated'] = filter;
                    }
                }),
                searchable: false,
                editable: false,
                sort: true,
                csvFormatter: dateFormatter
            },

            {   dataField: 'code_invertor',
                text: 'Код Инвертор',
                sort: true,
                editable: false,
            },

            {   dataField: 'name_short',
                text: 'Короткое название ENG',
                formatter: nameRuFormatter,                
                sort: false,
                editable: true,
                validator: (newValue, row, column) => {
                    if(newValue.length < 201){
                        return true;
                    }
                    return {valid: false, message: 'Длина строки не должна быть более 200 символов'};
                }
            },
        
            {   dataField: 'name_ru',
                text: 'Название RUS',
                formatter: nameRuFormatter,
                filter: selectFilter({
                    options: nameRuOptions,
                    placeholder: 'Вce',
                    getFilter: (filter) => {
                        that.filters['name_ru'] = filter;
                    }
                }),
                sort: true,
                editable: true,
                validator: (newValue, row, column) => {
                    if(newValue.length < 201){
                        return true;
                    }
                    return {valid: false, message: 'Длина строки не должна быть более 200 символов'};
                }
            },

            {   dataField: 'comments_count',
                text: 'Коментарии',
                headerFormatter: () => (<i className="fa fa-comment"></i>),
                formatter: (cell, row) => (<CommentTableCell cell={ cell } id={ row['id'] } source="spareparts" />),
                searchable: false,
                editable: false,
            }, 

            {   dataField: 'badges',
                text: 'Статус',
                formatter: genBagesFormatter(badges),
                csvFormatter: genCsvBagesFormatter(badges),
                filter: selectFilter({
                        options: badges,
                        placeholder: 'Вce',
                        getFilter: (filter) => {
                            that.filters['badges'] = filter;
                        }
                    }),
                searchable: false,
                editable: false,
            },   
            
        ];
    }
}