
export function getDriveStatuses(role='manager'){
    const STATE_DRAFT = 'draft';
    const STATE_CHOSE_END = 'chose';
    const STATE_CHOSE_WAITING = 'waiting_chose';
    const STATE_CHOSE_ERROR = 'chose_error';
    const STATE_NO_WORK_PROVIDED = 'no_work_provided';
    const statuses = [
        {value: STATE_DRAFT, label: 'Черновик'},        
        {value: STATE_CHOSE_WAITING, label: 'Ожидает подбора'},
        {value: STATE_CHOSE_END, label: 'Подобран'},
        {value: STATE_CHOSE_ERROR, label: 'Ошибки подбора'},
        {value: STATE_NO_WORK_PROVIDED, label: 'Работы не предусмотрены'},
    ]
    if(role === 'ingeneer'){
        return statuses.filter(item => item.value !== STATE_DRAFT);
    }
    return statuses;
}

export function getWorkStatuses(){
    const WORK_CHOSE_TO3 = 'to3';
    const WORK_CHOSE_TO6 = 'to6';
    const WORK_CHOSE_TO9 = 'to9';
    const WORK_CHOSE_TO12 = 'to12';
    const WORK_CHOSE_TO15 = 'to15';
    const WORK_CHOSE_TO18 = 'to18';
    const WORK_CHOSE_TO24 = 'to24';
    const WORK_CHOSE_ANNUALLY = 'annually'
    const WORK_CHOSE_REPLACE = 'replacement';
    const WORK_CHOSE_RETRO_MODERN = 'retro_modern';
    return [
        {value: WORK_CHOSE_TO3, label: 'ТО3'},
        {value: WORK_CHOSE_TO6, label: 'ТО6'},
        {value: WORK_CHOSE_TO9, label: 'ТО9'},
        {value: WORK_CHOSE_TO12, label: 'ТО12'},
        {value: WORK_CHOSE_TO15, label: 'ТО15'},
        {value: WORK_CHOSE_TO18, label: 'ТО18'},
        {value: WORK_CHOSE_TO24, label: 'ТО24'},
        {value: WORK_CHOSE_ANNUALLY, label: 'ежегодное ТО'},
        {value: WORK_CHOSE_REPLACE, label: 'замена'},
        {value: WORK_CHOSE_RETRO_MODERN, label: 'ретр./мод.'},
    ]
}


export function getDibStatuses(){
    const DIB_SOURCE_WE = 'we'
    const DIB_SOURCE_ANOTHER = 'another'
    const DIB_SOURCE_ABB = 'abb'
    return [
        {value: DIB_SOURCE_WE, label: "Нами"},
        {value: DIB_SOURCE_ANOTHER, label: "Другой компанией"},
        {value: DIB_SOURCE_ABB, label: "ABB"}
    ];
}

export function getDriveBadges(role='manager'){
    const BADGE_OBSOLETE_PARTS = 'info_obsolete_parts'
    const BADGE_MARK_MISMATCH = 'info_mark_mismatch'  
    const statuses = [
        {value: BADGE_OBSOLETE_PARTS, label: 'outdated'},        
        {value: BADGE_MARK_MISMATCH, label: 'Несовпадение маркировок'},
    ]
    return statuses;
}