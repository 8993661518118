import React, { useState } from 'react';
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';


const AddressSearch = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    
    const handleSearch = query => {
        if(props.clientCustom){
            setOptions([]);
            return true;
        }
        setIsLoading(true);
        
        getDataRequest(
            props.cookies, '/api/addresses/', `?search=${query}&client__name=${props.clientName}&limit=5&offset=0`
        ).then(
            resp => resp.json()
        ).then(
            res => {
                setOptions(res['results']);
                setIsLoading(false);
            }
        ).catch(error => console.log('error', error));
    };

    const filterCallback = (option, props) => (true);

    return (
          <AsyncTypeahead
            filterBy={filterCallback}
            id="addsress-search"
            isLoading={isLoading}
            labelKey="name"
            minLength={3}
            delay={500}
            onSearch={handleSearch}
            options={options}
            allowNew
            newSelectionPrefix="Новый адрес: "
            placeholder="Начните вводить адрес"
            searchText="Поиск..."
            renderMenuItemChildren={(option, props) => (                           
                <div key={option.id}>{option.name} <br/><span>({option.responsible_name})</span></div>              
            )}
            {...props}
          />
    );
};

AddressSearch.propTypes = {
    clientName: PropTypes.string.isRequired,
    clientCustom: PropTypes.bool
};

export default withCookies(AddressSearch);
