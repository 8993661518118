import React , { useState } from 'react';
import ControlledSwitch from '../tools/switch';
import FieldGroup from '../tools/fieldgroup';
import { Button, FormGroup, ControlLabel, Panel } from 'react-bootstrap';
import Datetime from 'react-datetime';

import moment from 'moment';
import 'moment/locale/ru'


const RetroCell = (props) => {
    const switchprop = 'planned_' + props.slug;
    const [dateValid, setDateValid] = useState(props.plan['date']? 'success': 'error');
    
    const validateDate = (date) => {
        setDateValid(typeof date == 'string'? 'error': 'success');
    }

    const handleDate = (date) => {
        validateDate(date);
        props.onChangeDate(date, props.slug);
    }

    return (
        <>
            <FormGroup>
                <ControlledSwitch checked={props.drive[switchprop]} name={switchprop} onChange={props.onSwitch}/>
                {!props.drive[switchprop]?
                    <ControlLabel style={ {marginLeft: '10px'} }>{props.label}</ControlLabel>
                :
                    <Button onClick={() => props.handleSpoiler(switchprop) } bsStyle="link" className="link--dashed">
                        <span>{props.label}</span> <i className={'fa fa-angle' +(props.spoilerKey === switchprop? '-right': '-down')}></i>
                    </Button>
                }
            </FormGroup>
            <Panel  expanded={ props.spoilerKey === switchprop } style={ {border: 'none'} } onToggle={ () => {}}>
                <Panel.Collapse>
                    <Panel.Body>
                        <FormGroup validationState={ dateValid }>
                            <ControlLabel>Планируемая дата</ControlLabel>
                            <Datetime
                                value={ moment(props.plan['date']) }
                                locale="ru"
                                timeFormat={ false }
                                onChange={ date => handleDate(date, props.slug) }
                                closeOnSelect={ true }
                            />
                        </FormGroup>
                        <FieldGroup
                        label="Комментарий"
                        componentClass="textarea"
                        value={ props.plan['description'] }
                        name={ props.slug }
                        onChange={ evt => props.onChangeComment(evt) }/>
                        
                        {props.canSave && 
                            <div className="pull-right">
                                <Button bsStyle="primary" disabled={ props.saving } onClick={ evt => props.onSave() }>
                                {props.saving? <i className="fa fa-spinner fa-spin"></i> :<i className="hi hi-floppy_disk"></i>} Сохранить
                                </Button>
                            </div>
                        }
                        
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        </>
    )
}
export default RetroCell;