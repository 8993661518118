import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';
import { 
    Row, Col, HelpBlock,
    FormGroup, ControlLabel,
    Button, Alert } from 'react-bootstrap';
import getDataRequest from '../tools/datarequest';
import ExcelDropZone from '../tools/exceldropzone';
import ClientSearch from './client-search';
import AddressSearch from './address-search';
import ManagerSelect from './manager-select';
import Switch from '../table/switch';
import ImportTable from './import-table';
import privod from '../../docs/privod.xls';
import { CSVLink } from "react-csv";


function DriveImport(props) {

    const [client, setClient] = useState('');
    const [clientCustom , setClientCustom] = useState(false);
    const [address, setAddress] = useState('');
    const [addressCustom, setAddressCustom] = useState(false);
    const [fieldsValid, setFieldsValid] = useState({
        'address': {state: null, help: ''},
        'manager': {state: null, help: ''}
    });
    const [datafile, setDatafile] = useState(null);
    const [invalidMessages, setInvalidMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [manager, setManager] = useState('');
    const [manualImport, setManualImport] = useState(false);
    const [manualData, setManualData] = useState([]);
    const isSeniorManager = !!(props.user.permissions.indexOf('access_to_all_client') + 1);

    const changeFileHandler = (file) => {
        setDatafile(file);
        setInvalidMessages([]);
    }

    const handleDismiss = (e, i) => {
        let mes = [...invalidMessages];
        mes.splice(i, 1);
        setInvalidMessages(mes);
    }

    const buttonHandler = (evt) => {
        evt.preventDefault();
        let messages = [];

        if(!client){
            messages.push({
                title: "Укажите имя клиента",
                message: "Поле 'клиент' обязательно для заполнения",
                variant: "danger"
            });
        }

        if(!address){
            messages.push({
                title: "Укажите адрес",
                message: "Поле 'адрес' обязательно для заполнения",
                variant: "danger"
            });
        }
    
        if(!datafile && !manualImport){
            messages.push({
                title: "Необходим файл",
                message: "Загрузите файл для импорта",
                variant: "danger"
            });
        }

        if(!manualData && manualImport){
            messages.push({
                title: "Заполните таблицу",
                message: "Заполните таблицу с данными",
                variant: "danger"
            });
        }
        setInvalidMessages(messages);
        
        if(!messages.length){
            formSend();
        }       
    }

    const formSend = () => {
        let data = {
            client: client,
            address: address
        }
        if(manualImport){
            data['datatable'] = JSON.stringify(manualData);
        } else {
            data['datafile'] = datafile;
        }
        if(isSeniorManager){
            data['responsible'] = manager;
        }
        
        setIsLoading(true);
        var has_errors = false;
        getDataRequest(
            props.cookies, '/api/drives/dataimport/', '', 'POST', data
        ).then(
            resp => {
                has_errors = resp.status === 200? false: true;
                return resp.json()
            }
        ).then(
            res => {             
                let messages = [];
                if(res.status === 'error'){   
                    messages = createImportErrorMessages(res);
                } else if(has_errors && (res.hasOwnProperty('message') || res.hasOwnProperty('detail'))) {
                    messages = createGenericErrorMessages(res);
                } else {
                    messages = createSuccessMessages(res);
                }
                setIsLoading(false);
                setInvalidMessages(messages);
            }
        ).catch(error => {
            setIsLoading(false);
            console.log('error', error)
        });
    }

    const createGenericErrorMessages = (res) => {
        let mes = {
            title: 'Ошибка',
            variant: 'danger',
            message: res.message || res.detail,
        }
        return [mes]; 
    }

    const createImportErrorMessages = (res) => {
        let mes = {
            title: res.message,
            variant: 'danger',
            message: (res.detail.map((m, i) => (<span key={i}>{ m }<br/></span>))),
        }
        return [mes]; 
    }

    const createSuccessMessages = (res) => {
        let messages = [];        
        if(res.hasOwnProperty('report')){
            if(res.report.their.length){
                const serials = res.report.their.map(item => item.serial).join(', ')
                let mes = '';
                if(res.report.their.length > 1){
                    mes = `Приводы с серийными номерами ${serials} уже были импортированы ранее.`;
                } else {
                    mes = `Привод с серийным номером ${serials} уже импортирован ранее.`;
                }
            
                messages.push(
                    {   
                        variant: 'warning',
                        title: "Некоторые приводы уже загружены",
                        message: mes
                    }                    
                );
            }
            if(res.report.alien.length){
                let responsible = (res.report.alien.map((item, i) => {
                        return (
                            <React.Fragment key={i}>
                                <span>
                                    с/н: {item.serial}&nbsp;ответственный: {item.firstName} {item.lastName}
                                </span>
                                <br/>
                            </React.Fragment>
                        )}
                    )
                )
                const headers = [
                    { label: "С/Н", key: "serial" },
                    { label: "username", key: "username" },
                    { label: "Имя", key: "firstName" },
                    { label: "Фамилия", key: "lastName" }
                ];
                messages.push(
                    {   
                        variant: 'warning',
                        title: "Некоторые приводы уже прикреплены к другим ответственным",
                        message: (<>
                            {responsible}
                            <br/>
                            <CSVLink data={res.report.alien} headers={headers} separator={";"} filename={"import_report.csv"}>
                                Скачать отчет
                            </CSVLink>
                        </>)
                    }                    
                );
            }
        }
        let mes = 'Импорт завершен удачно';
        if(res.address_id){
            mes = (<>{mes}. <NavLink to={ `/clients/${res.address_id}` }>Открыть результат</NavLink></>);        
        }
        messages.push(
            {
                title: "Поздравляем",
                message: mes
            }                    
        );
        return messages;
    }

    const clientChangeHandler = option => {
        setClientCustom(option.length && option[0].customOption? true: false);
        setClient(option.length? option[0].name: '');    
    }

    const addressChangeHandler = option => {
        setAddressCustom(option.length && option[0].customOption? true: false);
        setAddress(option.length? option[0].name: '');
        
        let validStates = {...fieldsValid};
        if(!isSeniorManager
            && option.length
            && option[0].hasOwnProperty('responsible_name')
            && option[0].responsible_name !== props.user.username
        ){
            validStates['address']['state'] = 'error';
            validStates['address']['help'] = 'За этот адрес ответственен другой менеджер';
        } else {
            validStates['address']['state'] = null;
            validStates['address']['help'] = '';
        }
        if(isSeniorManager
            && option.length
            && option[0].hasOwnProperty('responsible_name')
        ){
            setManager(option[0].responsible_name);
        }
        setFieldsValid(validStates);
    }

    const managerChangeHandler = evt => {
        let value = evt.target.value;
        setManager(value);
        let validStates = {...fieldsValid};
        if(isSeniorManager && clientCustom && value === ''){
            validStates['manager']['state'] = 'error';
            validStates['manager']['help'] = 'Укажите ответственного';
        } else {
            validStates['manager']['state'] = null;
            validStates['manager']['help'] = '';
        }
        setFieldsValid(validStates);
    }

    const isAllValid = () => {
        // Check if data is ready for send.
        let noempty = (
            client && address
            && (manager || !isSeniorManager)
            && (datafile || manualData.length && manualImport)
        );
        let noInvalid = true;
        for(let key in fieldsValid){
            noInvalid = fieldsValid[key]['state'] === null? noInvalid: false;
        }
        return noempty && noInvalid
    }

    return (
        <>
        <PageHeader title="Импорт приводов" subtitle="Здесь вы можете добавить клиентов и приводы" icon="gi gi-file_import"/>
        <Breadcrumbs crumbs={['Клиенты', 'Импорт']}/>
        <Row>
            <Col xs={12} md={12}>
                <div className="block full">            
                    <div className="block-title">
                        <h2>Импорт приводов</h2>
                    </div>
                    <Row>                        
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <ControlLabel>Клиент</ControlLabel>
                                <ClientSearch onChange={clientChangeHandler}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormGroup validationState={fieldsValid.address.state}>
                                <ControlLabel>Адрес</ControlLabel>
                                <AddressSearch disabled={ !client } clientName={ client } clientCustom={clientCustom} onChange={addressChangeHandler}/>
                                {fieldsValid.address.help && <HelpBlock>{fieldsValid.address.help}</HelpBlock>}
                            </FormGroup>
                        </Col>
                        { isSeniorManager &&
                            <Col xs={12} md={2}>
                                <FormGroup validationState={fieldsValid.manager.state}>
                                    <ControlLabel>Ответственный</ControlLabel>
                                    <ManagerSelect 
                                        disabled={ !addressCustom }
                                        onChange={ managerChangeHandler }
                                        value={manager}
                                    />
                                    {fieldsValid.manager.help && <HelpBlock>{fieldsValid.manager.help}</HelpBlock>}
                                </FormGroup>
                            </Col>
                        }
                        <Col xs={2}>
                            <FormGroup>
                                <ControlLabel>Ручной ввод</ControlLabel><br/>
                                <Switch id="importtype_toggler" name="manual_import"
                                    checked={ manualImport }
                                    onChange={ (id, name, val) => setManualImport(val) } />
                            </FormGroup>                        
                        </Col>
                    </Row>
                    <Row>
                        {manualImport?
                            <Col xs={12}>                                
                                <ImportTable brands={ props.brands } onDataChange={ setManualData }/>                                
                            </Col>
                            :
                            <Col xs={12}>
                                <FormGroup>
                                    <ExcelDropZone pushFile={ (file) => changeFileHandler(file) } fileExample={privod}/>
                                </FormGroup>
                            </Col>
                        }
                        
                        {invalidMessages.length ?
                            <Col xs={12}>
                                {invalidMessages.map((m, i) => (
                                    <Alert key={i} bsStyle={ m.variant } onDismiss={ (e) => handleDismiss(e, i) } style={{marginTop: '20px'}} >
                                        <h4>{ m.title }</h4>
                                        <p>{ m.message }</p>
                                    </Alert>
                                ))}
                            </Col>
                            : ''   
                        }
                        <Col xs={12} className="text-center">
                            <Button bsSize="large" bsStyle="info"  disabled={ isLoading || !isAllValid() } onClick={ buttonHandler }>
                                {isLoading ?
                                    <i className="fa fa-spinner fa-spin"></i>
                                :
                                    <i className="fa fa-upload"></i>
                                } Импорт
                            </Button>
                        </Col>
                            
                    </Row>
                    
                </div>
            </Col>
        </Row>
        </>
    )
    
}

DriveImport.propTypes = {
    brands: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
};

export default withCookies(DriveImport);