import React from 'react';
import { withCookies } from 'react-cookie';
import { useParams } from "react-router-dom";

/**
 * Allows passing query parameters to class-based child components
 * 
 * @param {*} props 
 */
function ProxyChild(props){
    let matchParams = useParams();
    return (<><props.component matchParams={matchParams} {...props.props}/></>);
} 
export default withCookies(ProxyChild);