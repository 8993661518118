import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Form,
        FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import ColumnsManager from './list-columns';
import MonthPicker from '../tools/monthpicker';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');


class FilterForm extends Component{
    
    changeResponsibleHandler = evt => {
        this.props.columnsMgr.getFilters()['responsible'](evt.target.value);
    }

    render(){        
        return (
            <Row style={ {marginBottom: '2rem'} }>
                <Col md={12}>
                    <Form>
                        <h3>Фильтрация</h3>
                        <Row>
                            {!!this.props.managers.length &&
                            <Col xs={6} md={2}>
                                <FormGroup>
                                    <ControlLabel>Ответственный</ControlLabel>
                                    <FormControl componentClass="select" onChange={ this.changeResponsibleHandler }>
                                        <option value="">все</option>
                                        { this.props.managers.map(m => (<option key={ m.id } value={ m.id }>{ m.username }</option>)) }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            }
                            <Col xs={12} sm={8} md={6}>
                                <FormGroup>
                                    <ControlLabel>Месяц планируемых работ</ControlLabel>
                                    <MonthPicker 
                                        onChange={ value => this.props.onExtraFilterChange('to_plans__date', value) }
                                        yearOffset={ {start: -1, end: 1} }
                                    />                                    
                                </FormGroup>
                            </Col>
                        </Row>
                                     
                    </Form>
                </Col>
            </Row>
        );
    }
}

FilterForm.propTypes = {
    columnsMgr: PropTypes.instanceOf(ColumnsManager),
    managers: PropTypes.array.isRequired,
};

export default FilterForm;