import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import ColumnsManager from './list-columns';
import ClientSelect from './client-select';
import AddressSelect from './address-select';
import MonthPicker from '../tools/monthpicker';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');


class FilterForm extends Component{

    changeBrandHandler = evt => {
        this.props.columnsMgr.getFilters()['brand_name'](evt.target.value);
    }

    changeStateHandler = evt => {
        this.props.columnsMgr.getFilters()['state'](evt.target.value);
    }

    changeResponsibleHandler = evt => {
        this.props.onExtraFilterChange('address__responsible', evt.target.value);
    }

    changeClientHandler = items => {
        let value = items.length? items[0]['id']: '';        
        this.props.onExtraFilterChange('address__client', value);
    }

    changeAddressHandler = items => {
        let value = items.length? items[0]['id']: '';        
        this.props.onExtraFilterChange('address', value);
    }

    render(){        
        return (
            <Row style={ {marginBottom: '2rem'} }>
                <Col md={12}>
                    <Form>
                        <h3>Фильтрация</h3>
                        <Row>
                            <Col xs={6} md={2}>
                                <FormGroup>
                                    <ControlLabel>Бренд</ControlLabel>
                                    <FormControl componentClass="select" onChange={ this.changeBrandHandler }>
                                        <option value="">все</option>
                                        { this.props.brands.map(b => (<option key={ b.id } value={ b.id }>{ b.name }</option>)) }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            <Col xs={6} md={2}>
                                <FormGroup>
                                    <ControlLabel>Статус</ControlLabel>
                                    <FormControl componentClass="select" onChange={ this.changeStateHandler }>
                                        <option value="">без разницы</option>
                                        { this.props.statuses.map((s, i) => (<option key={ i } value={ s.value }>{ s.label }</option>)) }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            {!!this.props.managers.length &&
                                <Col xs={6} md={2}>
                                    <FormGroup>
                                        <ControlLabel>Ответственный</ControlLabel>
                                        <FormControl componentClass="select" onChange={ this.changeResponsibleHandler }>
                                            <option value="">все</option>
                                            { this.props.managers.map(m => (<option key={ m.id } value={ m.id }>{ m.username }</option>)) }
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                            }
                            <Col xs={6} md={3}>
                                <FormGroup>
                                    <ControlLabel>Клиент</ControlLabel>     
                                    <ClientSelect onChange={ this.changeClientHandler }/>  
                                </FormGroup>
                            </Col>
                            <Col xs={6} md={3}>
                                <FormGroup>
                                    <ControlLabel>Адрес</ControlLabel>     
                                    <AddressSelect onChange={ this.changeAddressHandler }/>  
                                </FormGroup>
                            </Col>
                             
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <FormGroup>
                                    <ControlLabel>Дата производства</ControlLabel>
                                    <MonthPicker 
                                        onChange={ value => this.props.onExtraFilterChange('date_of_manufacture', value) }
                                        yearOffset={ {start: -30, end: 2} }
                                    />                                    
                                </FormGroup>
                            </Col>                        
                            <Col xs={12} sm={6} md={4}>
                                <FormGroup>
                                    <ControlLabel>Месяцы планируемых работ</ControlLabel>
                                    <MonthPicker 
                                        onChange={ value => this.props.onExtraFilterChange('to_plans__date', value) }
                                        yearOffset={ {start: -1, end: 2} }
                                    />                                    
                                </FormGroup>
                            </Col>
                        </Row>       
                    </Form>
                </Col>
            </Row>
        );
    }
}

FilterForm.propTypes = {
    columnsMgr: PropTypes.instanceOf(ColumnsManager),
    brands: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
};

export default FilterForm;