import React from 'react';

export default function EditalbeCell(props) {
    const handleClick = evt => {
        // open editor by click on pencil icon
        var event = new MouseEvent('dblclick', {
            'view': window,
            'bubbles': true,
            'cancelable': true
        });
        cellWrap.current.dispatchEvent(event);
    }

    const cellWrap = React.createRef();
    return(
        <div ref={ cellWrap } className={"editable__cell " + (props.className ? props.className:'')}>{props.cell}&nbsp;<i className="fa fa-pencil editable__cell__icon" onClick={handleClick}></i></div>
    )    
}