import React, { useState } from 'react';
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';


function ClientSearch (props){
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    
    const handleSearch = query => {
        setIsLoading(true);
        
        getDataRequest(
            props.cookies, '/api/clients/', `?search=${query}&limit=5&offset=0`
        ).then(
            resp => resp.json()
        ).then(
            res => {
                setOptions(res['results']);
                setIsLoading(false);
            }
        ).catch(error => console.log('error', error));
    };
     
    return (
          <AsyncTypeahead
            id="client-search"
            isLoading={isLoading}
            labelKey="name"
            minLength={3}
            delay={500}
            onSearch={handleSearch}
            options={options}
            allowNew
            newSelectionPrefix="Новый клиент: "
            placeholder="Начните вводить название клиента"
            searchText="Поиск..."
            renderMenuItemChildren={(option, props) => (                           
              <div key={option.id}>{option.name}</div>              
            )}
            {...props}       
          />
    );
};

export default withCookies(ClientSearch);
