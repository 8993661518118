import React, { useState, useEffect } from 'react';
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';
import ReactHtmlParser from 'react-html-parser';
import { Button, Alert, Table} from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');

const MPChoiceList = (props) => {
    const [show, setShow] = useState(true);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleDismiss = evt => {
        setShow(false);
        props.handleDismiss();
    }

    const handleChoice = id => {
        setShow(false);
        props.handleChoice(id);
    }

    useEffect(() => {
        const esc = encodeURIComponent;
        const params = { 
            type_code: props.packageName,
            brand: props.brand,
            offset: 0,
            limit: 1
        };
        // this line takes the params object and builds the query string
        const query = '?' + Object.keys(params).map(k => `${esc(k)}=${esc(params[k])}`).join('&');
        getDataRequest(
            props.cookies, '/api/packages/', query
        ).then(
            resp => resp.json()
        ).then(
            res => {
                setItems(res.results);
                setLoading(false);
            }
        ).catch(
            error => {
                setLoading(false);
                console.log('error', error)
            }
        );
    }, []);

    return (
        <>
        {show &&
            <Alert bsStyle='info' onDismiss={ handleDismiss } style={ {backgroundColor: 'rgba(218, 232, 242, 0.3)'} }>
                {loading ?
                    <div className="text-center">
                        <i className="fa fa-spinner fa-4x fa-spin"></i>
                    </div>
                :   
                    <>
                        {items && items.length?
                            <>
                            <h3>Похожие пакеты ТО</h3>
                            <Table condensed>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Название</th>
                                        <th>Комплекты ТО</th>
                                        <th>Обновлен</th>
                                        <th></th>                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((it, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{ReactHtmlParser(it.type_code)}</td>
                                            <td>{it.material_types.join(', ')}</td>
                                            <td>{moment(it.updated).format('L')}</td>
                                            <th><Button bsStyle="primary" onClick={ () => handleChoice(it.id) }>Выбрать</Button></th>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            </>
                        :
                            <p>Похожие пакеты ТО отсутствуют</p>
                        }
                    </>
                }
                <Button 
                    bsStyle="primary" 
                    onClick={ handleDismiss }
                    style={ {marginTop: '10px'} }
                >Импортировать новый пакет ТО</Button>
            </Alert>
        }
        </>
    );
}
export default withCookies(MPChoiceList);
