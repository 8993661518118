import React, {Component} from 'react';
import { withCookies } from 'react-cookie';
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';
import RemoteAll from '../table';
import ColumnsManager from './detail-columns';
import prepareQueryArgs from './detail-argshandler';
import FilterForm from './detail-filter';
import SendToChoiceButton from './detail-tochoiceform';
import ExportButton from './detail-exportbutton';
import { ToolsSlidedown } from '../tools/slidedown';
import getDataRequest from '../tools/datarequest';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getDriveStatuses } from '../address/drive-status';
import CommentPageBlock from '../comment/page';
import ScrollableAnchor from 'react-scrollable-anchor';
import initUpdateWatcher from '../tools/driveupdatewatch';
import ChrpPlanLegend from '../table/chrpplan-legend';


class AddressDetail extends Component {

    state = {
        address: {name: '', comments: []},
        items: [],
        sizePerPage: 100,
        page: 1,
        totalSize: 100,
        loading: false,
        filterVisible: false,
        filterClassname: '',
        isExportClicked: false,
    }
    extraFilters = {
        'to_plans__date_before': '',
        'to_plans__date_after': '',
        'date_of_manufacture_before': '',
        'date_of_manufacture_after': ''
    }   

    componentDidMount(){        
        this.dataRequest(this.state.page, this.state.sizePerPage);
        this.detailRequest();
        this.uWatch = initUpdateWatcher(this.props.cookies, this.driveUpdateCallback);
    }

    driveUpdateCallback = responsible => {
        if(!this.just_edited && responsible.indexOf(this.state.address.responsible) + 1){
            this.dataRequest.apply(this, this.tableargs);
        }  
        this.just_edited = 0;      
    }

    detailRequest = () => {
        var addressId = this.props.matchParams.addressId;
        getDataRequest(
            this.props.cookies, `/api/addresses/${addressId}/`, ''
        ).then(
            resp => {
                if(resp.status === 404){
                    throw 'Страницы не существует';
                }
                return resp.json();
            }
        ).then(
            res => (this.setState({address: res}))
        ).catch(error => console.log('error', error));
    }

    dataRequest = (page, sizePerPage, filters, searchText, sortField, sortOrder) => {
        
        filters = filters || {};
        //add exrta filters
        for(let field in this.extraFilters){
            filters[field] = {'filterVal': this.extraFilters[field]};
        }

        var addressId = this.props.matchParams.addressId;
        var qargs = prepareQueryArgs(addressId, page, sizePerPage, filters, searchText, sortField, sortOrder);
        this.setState({loading: true});

        getDataRequest(
            this.props.cookies, '/api/drives/', qargs
        ).then(
            resp => {   
                if(resp.status === 400){
                    throw 'Неверный запрос';
                }
                return resp.json();
            }
        ).then(
            res => {
                //remove duplicates generatet by filter
                let ids = [];
                let result = res['results'].filter(item => {
                        if(ids.indexOf(item.id) > -1){
                            return false;
                        }
                        ids.push(item.id);
                        return true;
                    }
                );
                this.setState({items: result, totalSize: res['count']})
            }
        ).catch(
            error => {
                console.log('error', error);
                this.setState({items: [], totalSize: 1});
            }
        ).finally(
            () => this.setState({loading: false})
        );
    } 

    tabletimer = ''
    tableargs = []
    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {        
        if(type === 'cellEdit'){
            this.editRequest(cellEdit);            
        } else {
            if(type !== 'pagination'){
                page = 1;
            }
            this.setState({ sizePerPage: sizePerPage, page: page });
    
            // Slow down remote requests to avoid duplicate requests when clearing filters.
            this.tableargs = [page, sizePerPage, filters, searchText, sortField, sortOrder];
            if(this.tabletimer === ''){
                this.tabletimer = setTimeout(() => {
                    this.dataRequest.apply(this, this.tableargs);
                    this.tabletimer = '';
                }, 50);
            }
        }
        
    }

    just_edited = 0;  // if 1 next update callback will blocked
    editRequest = (cellEdit) => {
        this.just_edited = 1;
        const id = cellEdit['rowId'];
        let data = {}
        data[cellEdit['dataField']] = cellEdit['newValue'];
        getDataRequest(
            this.props.cookies, `/api/drives/${id}/`, '', 'PATCH', data
        ).then(
            resp => resp.json()
        ).then(
            res => {
                let items = this.state.items;
                for(let i = 0; i < items.length; i++){
                    if(items[i]['id'] === res.id){
                        items[i] = res;
                        break;
                    }
                }
                this.setState({items: items});
            }
        ).catch(error => console.log('error', error));
    }
   
    setExportClicked = (value) => {
        this.setState({isExportClicked: value})
    }

    exportCSV = evt => {
        evt.currentTarget.blur();
        evt.preventDefault();        
        this.setExportClicked(true);
    }

    selectedStorage = (function(){
        var storage = [];
        return {
            push: (id) => storage.indexOf(id) + 1 || storage.push(id),
            get: () => ([...storage]),
            remove: (id) => {
                let ind = storage.indexOf(id);
                ind + 1 && storage.splice(ind, 1);
            }
        }
    })();

    toggleSelected = (id, isAdd) => {        
        if(isAdd){
            this.selectedStorage.push(id);
        } else {
            this.selectedStorage.remove(id);
        }
    }

    getSelectedDrivesData = () => {
        let ids = this.selectedStorage.get();
        return this.state.items.filter(it => (ids.indexOf(it.id) + 1));
    }

    selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        onSelect: (row, isSelect, rowIndex, e) => {
            this.toggleSelected(row.id, isSelect);
        },
        onSelectAll: (isSelect, rows, e) => {
            rows.forEach(item => this.toggleSelected(item.id, isSelect));
        }
    };
    
    onSendSelected = (ids) => {
        this.setState({
            items: this.state.items.map(it => {                
                if(ids.indexOf(it.id) + 1){
                    it.state = 'waiting_chose';                 
                }
                return it;
            })
        })
    }

    handleExtraFilter = (field, value) => {
        if(field === 'to_plans__date' || field === 'date_of_manufacture'){
            if(value){
                this.extraFilters[field + '_after'] = value[0];
                this.extraFilters[field + '_before'] = value[1];
            } else {
                this.extraFilters[field + '_after'] = '';
                this.extraFilters[field + '_before'] = '';
            }
        } else {
            this.extraFilters[field] = value;
        }
        //use last request table args for fictive filtering 
        this.dataRequest.apply(this, this.tableargs);
    }

    render(){
        const statuses = getDriveStatuses();
        const columnsMgr = new ColumnsManager();
        const columns = columnsMgr.getColumns(this.props.brands, statuses);

        const toggleFilter = evt => {
            evt.currentTarget.blur();
            evt.preventDefault();
            columnsMgr.cleanFilters();
            let visible = this.state.filterVisible;
            this.setState({filterVisible: !visible});
            if(!visible){
                setTimeout(
                    () => {
                        // Ensures the visibility of the nested calendar
                        this.setState({filterVisible: !visible});
                    },
                    300
                );
            }
        }
    
        return (
            <React.Fragment>
            <PageHeader 
                title="Таблица приводов клиента"
                subtitle=""
                icon="gi gi-suitcase"/>
            <Breadcrumbs crumbs={['Клиенты', 'Приводы']}/>
            <div className="block full">            
                <div className="block-title">
                    <div className="block-options pull-right">
                        
                        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Фильтрация</Tooltip>}>
                            <a href="#" 
                                className="btn btn-alt btn-sm btn-default"
                                onClick={ toggleFilter }
                            ><i className="fa fa-filter"></i></a>
                        </OverlayTrigger>

                        <SendToChoiceButton getDrives={ this.getSelectedDrivesData } onSend={ids => this.onSendSelected(ids) }/>
                        <ExportButton getDrives={ this.getSelectedDrivesData }/>
                        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Экспорт в csv</Tooltip>}>
                            <a href="#"
                                className="btn btn-alt btn-sm btn-default"
                                onClick={ this.exportCSV }
                            ><i className="gi gi-file_import"></i></a>
                        </OverlayTrigger>                     
                    </div>                  
                    <h2>Список приводов</h2>
                </div>
                <div>
                    <p>Клиент: {this.state.address.client_name? this.state.address.client_name: '...'}</p>
                    <p>Адрес: {this.state.address.name? this.state.address.name: '...'}</p>
                </div>
                <ToolsSlidedown open={this.state.filterVisible}>
                    <FilterForm
                        columnsMgr={ columnsMgr }
                        statuses={statuses}
                        brands={ this.props.brands }
                        onExtraFilterChange={ this.handleExtraFilter }
                    />                   
                </ToolsSlidedown>

                <ChrpPlanLegend/>

                <RemoteAll
                    data = { this.state.items }
                    columns = { columns }
                    page = { this.state.page }
                    sizePerPage = { this.state.sizePerPage }
                    totalSize = { this.state.totalSize }
                    onTableChange = { this.handleTableChange }
                    loading = { this.state.loading }
                    exportCSV = {{
                            fileName: 'Приводы.csv',
                            separator: ';',
                            isExportClicked: this.state.isExportClicked,
                            setExportClicked: this.setExportClicked,
                            onlyExportSelection: true,
                            exportAll: false
                        }
                    }
                    selectRow={ this.selectRow }                    
                />
                
            </div>
            
            <div className="block full" >
                <ScrollableAnchor id={'comments_block'}>
                    <div>
                        <div className="block-title"><h2>Комментарии</h2></div>
                        <CommentPageBlock items={this.state.address.comments}/>
                    </div>
                </ScrollableAnchor>
            </div>
            </React.Fragment>
        );
    }
}

export default withCookies(AddressDetail);