import React from 'react';
import { Alert } from 'react-bootstrap';

const ValidateAlert = (props) => {
    return (
        <>
            {props.errors && 
                <>
                    {props.errors.map((it, i) => (
                        <Alert key={ i } bsStyle={ it.bsStyle }>
                            <h4>{ it.title }</h4>
                            <p>{ it.text }</p>
                        </Alert>
                    ))}
                </>
            }
        </>
    )    
}
export default ValidateAlert;
