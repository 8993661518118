import React, { useState, useEffect } from 'react';
import { withCookies } from 'react-cookie';
import { NavLink } from 'react-router-dom';
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';
import getDataRequest from '../tools/datarequest';
import ControlledSwitch from '../tools/switch';
import { Row, Col, Button, FormGroup, ControlLabel, Panel, Label } from 'react-bootstrap';
import MPackagesImportBody from '../m_packages/import-body';
import MPChoiceList from './detail-mplist';
import TOPartsList from './detail-toparts';
import TOChoiceList from './detail-tovariants';
import RetroCell from './detail-retrocell';
import FinalWindow from './detail-finalmodal';
import ValidateAlert from './detail-alerts';
import DriveInfo from './detail-info';
import DateField from './detail-date';
import { getDriveStatuses } from '../address/drive-status';
import CommentPageBlock from '../comment/page';
import ScrollableAnchor from 'react-scrollable-anchor';
import Page404 from '../404';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getDibStatuses } from '../address/drive-status.js';
import moment from 'moment';
import 'moment/locale/ru'


const ChrpDetail = props => {
    const sources_map = getDibStatuses().reduce((acc, item) => {
        if(item['value'] !== 'we'){
            acc[item['value']] = item['label'];
        }
        return acc;
    }, {});
    const [drive, setDrive] = useState({
        name: '', serial_number: '', to_parts: [], to_plans: [], comments: []
    });
    const [plans, setPlans] = useState({'retro_modern': {date: '', description: ''}, 'replacement': {date: '', description: ''}});
    const [notExists, setNotExists] = useState(false);
    const [loading, setLoading] = useState(true);
    const [packagesLoading, setPackagesLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [spoilerKey, setSpoilerKey] = useState(0);
    const [showImport, setShowImport] = useState(false);
    const [manualImport, setManualImport] = useState(false);
    const [editedParts, setEditedParts] = useState([]);
    const [needUpdateParts, setNeedUpdateParts] = useState(false);
    const [showFinalModal, setShowFinalModal] = useState(false);
    const [errors, setErrors] = useState([]);
    
    const id = props.matchParams.driveId;

    useEffect(() => {
        initialRequest();
    }, []);

    const initialRequest = () => {
        getDataRequest(
            props.cookies, `/api/drives/${id}/`, ''
        ).then(
            resp => {
                if(resp.status == 404){
                    throw '404';
                }
                return resp.json()
            }
        ).then(
            res => {
                setDrive(res);
                let p = ['retro_modern', 'replacement']
                setPlans(res.to_plans.reduce((obj, it) => {
                    if(p.indexOf(it.work_kind) + 1){
                        obj[it.work_kind] = {...it}
                    }
                    return obj;
                }, {...plans}))
                setNotExists(false);
                showInitialPosition(res);
                setLoading(false);
            }
        ).catch(error => {
                console.log('error', error);
                setNotExists(true);
            }
        );
    }

    const showInitialPosition = (dr=drive) => {
        if(dr.has_to){
            setSpoilerKey('has_to');
        } else if(dr.planned_retro_modern){
            setSpoilerKey('planned_retro_modern');
        } else if(dr.planned_replacement){
            setSpoilerKey('planned_replacement');
        }
    }

    const handleToSwitch = (name, checked) => {
        let dr = {...drive};
        dr[name] = checked;
        setSpoilerKey(checked? name: 0);
        setDrive(dr);
    }

    const handleDoubleSwitch = (name, checked) => {
        let dr = {...drive};
        let keys = ['planned_retro_modern', 'planned_replacement'];
        dr[name] = checked;
        if(checked){
            keys.splice(keys.indexOf(name), 1);
            dr[keys[0]] = false;
            setSpoilerKey(name);
        } else {
            setSpoilerKey(0);
        }
        setDrive(dr);
    }

    const handleSpoiler = key => {
        setSpoilerKey(spoilerKey === key? 0: key);
    }

    const changeDateHandler = (date, slug) => {
        var outdate = typeof date !== 'string'? date.add(12, 'hours') : '';
        outdate = outdate? outdate.toDate(): '';
        let pl = {...plans};
        pl[slug]['date'] = outdate;
        setPlans(pl);
    };

    const handleManufactureDate = date => {
        var outdate = typeof date !== 'string'? date.add(12, 'hours') : '';
        outdate = outdate? outdate.toDate(): '';
        let dr = {...drive};
        dr.date_of_manufacture = outdate;
        setDrive(dr);
    }

    const changeCommentHandler = evt => {
        let pl = {...plans};
        pl[evt.target.name]['description'] = evt.target.value;
        setPlans(pl);
    }

    const toResetHandler = () => {
        let dr = {...drive};
        dr['to_parts'] = [];
        setDrive(dr);
    }

    const toEditHandler = () => {
        let dr = {...drive};
        let parts = [...dr['to_parts']];
        dr['to_parts'] = [];
        setDrive(dr);
        setEditedParts(parts);
        setManualImport(true);
        setShowImport(true);
    }

    const fieldsValid = () => {
        setErrors([]);
        let messages = [];
        if(drive.has_to && (!drive.to_parts || !drive.to_parts.length)){
            messages.push({
                title: 'Неполный подбор',
                text: 'Заполните подбор или отключите переключатель "Подбор ТО"',
                bsStyle: 'danger'
            });
        }
        if(drive.planned_retro_modern && !plans.retro_modern.date){
            messages.push({
                title: 'Укажите дату ретрофита/модернизации',
                text: 'Планируемую дату обязательно нужно заполнить',
                bsStyle: 'danger'
            });
        }
        if(drive.planned_replacement && !plans.replacement.date){
            messages.push({
                title: 'Укажите дату планируемой замены',
                text: 'Планируемую дату обязательно нужно заполнить',
                bsStyle: 'danger'
            });
        }
        setErrors(messages);
        if(messages.length){
            return false;
        }
        return true;
    }

    const save = (final=false, finalArgs={}) => {

        if(!fieldsValid()){
            return false;
        }

        setSaving(true);
        var timeEnd = false;
        var responseEnd = false;
        const timeout = setTimeout(() => {
            timeEnd = true;
            setSaving(!(timeEnd && responseEnd));
        }, 300);

        let fields = ['has_to', 'planned_retro_modern', 'planned_replacement', 'date_of_manufacture'];

        if(needUpdateParts){
            fields.push('mp_package');
        }

        let data = {};

        fields.forEach(key => {            
            if((key.indexOf('date') + 1) && drive[key]){                
                data[key] = moment(drive[key]).format('YYYY-MM-DD');
            } else {
                switch(drive[key]){
                    case '': data[key] = '';
                        break;
                    case null: data[key] = '';
                        break;
                    case false: data[key] = '';
                        break;
                    default: data[key] = drive[key];            
                }
            }
        });

        let planout = [];
        for(let p in plans){
            let row = {...plans[p], ...{work_kind: p}};
            row.date = row.date? moment(row.date).format('YYYY-MM-DD'): '';
            planout.push(row);
        }
        data['to_plans'] = JSON.stringify(planout);

        if(final === true){
            data['state'] = finalArgs['state'];
            data['comment'] = finalArgs['comment'] || '';
            data['to_choise_date'] = moment().format('YYYY-MM-DDTHH:mm');
        }

        getDataRequest( props.cookies, `/api/drives/${id}/`, '', 'PATCH', data)
        .then( resp => resp.json() )
        .then(
            res => {                     
                if(final === true){
                    finalArgs.callback(true);
                }
            }
        )
        .catch(error => console.log('error', error) )
        .finally(
            () => {
                responseEnd = true;
                setSaving(!(timeEnd && responseEnd));
                setNeedUpdateParts(false);
            }
        );
    }

    const handleMPDismiss = () => {
        setShowImport(true);
    }

    const handleMPChoice = (packageid, dr) => {
        setPackagesLoading(true);

        getDataRequest(props.cookies, `/api/packages/${packageid}`, '')
        .then(resp => resp.json())
        .then(
            res => {
                const name = res.type_code;
                getDataRequest(props.cookies, '/api/packageparts/', `?package=${packageid}`)
                .then( resp => resp.json() )
                .then(
                    res => {
                        dr = dr || {...drive};
                        dr.mp_package = packageid;
                        dr.mp_package_name = name;
                        dr.to_parts = res.results;
                        setDrive(dr);
                        setManualImport(false);
                        setNeedUpdateParts(true);
                    }
                )
                .catch( error => console.log('error', error) )
                .finally( () => setPackagesLoading(false) );
            }
        ).catch(
            error => {                
                console.log('error', error)
            }
        );
    }

    const handleTOChoice = (driveitem) => {

        let dr = {...drive};
        let {has_to, planned_retro_modern, planned_replacement,
            replacement_description, retro_modern_description,
            mp_package, mp_package_name} = driveitem;
        let changes = {has_to, planned_retro_modern, planned_replacement,
            replacement_description, retro_modern_description,
            mp_package, mp_package_name};

        Object.assign(dr, changes);
        showInitialPosition(dr);
        if(dr.mp_package){
            handleMPChoice(dr.mp_package, dr)
        } else {
            setDrive(dr);
        }
    }

    const finalize = (state, comment) => {
        const callback = success => {
            if(success){
                let dr = {...drive};
                dr.state = state;
                setDrive(dr);
                setShowFinalModal(false);
                initialRequest();
            }
        }
        save(true, {state, comment, callback});
    }

    const statuses = getDriveStatuses().reduce((obj, item) => {
            obj[item.value] = item.label;
            return obj
        }, {});

    const canSave = () => {
        return !!(
                'waiting_chose' === drive.state &&
                (props.user.is_engineer || props.user.is_senior_engineer || props.user.is_admin)
            );
    }

    const isNamesMissmatch = () =>  {
        let cleaner = (str) => {
            const defis1 = String.fromCharCode(150);
            const defis2 = String.fromCharCode(151);
            const defis3 = String.fromCharCode(173);
            if(typeof str == "undefined"){
                return '';
            }
            return str.replace(defis1, '-')
                .replace(defis2, '-')
                .replace(defis3, '-')
                .replace(/[^a-zа-яё0-9\+\-\s]/gi, '')
                .toLowerCase()
                .trim()
        }
        return cleaner(drive.name) !== cleaner(drive.mp_package_name);
    }

    const name = drive.name.replace(/\+/ig, " +");

    return (
        <>
            {notExists ?
                <Page404/>
            :
                <>
                    <PageHeader
                        title={ `Подбор ТО ${name} (${drive.serial_number})` }
                        subtitle=""
                        icon="fa fa-server"/>
                    <Breadcrumbs crumbs={['ЧРП', 'подбор']}/>
                    <div className="block full">
                        <div className="block-title">
                            <div className="block-options pull-right">

                            </div>
                            <h2>{ `Подбор ТО ${drive.name} (${drive.serial_number})` }</h2>
                        </div>

                        {loading?
                            <div className="text-center">
                                <i className="fa fa-spinner fa-4x fa-spin"></i>
                            </div>
                        :
                            <>
                                <DriveInfo
                                    drive={ drive }
                                    plans={ plans }
                                    showFullData={ !canSave() }
                                    toFilter={ props.matchParams.toFilter }
                                    user={ props.user }
                                />

                                {canSave() &&
                                    <>                                    
                                        <Row>                                            
                                            <DateField 
                                                value={ drive.date_of_manufacture }
                                                label='Дата производства:'
                                                saving={ saving }
                                                onSave={ save }
                                                onChangeDate={ handleManufactureDate }
                                                editable={ canSave() }
                                            />
                                            {drive.dib_source in sources_map?
                                                <Col md={12}>
                                                <p className="lead">Зарегистрирован в DIB: {sources_map[drive.dib_source]}</p>
                                                </Col>
                                            :
                                                <DateField 
                                                    value={ drive.dib_date }
                                                    label='Зарегистрирован в DIB:'                            
                                                    editable={ false }
                                                />
                                            }
                                        </Row>                                        
                                        {drive.state == 'waiting_chose' &&
                                            <Row>
                                                <Col md={12}>
                                                    <TOChoiceList driveName={drive.name} brand={drive.brand} handleChoice={ handleTOChoice }/>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <FormGroup>
                                                    <ControlledSwitch checked={drive.has_to} name="has_to" onChange={handleToSwitch}/>
                                                    {!drive.has_to?
                                                        <ControlLabel style={ {marginLeft: '10px'} }>Подбор ТО</ControlLabel>
                                                    :
                                                        <Button onClick={() => handleSpoiler('has_to') } bsStyle="link" className="link--dashed">
                                                            <span>Подбор ТО</span> <i className={'fa fa-angle' + ('has_to' === spoilerKey? '-right': '-down')}></i>
                                                        </Button>
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <RetroCell slug="retro_modern"
                                                    label="Модернизация/ретрофит"
                                                    drive={ drive }
                                                    plan={ plans['retro_modern'] }
                                                    spoilerKey={ spoilerKey }
                                                    onSwitch={ handleDoubleSwitch }
                                                    handleSpoiler={ handleSpoiler }
                                                    onChangeDate={ changeDateHandler }
                                                    onChangeComment={ changeCommentHandler }
                                                    canSave={ canSave() }
                                                    saving={ saving }
                                                    onSave={ save }
                                                />
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <RetroCell slug="replacement"
                                                    label="Замена"
                                                    drive={ drive }
                                                    plan={ plans['replacement'] }
                                                    spoilerKey={ spoilerKey }
                                                    onSwitch={ handleDoubleSwitch }
                                                    handleSpoiler={ handleSpoiler }
                                                    onChangeDate={ changeDateHandler }
                                                    onChangeComment={ changeCommentHandler }
                                                    canSave={ canSave() }
                                                    saving={ saving }
                                                    onSave={ save }
                                                    />
                                            </Col>
                                            <Col xs={12}>
                                                <Panel expanded={ spoilerKey === 'has_to' } style={ {border: 'none'} } onToggle={ () => {}}>
                                                    <Panel.Collapse>
                                                        <Panel.Body>
                                                            {packagesLoading?
                                                                <div className="text-center">
                                                                    <i className="fa fa-spinner fa-4x fa-spin"></i>
                                                                </div>
                                                            :
                                                                <>
                                                                    {!drive.to_parts || !drive.to_parts.length ?
                                                                    <>
                                                                        {!showImport && <MPChoiceList
                                                                            packageName={drive.name}
                                                                            brand={drive.brand}
                                                                            handleDismiss={ handleMPDismiss }
                                                                            handleChoice={ handleMPChoice } />
                                                                        }
                                                                        {showImport && <MPackagesImportBody
                                                                            brand={ drive.brand }
                                                                            mark={ drive.name }
                                                                            serial={ drive.serial_number }
                                                                            manually={ manualImport }
                                                                            initialData={ editedParts }
                                                                            onSuccess={ handleMPChoice }
                                                                        />}
                                                                    </>
                                                                    :   
                                                                        <>
                                                                            <p>
                                                                            Пакет ТО: <NavLink to={ `/packages/${drive.mp_package}` }>
                                                                                    { drive.mp_package_name }
                                                                                </NavLink>                                                                                
                                                                                {canSave() &&
                                                                                    <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Синхронизировать</Tooltip>}>
                                                                                        
                                                                                            <i className="gi gi-restart"
                                                                                                onClick={() => handleMPChoice(drive.mp_package, drive)}
                                                                                                style={ {marginLeft: '10px', verticalAlign: 'baseline'} }
                                                                                            ></i>
                                                                                       
                                                                                    </OverlayTrigger>
                                                                                }
                                                                            </p>
                                                                            <p>
                                                                            {(drive.badges.indexOf('info_obsolete_parts') > -1) &&
                                                                                <Label bsStyle="primary" style={{marginRight: '5px'}}>outdated</Label>
                                                                            }
                                                                            {isNamesMissmatch() && drive.brand == 1 &&
                                                                                <Label bsStyle="primary" style={{marginRight: '5px'}}>Несовпадение маркировок</Label>
                                                                            }
                                                                            </p>
                                                                            <TOPartsList
                                                                                parts={ drive.to_parts }
                                                                                user={ props.user }
                                                                                canSave={ true }
                                                                                saving={ saving }
                                                                                onSave={ save }
                                                                                onReset={ toResetHandler }
                                                                                onEdit={ toEditHandler }
                                                                            />
                                                                        </>
                                                                    }
                                                                </>
                                                            }

                                                        </Panel.Body>
                                                    </Panel.Collapse>
                                                </Panel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <ValidateAlert errors={ errors }/>
                                            </Col>
                                            <Col md={12}>
                                                <div className="pull-right">
                                                    <Button bsStyle="success" onClick={ () => { fieldsValid() && setShowFinalModal(true) } }>Завершить подбор</Button>
                                                </div>
                                                <FinalWindow
                                                    show={ showFinalModal }
                                                    saving={ saving }
                                                    choseEnabled={ spoilerKey }
                                                    onHide={ () => setShowFinalModal(false) }
                                                    onSubmit={ finalize }
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </>
                        }

                    </div>

                    <div className="block full" >
                        <ScrollableAnchor id={'comments_block'}>
                            <div>
                                <div className="block-title"><h2>Комментарии</h2></div>
                                <CommentPageBlock items={drive.comments}/>
                            </div>
                        </ScrollableAnchor>
                    </div>
                </>
            }
        </>
    );
}
export default withCookies(ChrpDetail);