import React , { useState } from 'react';
import { Col, Button, FormGroup,
         ControlLabel, InputGroup } from 'react-bootstrap';
import Datetime from 'react-datetime';

import moment from 'moment';
import 'moment/locale/ru'


const DateField = props => {

    const [dateValid, setDateValid] = useState(props.value? 'success' : 'error');
    const [editMode, setEditMode] = useState(false);
    
    const validateDate = (date) => {
        setDateValid(typeof date == 'string'? 'error': 'success');
    }

    const handleDate = (date) => {
        validateDate(date);
        props.onChangeDate(date);
    }

    const toggleEdit = () => {
        setEditMode(true);
    }

    const save = () => {
        props.onSave();
        setEditMode(!editMode);
    }

    return (
        <>            
            {!editMode?
                <Col md={12}>
                    <p className="lead">
                        { props.label } {
                            props.value ? 
                            moment(props.value).format('L') :
                            '__.__.____'
                        }
                        {props.editable && 
                            <i className="fa fa-pencil" onClick={ toggleEdit } style={ {marginLeft: '10px', cursor: 'pointer'} }></i>
                        }
                    </p>                          
                </Col>
            :
                <Col xs={6} md={3}>                                                                                           
                    <FormGroup validationState={ dateValid }>
                        <ControlLabel>{ props.label }</ControlLabel>
                        <InputGroup>                            
                            <Datetime
                                value={ (props.value ? moment(props.value) : '') }
                                locale="ru"
                                timeFormat={ false }
                                onChange={ date => handleDate(date) }
                                closeOnSelect={ true }
                            />
                            <InputGroup.Button>
                                <Button bsStyle="primary" disabled={ props.saving } onClick={ evt => save() }>
                                {props.saving? <i className="fa fa-spinner fa-spin"></i> :<i className="hi hi-floppy_disk"></i>}
                                </Button>  
                            </InputGroup.Button>
                        </InputGroup>
                    </FormGroup>                                                
                </Col> 
            }
                       
        </>
    )
}

export default DateField;