import React, { useState, useEffect } from 'react';
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';
import ReactHtmlParser from 'react-html-parser';
import { Button, Alert, Table} from 'react-bootstrap';
import { getDriveStatuses } from '../address/drive-status';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');

const TOChoiceList = (props) => {
    const [show, setShow] = useState(true);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleDismiss = evt => {
        setShow(false);        
    }

    const handleChoice = id => {
        setShow(false);
        let item = items.filter(it => (it.id === id) )[0];        
        props.handleChoice(item);
    }

    useEffect(() => {
        const states = 'chose, no_work_provided'

        const esc = encodeURIComponent;
        const params = { 
            name: props.driveName,
            brand: props.brand,
            state__in: states,
            offset: 0,
            limit: 1
        };
        // this line takes the params object and builds the query string
        const query = '?' + Object.keys(params).map(k => `${esc(k)}=${esc(params[k])}`).join('&');
        getDataRequest(
            props.cookies, '/api/drives/', query
        ).then(
            resp => resp.json()
        ).then(
            res => {
                setItems(res.results? res.results : []);
                setLoading(false);
            }
        ).catch(
            error => {
                setLoading(false);
                console.log('error', error)
            }
        );
    }, []);

    const stmap = getDriveStatuses().reduce((imp, it) => {
        imp[it.value] = it.label;
        return imp;
    }, {});
   
    return (
        <>
        {show &&
            <Alert bsStyle='info' onDismiss={ handleDismiss } style={ {backgroundColor: 'rgba(218, 232, 242, 0.3)'} }>
                {loading ?
                    <div className="text-center">
                        <i className="fa fa-spinner fa-4x fa-spin"></i>
                    </div>
                :   
                    <>
                        {items.length?
                            <>
                            <h3>Похожие подборы ТО</h3>
                            <Table condensed>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Название</th>
                                        <th>Статус</th>
                                        <th>Подбор</th>
                                        <th>Ретрофит/модернизация</th>
                                        <th>Замена</th>
                                        <th>Дата подбора</th>
                                        <th></th>                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((it, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{ReactHtmlParser(it.name)}</td>
                                            <td>{stmap.hasOwnProperty(it.state)? stmap[it.state]: '' }</td>
                                            <td>{it.has_to ? 'Да' : 'Нет' }</td>
                                            <td>{it.planned_retro_modern ? 'Да' : 'Нет' }</td>
                                            <td>{it.planned_replacement ? 'Да' : 'Нет' }</td>
                                            <td>{moment(it.to_choise_date).format('L')}</td>                                           
                                            <th><Button bsStyle="primary" onClick={ () => handleChoice(it.id) }>Выбрать</Button></th>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            </>
                        :
                            <p>Похожие подборы отсутствуют</p>
                        }
                    </>
                }
                <Button 
                    bsStyle="primary"
                    onClick={ handleDismiss }
                    style={ {'marginTop': '10px'} }>Похожих нет</Button>
            </Alert>
        }
        </>
    );
}
export default withCookies(TOChoiceList);
