import React, {Component} from 'react';
import { withCookies } from 'react-cookie';
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';
import RemoteAll from '../table';
import ColumnsManager from './list-columns';
import prepareQueryArgs from './list-argshandler';
import FilterForm from './list-filter';
import AttachWorkButton from './list-addworks';
import AddDibButton from './list-adddib.js';
import { ToolsSlidedown } from '../tools/slidedown';
import getDataRequest from '../tools/datarequest';
import { OverlayTrigger, Tooltip} from 'react-bootstrap';
import { getDriveStatuses } from '../address/drive-status';
import initUpdateWatcher from '../tools/driveupdatewatch';
import ChrpPlanLegend from '../table/chrpplan-legend';


class ChrpList extends Component {

    state = {        
        items: [],
        managers: [],
        sizePerPage: 100,
        page: 1,
        totalSize: 100,
        loading: false,
        isExportClicked: false,        
    }

    extraFilters = {
        'address__client': '',
        'address': '',
        'to_plans__date_before': '',
        'to_plans__date_after': '',
        'date_of_manufacture_before': '',
        'date_of_manufacture_after': '',
    }

    componentDidMount(){
        this.managersRequest();      
        this.dataRequest(this.state.page, this.state.sizePerPage);
        this.uWatch = initUpdateWatcher(this.props.cookies, this.driveUpdateCallback);
    }

    driveUpdateCallback = responsible => {
        this.dataRequest.apply(this, this.tableargs);
    }

    dataRequest = (page, sizePerPage, filters, searchText, sortField, sortOrder) => {
        
        filters = filters || {};
        //add extra filters
        for(let field in this.extraFilters){
            filters[field] = {'filterVal': this.extraFilters[field]};
        }
        var qargs = prepareQueryArgs(page, sizePerPage, filters, searchText, sortField, sortOrder);
        this.setState({loading: true});

        getDataRequest(
            this.props.cookies, '/api/drives/', qargs
        ).then(
            resp => resp.json()
        ).then(
            res => {
                //remove duplicates generatet by filter
                let ids = [];
                let result = res['results'].filter(item => {
                        if(ids.indexOf(item.id) > -1){
                            return false;
                        }
                        ids.push(item.id);
                        return true;
                    }
                );
                this.setState({items: result, totalSize: res['count']});
            }
        ).catch(
            error => console.log('error', error)
        ).finally(
            () => this.setState({loading: false})
        );
    } 

    managersRequest = () => {
        getDataRequest(
            this.props.cookies, '/api/users/', '?groups__name__icontains=manager&is_active=true'
        )
        .then( resp => resp.json() )
        .then( res => this.setState({ managers: res['results'] }) )
        .catch( error => console.log('error', error) );
    }

    tabletimer = ''
    tableargs = []
    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {        
        if(type == 'cellEdit'){
            this.editRequest(cellEdit);            
        } else {
            if(type != 'pagination'){
                page = 1;
            }
            this.setState({ sizePerPage: sizePerPage, page: page });
    
            // Slow down remote requests to avoid duplicate requests when clearing filters.
            this.tableargs = [page, sizePerPage, filters, searchText, sortField, sortOrder];
            if(this.tabletimer == ''){
                this.tabletimer = setTimeout(() => {
                    this.dataRequest.apply(this, this.tableargs);
                    this.tabletimer = '';
                }, 50);
            }
        }
        
    }
   
    extraFilterChangeHandler = (field, value) => {
        if(field === 'to_plans__date' || field === 'date_of_manufacture'){
            if(value){
                this.extraFilters[field + '_after'] = value[0];
                this.extraFilters[field + '_before'] = value[1];
            } else {
                this.extraFilters[field + '_after'] = '';
                this.extraFilters[field + '_before'] = '';
            }
        } else {
            this.extraFilters[field] = value;
        }        
        //use last request table args for fictive filtering 
        this.dataRequest.apply(this, this.tableargs);
    }

    setExportClicked = (value) => {
        this.setState({isExportClicked: value})
    }

    exportCSV = evt => {
        evt.currentTarget.blur();
        evt.preventDefault();        
        this.setExportClicked(true);
    }

    selectedStorage = (function(){
        var storage = [];
        return {
            push: (id) => storage.indexOf(id) + 1 || storage.push(id),
            get: () => ([...storage]),
            remove: (id) => {
                let ind = storage.indexOf(id);
                ind + 1 && storage.splice(ind, 1);
            }
        }
    })();

    toggleSelected = (id, isAdd) => {        
        if(isAdd){
            this.selectedStorage.push(id);
        } else {
            this.selectedStorage.remove(id);
        }
    }

    getSelectedDrivesData = () => {
        let ids = this.selectedStorage.get();
        return this.state.items.filter(it => (ids.indexOf(it.id) + 1));
    }

    selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        onSelect: (row, isSelect, rowIndex, e) => {
            this.toggleSelected(row.id, isSelect);
        },
        onSelectAll: (isSelect, rows, e) => {
            rows.forEach(item => this.toggleSelected(item.id, isSelect));
        }
    };
    
    onAddWorks = (ids) => {
        //use last request table args for reloading 
        this.dataRequest.apply(this, this.tableargs);
    }

    onAddDib = (ids) => {
        //use last request table args for reloading 
        this.dataRequest.apply(this, this.tableargs);
    }
   
    render(){

        const statuses = getDriveStatuses('ingeneer');
        const columnsMgr = new ColumnsManager();
        const columns = columnsMgr.getColumns(this.props.brands, statuses);

        return (
            <React.Fragment>
            <PageHeader title="Таблица ЧРП" subtitle="" icon="fa fa-server"/>
            <Breadcrumbs crumbs={['ЧРП']}/>
            <div className="block full">
                <div className="block-title">
                    <div className="block-options pull-right">
                        <AttachWorkButton getDrives={ this.getSelectedDrivesData } onAdd={ ids => this.onAddWorks() } />
                        <AddDibButton getDrives={ this.getSelectedDrivesData } onAdd={ ids => this.onAddDib() } />
                        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Экспорт в csv</Tooltip>}>
                            <a href="#"
                                className="btn btn-alt btn-sm btn-default"
                                onClick={ this.exportCSV }
                            ><i className="gi gi-file_import"></i></a>
                        </OverlayTrigger>
                    </div>                  
                    <h2>Таблица ЧРП</h2>
                </div>
                
                <ToolsSlidedown open={true}>
                    <FilterForm 
                        columnsMgr={ columnsMgr }
                        statuses={ statuses }
                        managers={ this.state.managers }
                        brands={ this.props.brands }
                        onExtraFilterChange={ this.extraFilterChangeHandler }
                    />                   
                </ToolsSlidedown>

                <ChrpPlanLegend/>

                <RemoteAll
                    data = { this.state.items }
                    columns = { columns }
                    page = { this.state.page }
                    sizePerPage = { this.state.sizePerPage }
                    totalSize = { this.state.totalSize }
                    onTableChange = { this.handleTableChange }
                    loading = { this.state.loading }
                    exportCSV = {{
                            fileName: 'Приводы.csv',
                            separator: ';',
                            isExportClicked: this.state.isExportClicked,
                            setExportClicked: this.setExportClicked,
                            onlyExportSelection: true,
                            exportAll: false
                        }
                    }
                    selectRow = { this.selectRow }                   
                />
                
            </div>
            </React.Fragment>
        );
    }
}

export default withCookies(ChrpList);