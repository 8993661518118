import React from 'react'
import PageHeader from './page_header';
import Breadcrumbs from './breadcrumbs';


function Home(props) {

    return (
        <>
        <PageHeader title="Главная" subtitle="" icon="gi gi-table"/>
        <Breadcrumbs crumbs={['Главная']}/>
        <div className="block">            
            <div className="block-title">
                <div className="block-options pull-right">                    
                    <div className="btn-group btn-group-sm">
                        
                    </div>
                </div>
                <h2>Здесь пока пусто</h2>
            </div>
            <p>...</p>
            
        </div>
        </>
    )
}

export default Home;