import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');

export default function(props){
    const replacer = text => (text.replace(/\r\n/gi, '<br/>'))
    return (
        <>       
            {props.items.map( item => (                                
                        <div className="comment" key={ item.id }>
                            <small className="comment__date">
                                { moment(item.created).format('L') }
                            </small><br/>                            
                            <p className="comment__text">{ ReactHtmlParser(replacer(item.text)) }</p>
                        </div>
                    )
                )
            }
        </>
    )
}
