import serializeArgs from '../tools/serializeargs';

export default function(addressId, page, sizePerPage, filters, searchText, sortField, sortOrder){

    const offset = (page - 1) * sizePerPage;
    var argobj = {limit: sizePerPage, offset: offset, address: addressId}

    if(filters && filters.hasOwnProperty('brand_name')){
        filters['brand'] = {...filters['brand_name']};
    } else if(filters && filters.hasOwnProperty('brand')){
        delete filters['brand'];
    }

    if(searchText){
        argobj['search'] = searchText;
    }
    
    if(sortField){
        argobj['ordering'] = (sortOrder === 'asc' ? '': '-') + sortField;
    }

    for(let fk in filters){
        if(filters.hasOwnProperty(fk)){                
            argobj[fk] = filters[fk]['filterVal'];
        }
    }

    return serializeArgs(argobj);
}