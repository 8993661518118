import React, { useState } from 'react';
import { getWorkStatuses } from '../address/drive-status';
import { ButtonToolbar, Overlay, Popover } from 'react-bootstrap';

import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');

function LonlyComment(props){
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);

    const toggleComments = event => {
        const oldshow = show;        
        setShow(!show);
        if(!oldshow){
            setTarget(event.target);
        }
    }

    const hideComments = evt => {        
        setShow(false);
    }

    if(props.cell == 0){
        return '';
    }
    return (
        <ButtonToolbar className="">
            <i className="fa fa-comment" onClick={ toggleComments } style={{cursor: 'pointer'}}></i>
            <Overlay
                show={ show }
                target={ target }
                placement="left"
                container={ this }
                containerPadding={ 20 }
                rootClose={ true }
                onHide={ hideComments }
                >
                <Popover className="comments" id={'comment-contained-' + props.id} title="Коментарий">                    
                    <div className="comment">
                        <small className="comment__date">                            
                        </small><br/>                            
                        <p className="comment__text">{ props.content }</p>
                    </div>
                </Popover>
            </Overlay>
        </ButtonToolbar>
    );
}

export default function getWorkCellFormatter(){

    const to_kinds = getWorkStatuses().reduce((obj, it) => {
        obj[it.value] = it.label;
        return obj;
    }, {});

    return function(cell, row){
        if(cell){
            return (<>
                { moment(cell).format('L') } {to_kinds[row.last_work_kind]}
                { row.last_work_comment && <LonlyComment content={row.last_work_comment}/>}
            </>);                        
        }                    
        return '';
    }
}