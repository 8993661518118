import React from 'react';
import { InputGroup } from 'react-bootstrap';


export default function SearchBarWithButton(props){
    let input;

    const onChangeHandler = () => {
        if (input.value === '') {
            props.onSearch(input.value);
        }
    }

    const onKeyPressHandler = (event) => {
        if (event.key === 'Enter') {
            props.onSearch(input.value);
        }
    }

    const handleClick = () => {
      props.onSearch(input.value);
    };

    return (
        <div className="dataTables_filter">
            <label>
                <InputGroup>
                    <input
                        className="form-control"
                        ref={ n => input = n }
                        type="text"
                        onKeyPress={ onKeyPressHandler }
                        onChange={ onChangeHandler }
                        />
                    <span className="input-group-addon btn" onClick={ handleClick }>
                        <i className="fa fa-search"></i>
                    </span>
                </InputGroup>
            </label>
        </div>
    );    
};