import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
    Table, FormControl, Button,
    FormGroup, ControlLabel, Row, Col
} from 'react-bootstrap';


function ImportRow(props){

    const [data, setData] = useState(props.data);

    const changeFieldHandler = (evt, i) => {        
        let freshdata = [...data];
        freshdata[i]['value'] = evt.target.value;
        setData(freshdata);
        props.onChange(props.index, freshdata);
    }
    const pm_types = ['PM1', 'PM2', 'PM3', 'PM6', 'PM9', 'PM12', 'PM15', 'PM18', 'PM24'];
    return (
        <tr>
            <td>{props.index + 1}</td>
            {data.map((item, i) => (
                <td key={i}>
                    {item.field === 'text' &&                                                          
                        <FormControl onChange={ (evt) => changeFieldHandler(evt, i) } value={item.value}/> 
                    }                    
                    {item.field === 'pm_type' &&                        
                        <FormControl componentClass="select" onChange={ (evt) => changeFieldHandler(evt, i) } value={item.value}>
                            <option value="">------</option>
                            { pm_types.map(t => (
                                        <option key={ t } value={ t }>
                                            { t }
                                        </option>
                                    )
                                )
                            }
                        </FormControl>
                    }
                    {item.field == 'qty' &&
                        <FormControl style={ {maxWidth: '80px'} } type="number" onChange={ (evt) => changeFieldHandler(evt, i) } value={item.value}/>
                    }
                    {item.field == 'text_short' &&                                                          
                        <FormControl style={ {maxWidth: '80px'} } onChange={ (evt) => changeFieldHandler(evt, i) } value={item.value}/> 
                    }

                </td>
            ))}
        </tr>
    )
}

function ImportTable(props){
    const [packageName, setPackageName] = useState(props.packageName || '');
    const [packageNameValid, setPackageNameValid] = useState(props.packageName? 'success' : 'error');
    const [serial, setSerial] = useState(props.serial || '');
    const [serialValid, setSerialValid] = useState(props.serial? 'success' : 'error');
    const width = 8;

    const genEmptyRowData = () => {
        let row = Array(width).fill().map(i => ({field: 'text', value: ''}));
        row[0]['field'] = 'pm_type';        
        row[3]['field'] = 'qty';
        row[4]['field'] = 'text_short';
        return row;
    };

    const transformExternalData = data => {
        //transform external Data to initial data format
        let out = [];
        for(let i=0; i < data.length; i++){
            let it = data[i];
            out.push([                
                {field: 'pm_type', value: it['material_type']},
                {field: 'text', value: it['code']},
                {field: 'text', value: it['kit_code']},
                {field: 'qty', value: it['quantity']},
                {field: 'text_short', value: it['unit']},
                {field: 'text', value: it['description']},
                {field: 'text', value: it['type_designation']},
                {field: 'text', value: it['technical_description']},                
            ]);
        }
        
        return out;
    }

    const initialData = props.initialData && props.initialData.length? transformExternalData(props.initialData) : Array(props.initialRows || width).fill().map(i => (genEmptyRowData()) );   
    const [tableStorage, setTableStorage] = useState(initialData);

    const cellTransposition = row => {
        //rearranges values of cells in a row in order suitable for importing
        return [
            row[0].value, //material_type
            row[1].value, //code
            row[3].value, //quantity
            row[4].value, //unit
            row[5].value, //description
            row[6].value, //type_designation
            row[7].value, //technical_description
            row[2].value  //kit_code
        ]
    }

    const getData = (table=tableStorage, pkn=packageName, srl=serial) => {

        const height = 12;
        let row = Array(width).fill().map(i => '');
        let head = Array(height).fill().map(i => [...row]);
        let headers = ['Type code:', 'Serial number:', 'Package type:', 'Date of data:',
                'Date of extract:', 'Customer name:', 'Customer site:', 'Functional location:',
                'Customers equipment ID:', 'Total working hours:'];
        //create noempty row heads for tembe intermedia
        for(let i = 0; i < headers.length; i++){
            head[i][0] = headers[i];
        }
        
        head[0][1] = pkn;
        head[1][1] = srl;
        return [...head, ...table.map(row => cellTransposition(row))];
    }

    const onChangeRow = (index, rowdata) => {
        let copy = [...tableStorage];
        copy[index] = rowdata;
        setTableStorage(copy);
        props.onDataChange(getData(copy));
    }

    const addRow = evt => {
        let copy = [...tableStorage];
        let row = genEmptyRowData();
        copy.push(row);
        setTableStorage(copy);    
        props.onDataChange(getData(copy));
    }

    const packageNameHandler = evt => {
        let val = evt.target.value;
        setPackageName(val);
        setPackageNameValid(val.length > 0? 'success': 'error');
        props.onDataChange(getData([...tableStorage], val, serial));
    }

    const serialHandler = evt => {
        let val = evt.target.value;
        setSerial(val);
        setSerialValid(val.length > 0? 'success': 'error');
        props.onDataChange(getData([...tableStorage], packageName, val));
    }

    return (
        <>
            {!props.onlyTable &&
                <Row>
                    <Col xs={6} md={5}>
                        <FormGroup validationState={packageNameValid}>
                            <ControlLabel>Имя пакета ТО*</ControlLabel>
                            <FormControl onChange={ packageNameHandler } value={ packageName }/>
                        </FormGroup>
                    </Col>
                    <Col xs={6} md={3}>
                        <FormGroup validationState={serialValid}>
                            <ControlLabel>Серийный номер*</ControlLabel>
                            <FormControl onChange={ serialHandler } value={ serial }/>
                        </FormGroup>                
                    </Col>
                </Row>
            }
            
            <Table striped bordered={false} condensed>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Тип ТО</th>
                        <th>Код</th>
                        <th>Код наб.</th>
                        <th>Кол-во</th>
                        <th>Ед.</th>
                        <th>Описание</th>                        
                        <th>Обозначение</th>
                        <th>Техническое описание</th>                        
                    </tr>                
                </thead>
                <tbody>
                    {tableStorage.map((item, i) => (<ImportRow key={i} index={i} data={item} onChange={onChangeRow} />))}
                </tbody>
            </Table>
            <Button className="pull-right" onClick={addRow}><i className="fa fa-plus"></i> строка</Button>
        </>
    )
}

ImportTable.propTypes = {
    initialRows: PropTypes.number,
    withDate: PropTypes.bool,
    onChange: PropTypes.func,
};

export default ImportTable;