import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { 
    Row, Col,
    FormGroup, FormControl,
    ControlLabel, Button, Alert } from 'react-bootstrap';
import getDataRequest from '../tools/datarequest';
import ExcelDropZone from '../tools/exceldropzone';
import ImportTable from './import-table';
import Switch from '../table/switch';
import m_package from '../../docs/m_package.xlsx';


class MPackagesImportBody extends Component{

    state = {
        brands: this.props.brands || [],
        brandChoice: this.props.brand? this.props.brand : (this.props.brands.length? this.props.brands[0]['id']: ''),
        datafile: null,        
        invalidMessages: [],
        isLoading: false,
        manualImport: this.props.manually? true : false,
        manualData: [],
        initialData: this.props.initialData
    }

    changeBrandHandler = (evt) => {
        this.setState({brandChoice: evt.target.value});
    }

    changeFileHandler = (file) => {
        this.setState({datafile: file, invalidMessages: []})
    }

    handleDismiss = (e, i) => {
        let mes = [...this.state.invalidMessages];
        mes.splice(i, 1);
        this.setState({invalidMessages: mes});
    }
 
    buttonHandler = (evt) => {        
        evt.preventDefault();
        let messages = [];
        if(!this.state.brandChoice){
            messages.push({
                title: "Укажите бренд",
                message: "Поле бренд обязательно для заполнения",
                variant: "danger"
            });
        }
    
        if(!this.state.datafile && !this.state.manualImport){
            messages.push({
                title: "Необходимы файлы",
                message: "Загрузите файлы для импорта",
                variant: "danger"
            });
        }

        if(!this.state.manualData && this.state.manualImport){
            messages.push({
                title: "Необходимы данные",
                message: "Заполните таблицу для импорта",
                variant: "danger"
            });
        }
        
        if(messages.length){
            this.setState({invalidMessages: messages});
        } else {
            this.formSend();
        }       
    }

    handleSuccess = (id, name) => {
        if(this.props.onSuccess){
            this.props.onSuccess(id);
        }
    }

    formSend = () => {
        let data = {
            brand: this.state.brandChoice,            
        }
        if(this.state.manualImport){
            data['datatable'] = JSON.stringify(this.state.manualData);
        } else {
            data['datafile'] = this.state.datafile;
        }
        this.setState({invalidMessages: [], isLoading: true});
        getDataRequest(
            this.props.cookies, '/api/packages/dataimport/', '', 'POST', data
        ).then(
            resp => {
                if(resp.status >= 500){
                    throw "Произошла непредвиденная ошибка. Исправьте файл импорта согласно шаблону.";
                }
                if(resp.status === 403){
                    throw "У вас нет прав на совершение данной операции.";
                }
                return resp.json()
            }
        ).then(
            res => {             
                let messages = [];
                if(res.status === 'error'){                    
                    let mes = {
                        title: res.message,
                        variant: 'danger',
                        message: (res.detail.map((m, i) => (<span key={i}>{ m }<br/></span>))),
                    }
                    messages.push(mes);          
                } else {
                    messages.push(
                        {
                            title: "Поздравляем",
                            message: "Импорт завершен удачно"
                        }                    
                    );
                    this.handleSuccess(res['package_id']);
                }
                this.setState({invalidMessages: messages});
            }
        ).catch(
            error => {                
                this.setState({ invalidMessages: [{
                    title: "Произошла ошибка", variant: 'danger', message: error}] });
            }
        ).finally(
            () => this.setState({isLoading: false})
        );
    }

    isManuslDataValid = () => {
        let md = this.state.manualData;
        const tablevalid = (res, item) => {
            return res || item.length && item[0] && item[1] && item[3];
        }
        return (
            this.state.manualImport && md.length 
            && md[0][1].length && md[1][1].length 
            && md.slice(12, -1).reduce(tablevalid, false)
        )            
    }

    isAllValid = () => {
        return !!(this.state.brandChoice && (this.state.datafile || this.isManuslDataValid()));
    }

    render(){

        return (
            <>
                <Row>
                    {!this.props.brand &&
                        <Col xs={6} md={3}>
                            <FormGroup>
                                <ControlLabel>Бренд</ControlLabel>
                                <FormControl componentClass="select" onChange={ this.changeBrandHandler } value={this.state.brandChoice}>                                        
                                    { this.state.brands.map(b => (
                                                <option key={ b.id } value={ b.id }>
                                                    { b.name }
                                                </option>
                                            )
                                        )
                                    }
                                </FormControl>
                            </FormGroup>
                        </Col>
                    }
                    <Col xs={2} className={!!this.props.brand? 'pull-right' :''}>
                        <FormGroup>
                            {!this.props.brand && <><ControlLabel>Ручной ввод</ControlLabel><br/></>}
                            <Switch id="importtype_toggler" name="manual_import"
                                checked={ this.state.manualImport }
                                onChange={ (id, name, val) => this.setState( {manualImport: !this.state.manualImport} ) }                                 
                                />
                            {!!this.props.brand && <ControlLabel style={ {marginLeft: '10px'} }>Ручной ввод</ControlLabel>}
                        </FormGroup>                        
                    </Col>   
                </Row>
                <Row>                   
                    <Col xs={12}>
                        {!this.state.manualImport?
                            <FormGroup>
                                <ExcelDropZone pushFile={ (file) => this.changeFileHandler(file) } fileExample={m_package}/>
                            </FormGroup>
                        :
                            <ImportTable
                                packageName={ this.props.mark }
                                serial={ this.props.serial }
                                onlyTable={ !!this.props.brand }
                                onDataChange={ data => this.setState({manualData: data}) }
                                initialData={ this.state.initialData }
                                />
                        }
                    </Col>
                    
                    {this.state.invalidMessages.length ?
                        <Col xs={12}>
                            {this.state.invalidMessages.map((m, i) => (
                                <Alert key={i} bsStyle={ m.variant } onDismiss={ (e) => this.handleDismiss(e, i) } style={{marginTop: '20px'}} >
                                    <h4>{ m.title }</h4>
                                    <p>{ m.message }</p>
                                </Alert>
                            ))}
                        </Col>
                        : ''   
                    }
                    <Col xs={12} className="text-center">
                        <Button bsSize="large" bsStyle="info"  disabled={ this.state.isLoading || !this.isAllValid() } onClick={ this.buttonHandler }>
                            {this.state.isLoading ?
                                <i className="fa fa-spinner fa-spin"></i>
                            :
                                <i className="fa fa-upload"></i>
                            } Импорт
                        </Button>
                    </Col>                        
                </Row>
            </>
        )
    }
}

export default withCookies(MPackagesImportBody);