import React, { useState, useEffect } from 'react';
import { withCookies } from 'react-cookie';
import { useParams } from "react-router-dom";
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';
import RemoteAll from '../table';
import ColumnsManager from './parts-columns';
import prepareQueryArgs from './parts-argshandler';
import FilterForm from './parts-filter';
import CommentPageBlock from '../comment/page';
import ScrollableAnchor from 'react-scrollable-anchor';
import {ToolsSlidedown} from '../tools/slidedown';
import getDataRequest from '../tools/datarequest';
import { OverlayTrigger, Tooltip} from 'react-bootstrap';


function MPackagesPartsList(props){

    const { mpId, pmNum } = useParams();

    const [ items, setItems ] = useState([]);
    const [ mpackage, setMpackage ] = useState({type_code: '', comments: []});
    const [ sizePerPage, setSizePerPage ] = useState(100);
    const [ page, setPage ] = useState(1);
    const [ totalSize, setTotalSize ] = useState(100);
    const [ loading, setLoading ] = useState(false);
    const [ filterVisible, setFilterVisible ] = useState(false);
    const [ isExportClicked, setIsExportClicked ] = useState(false);
    
    useEffect(() => {
        var cleanupFunction = false;
        if(mpId){
            getDataRequest(
                props.cookies, `/api/packages/${mpId}`, ''
            ).then(
                resp => {
                    if(resp.status === 404){
                        throw 'Package not found';
                    } else {
                        return resp.json()
                    }
                }
            ).then(
                res => {
                    
                    if(!cleanupFunction) setMpackage( res );                        
                }
            ).catch(error => console.log('error', error));

            dataRequest( page, sizePerPage );
        }

        return () => cleanupFunction = true;         
    }, [mpId]);

    
    const dataRequest = (page, sizePerPage, filters, searchText, sortField, sortOrder) => {

        if(!filters){
            filters = {};
        }
        if(!mpId){
            return false;
        }
        filters['package'] = {'filterVal': mpId};
        
        if(!filterVisible && pmNum && !filters.hasOwnProperty('material_type')){
            filters['material_type'] = {'filterVal': pmNum};
        }
        let qargs = prepareQueryArgs(page, sizePerPage, filters, searchText, sortField, sortOrder);
        setLoading(true);
        getDataRequest(
            props.cookies, '/api/packageparts/', qargs
        ).then(
            resp => {
                if(resp.status === 400){ throw 'Bad request'; }
                return resp.json();
            }
        ).then(
            res => {                
                setItems( res['results'] );
                setTotalSize( res['count'] );
                setLoading( false );
            }
        ).catch(error => console.log('error', error));
    }

    const createTableHandler = () => {
        let tabletimer = ''
        let tableargs = []
        return (type, { page, sizePerPage, filters, sortField, sortOrder, searchText }) => {        
            
            if(type != 'pagination'){
                page = 1;
            }
            setSizePerPage(sizePerPage);
            setPage(page);
            // Slow down remote requests to avoid duplicate requests when clearing filters.
            tableargs = [page, sizePerPage, filters, searchText, sortField, sortOrder];
            if(tabletimer == ''){
                tabletimer = setTimeout(() => {
                    dataRequest.apply(this, tableargs);
                    tabletimer = '';
                }, 50);
            }
        }
    };
    const handleTableChange = createTableHandler();
   
    const exportCSV = evt => {
        evt.currentTarget.blur();
        evt.preventDefault();
        setIsExportClicked(true);
    }

    const columnsMgr = new ColumnsManager();
    const columns = columnsMgr.getColumns(pmNum);

    const toggleFilter = evt => {
        evt.currentTarget.blur();
        evt.preventDefault();
        columnsMgr.cleanFilters();
        setFilterVisible(!filterVisible);
        if(!filterVisible){
            setTimeout(
                () => {
                    // Ensures the visibility of the nested calendar
                    setFilterVisible(!filterVisible);
                },
                300
            );
        }
    }
    
    const crumbs = pmNum? ['Комплекты ТО', mpackage.type_code, pmNum]: ['Комплекты ТО', mpackage.type_code]; 
    return (
        <React.Fragment>
            <PageHeader title="Комплект ТО"
                subtitle={ 
                <>Комплект ТО { mpackage.type_code } { pmNum && pmNum }</>}
                icon="gi gi-table"/>
            <Breadcrumbs crumbs={crumbs}/>
            <div className="block full">            
                <div className="block-title">
                    <div className="block-options pull-right">
                        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Фильтрация</Tooltip>}>
                            <a href="#" 
                                className="btn btn-alt btn-sm btn-default"
                                onClick={ toggleFilter }
                            ><i className="fa fa-filter"></i></a>
                        </OverlayTrigger>                    
                        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Экспорт в csv</Tooltip>}>
                            <a href="#"
                                className="btn btn-alt btn-sm btn-default"
                                onClick={ exportCSV }
                            ><i className="gi gi-file_import"></i></a>
                        </OverlayTrigger>
                    </div>
                    <h2>Комплект ТО { mpackage.type_code } { pmNum && pmNum }</h2>
                </div>

                <ToolsSlidedown open={ filterVisible }>
                    <FilterForm columnsMgr={ columnsMgr }
                        pmOptions={ columnsMgr.getPmOptions() }
                        pmDefault={ pmNum ? pmNum : '' } />                   
                </ToolsSlidedown>
                
                <RemoteAll
                    data = { items }
                    columns = { columns }
                    page = { page }
                    sizePerPage = { sizePerPage }
                    totalSize = { totalSize }
                    onTableChange = { handleTableChange }
                    loading = { loading }
                    exportCSV = {{
                            fileName: 'Комплект_ТО.csv',
                            isExportClicked: isExportClicked,
                            setExportClicked: setIsExportClicked,
                            onlyExportSelection: true,
                            exportAll: false
                        }
                    }
                    selectRow = {{mode: 'checkbox', clickToSelect: false}}
                    
                />
                
            </div>

            <div className="block full" >
                <ScrollableAnchor id={'comments_block'}>
                    <div>
                    <div className="block-title"><h2>Комментарии</h2></div>
                    <CommentPageBlock items={mpackage.comments}/>
                    </div>
                </ScrollableAnchor>
            </div>
        </React.Fragment>
    );
    
}

export default withCookies(MPackagesPartsList);