
import React from 'react';
import PropTypes from 'prop-types';

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import SearchBarWithButton from './searchbar';
import {paginationTotalRenderer, paginationButtonRenderer} from './pagination';
import paginationFactory, {
    PaginationProvider,
    SizePerPageDropdownStandalone,
    PaginationListStandalone,
    PaginationTotalStandalone} from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import overlayFactory from 'react-bootstrap-table2-overlay';
import ToolkitProvider from './react-bootstrap-table2-toolkit/lib/provider';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';


const OverlaySpinner = () => (<i className="fa fa-spinner fa-4x fa-spin"></i>);

const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
}];

let exporterCall = () => {
    console.log('Export button is not definned');
}

const PseudoExportButton = (props) => {
    // Pseudo button. Reassigns export function to an external component.
    exporterCall = props.onExport;
    return (<></>);
};

function RemoteAll({data, columns, page, sizePerPage, onTableChange, totalSize, loading, exportCSV, selectRow}){
    

    React.useEffect(() => {
        // Starts export by clicking on an external button
        if (exportCSV.isExportClicked) {            
            exporterCall();
            exportCSV.setExportClicked(false);
        }
    }, [exportCSV.isExportClicked, exportCSV.setExportClicked]);

    return (
    <ToolkitProvider 
        keyField="id"
        data={ data }
        columns={ columns }
        search
        exportCSV={ exportCSV }>
        {
            toolkitprops => (
            <div>
                <PseudoExportButton { ...toolkitprops.csvProps }/>
                <PaginationProvider
                    pagination={ paginationFactory({ 
                        page,
                        sizePerPage,
                        totalSize,
                        sizePerPageList: [20, 50, 100, 300],
                        showTotal: true,
                        custom: true,
                        paginationTotalRenderer,
                        paginationButtonRenderer,             
                        alwaysShowAllBtns: true,
                    }) }
                >
                    {
                        ({ paginationProps, paginationTableProps }) => (
                            <div className="dataTables_wrapper form-inline no-footer">                                
                                <Row>
                                    <Col xs={5} sm={6}>
                                        <SizePerPageDropdownStandalone { ...paginationProps }/>                                        
                                    </Col>
                                    <Col xs={7} sm={6}>
                                        <SearchBarWithButton { ...toolkitprops.searchProps } />
                                    </Col>                                    
                                </Row>

                                <BootstrapTable
                                    { ...toolkitprops.baseProps }
                                    wrapperClasses='table-responsive'
                                    remote
                                    loading={loading}
                                    defaultSorted={ defaultSorted }
                                    filter={ filterFactory() } 
                                    cellEdit={ cellEditFactory({ mode: 'dbclick', blurToSave: true}) }
                                    onTableChange={ onTableChange }
                                    striped
                                    hover
                                    noDataIndication={ (<div className="text-center">Ничего не найдено</div>) }
                                    overlay={ overlayFactory({ 
                                            spinner: (<OverlaySpinner/>),
                                            fadeSpeed: 200,
                                        }) }
                                    selectRow={ selectRow }
                                    { ...paginationTableProps }
                                />
                                
                                <Row>
                                    <Col sm={5} className="hidden-xs">
                                        <PaginationTotalStandalone { ...paginationProps } />
                                    </Col>
                                    <Col sm={7} xs={12} className="clearfix">
                                        <div className="dataTables_paginate paging_bootstrap">                             
                                            <PaginationListStandalone { ...paginationProps } />  
                                        </div>                              
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </PaginationProvider>
            </div>
            )
        }
    </ToolkitProvider>
    )
};

RemoteAll.propTypes = {
    data: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    columns: PropTypes.array.isRequired,
    totalSize: PropTypes.number.isRequired,
    sizePerPage: PropTypes.number.isRequired,
    onTableChange: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    exportCSV: PropTypes.object.isRequired,
};

export default RemoteAll;