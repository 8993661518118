import React, {Component} from 'react';
import { withCookies } from 'react-cookie';
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';
import RemoteAll from '../table';
import ColumnsManager from './list-columns';
import prepareQueryArgs from './list-argshandler';
import FilterForm from './list-filter';
import FileSaver from 'file-saver';
import {ToolsSlidedown} from '../tools/slidedown';
import getDataRequest from '../tools/datarequest';
import { OverlayTrigger, Tooltip} from 'react-bootstrap';


class MPackagesList extends Component {

    state = {
        items: [],
        brands: [],
        sizePerPage: 20,
        page: 1,
        totalSize: 100,
        loading: false,
        filterVisible: false,
        filterClassname: '',
        isExportClicked: false,
    }    

    componentDidMount(){   
        this.brandsRequest();
        this.dataRequest(this.state.page, this.state.sizePerPage);
    }

    dataRequest = (page, sizePerPage, filters, searchText, sortField, sortOrder) => {
        
        var qargs = prepareQueryArgs(page, sizePerPage, filters, searchText, sortField, sortOrder);
        this.setState({loading: true});

        getDataRequest(
            this.props.cookies, '/api/packages/', qargs
        ).then(
            resp => resp.json()
        ).then(
            res => this.setState({
                    items: res['results'],
                    totalSize: res['count'],
                    loading: false
                })
        ).catch(error => console.log('error', error));
    }
    
    brandsRequest = () => {
        getDataRequest(
            this.props.cookies, '/api/brands/', ''
        ).then(
            resp => resp.json()
        ).then(
            res => this.setState({brands: res})
        ).catch(error => console.log('error', error));
    }

    tabletimer = ''
    tableargs = []
    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, searchText }) => {        
        
        if(type != 'pagination'){
            page = 1;
        }
        this.setState({ sizePerPage: sizePerPage, page: page });

        // Slow down remote requests to avoid duplicate requests when clearing filters.
        this.tableargs = [page, sizePerPage, filters, searchText, sortField, sortOrder];
        if(this.tabletimer == ''){
            this.tabletimer = setTimeout(() => {
                this.dataRequest.apply(this, this.tableargs);
                this.tabletimer = '';
            }, 50);
        }
    }
   
    setExportClicked = (value) => {
        this.setState({isExportClicked: value})
    }

    exportCSV = evt => {
        evt.currentTarget.blur();
        evt.preventDefault();        
        this.setExportClicked(true);
    }

    downloadPackage = id => {
                
        getDataRequest(
            this.props.cookies, `/api/packages/${id}/export/`, '', 'POST'
        ).then(
            resp => {
                if(resp.status > 400){
                    throw `Error ${resp.status}`;
                }
                return resp.blob();
            }   
        ).then(
            res => {
                FileSaver.saveAs(res, 'package_export.xlsx');
            }
        ).catch(error => {
                console.log('error', error)
            }
        )
        
    }

    render(){
        
        const badges = [
            {value: 'info_data_changed', label: 'Данные изменены'},
            {value: 'warning_need_update', label: 'Обновить!'},
            {value: 'warning_undefined_parts', label: 'Неопознанный ЗИП'}
        ]

        const columnsMgr = new ColumnsManager();
        const columns = columnsMgr.getColumns(this.state.brands, badges, this.downloadPackage);

        const toggleFilter = evt => {
            evt.currentTarget.blur();
            evt.preventDefault();
            columnsMgr.cleanFilters();
            let visible = this.state.filterVisible;
            this.setState({filterVisible: !visible});
            if(!visible){
                setTimeout(
                    () => {
                        // Ensures the visibility of the nested calendar
                        this.setState({filterVisible: !visible});
                    },
                    300
                );
            }
        }
        
        return (
            <React.Fragment>
            <PageHeader title="Комплекты ТО"
                subtitle={ 
                    <>Каталог добавленных в приложение полных комплектов ТО.
                    Позиции с отметкой <span className="label label-warning">Warning</span>&nbsp;
                    обязательны к обновлению.</>}
                icon="gi gi-table"/>
            <Breadcrumbs crumbs={['Комплекты ТО']}/>
            <div className="block full">            
                <div className="block-title">
                    <div className="block-options pull-right">
                        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Фильтрация</Tooltip>}>
                            <a href="#" 
                                className="btn btn-alt btn-sm btn-default"
                                onClick={ toggleFilter }
                            ><i className="fa fa-filter"></i></a>
                        </OverlayTrigger>                    
                        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Экспорт в csv</Tooltip>}>
                            <a href="#"
                                className="btn btn-alt btn-sm btn-default"
                                onClick={ this.exportCSV }
                            ><i className="gi gi-file_import"></i></a>
                        </OverlayTrigger>
                    </div>
                    <h2>Все комплекты</h2>
                </div>

                <ToolsSlidedown open={this.state.filterVisible}>
                    <FilterForm columnsMgr={ columnsMgr } brands={ this.state.brands } badges={ badges }/>                   
                </ToolsSlidedown>

                <RemoteAll
                    data = { this.state.items }
                    columns = { columns }
                    page = { this.state.page }
                    sizePerPage = { this.state.sizePerPage }
                    totalSize = { this.state.totalSize }
                    onTableChange = { this.handleTableChange }
                    loading = { this.state.loading }
                    exportCSV = {{
                            fileName: 'Комплекты_ТО.csv',
                            isExportClicked: this.state.isExportClicked,
                            setExportClicked: this.setExportClicked,
                            onlyExportSelection: true,
                            exportAll: false
                        }
                    }
                    selectRow = {{mode: 'checkbox', clickToSelect: false}}
                    
                />
                
            </div>
            </React.Fragment>
        );
    }
}

export default withCookies(MPackagesList);