import React, { useState, useCallback } from 'react';
import { Alert } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import FileSaver from 'file-saver';


export default function ExcelDropZoneMultiple(props){

    const [fileList, setFileList] = useState([]);
    const [invalidFiles, setInvalidFiles] = useState([]);

    const handleDismiss = (ind) => {
        setInvalidFiles([...invalidFiles].filter((mes, i) => i !== ind));
    }

    const onDrop = useCallback(acceptedFiles => {
       
        if(acceptedFiles.length){            
            let files = acceptedFiles;
            let ext = ['xls', 'xlsx', 'csv'];
            let valid = [];
            let invalid = [];
            let all_valid_files = []
            files.forEach((f, ind) => {
                let fExt = f.name.split('.').slice(-1)[0];
                let fName = f.name.length > 53? f.name.slice(0, 50) + '...': f.name;

                if(ext.indexOf(fExt) + 1 ){  
                    valid.push({name: fName, ext: fExt});
                    all_valid_files.push(f);
                } else {
                    invalid.push({
                        title: `Неверное расширение ${fExt}`,
                        message: 'Файлы должны иметь расширение .' + ext.join(', .')}
                    );
                }
            });
            if(all_valid_files.length){
                props.pushFiles(all_valid_files);
            }            
            setFileList(valid);
            setInvalidFiles(invalid);
        }
      
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: true})

    const onClickExample = evt => { 
        evt.stopPropagation();
        evt.preventDefault();
        let ext = props.fileExample.split('.').slice(-1)[0];
        FileSaver.saveAs(props.fileExample, `example.${ext}`);
    }
  
    return (
        <>
        <div className={'dropzone dz-clickable ' + (isDragActive? 'dz-drag-hover' : '' )} {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="dz-default dz-message">
                {fileList && !!fileList.length?
                        <React.Fragment>
                            <p className="text-center">
                                <i className={'fi fi-' + fileList[0].ext} style={ {fontSize: '80px', opacity: '0.9'} }></i>
                            </p>
                            <span className="dz-message__tale_name">Количество пакетов для парсинга: {fileList.length}</span>
                        </React.Fragment>
                    :                     
                    (<div>
                        <p className="text-center">
                            <i className="gi gi-download_alt" style={ {fontSize: '80px', opacity: '0.9'} }></i>
                        </p>
                        <span>Перетащите файлы сюда <br/>или кликните для загрузки</span><br/>
                        <span className="dz-message__subline">файлы должны иметь формат .xls, .xlsx или .csv</span>
                        {props.fileExample &&
                            <>
                                <br/>
                                <span className="dz-message__subline">
                                    <a href="#" onClick={ onClickExample }>шаблон файла для загрузки</a>
                                </span>
                            </>
                        }
                    </div>)
                }
            </div>
        </div>
        {invalidFiles && !!invalidFiles.length &&
                invalidFiles.map((mes, ind) => (
                    <Alert key={ind} bsStyle="danger" onDismiss={ ev => {handleDismiss(ind)} } style={{marginTop: '20px'}}>
                        <h4>{ mes.title }</h4>
                        <p>{ mes.message }</p>
                    </Alert>
                ) )             
            }
        </>
    )
}