import React, {useState} from 'react';

/**
 * Switch widget that work for no editable fields
 * 
 * @param {object} props 
 */
function Switch(props){

    const [checked, setChecked] = useState(props.checked);

    const onSwitch = (evt) => {
        setChecked(evt.target.checked);
        props.onChange(props.id, props.name, evt.target.checked);
    }

    const variants = ['default', 'primary', 'info', 'success', 'warning', 'danger'];
    let addition = 'switch-' + variants[1];
    if(variants.indexOf(props.variant) + 1){
        addition = 'switch-' + props.variant;
    }
    if(props.disabled){
        addition = 'switch-' + variants[0];
    }
    return (<label className={ 'switch ' + addition }>
                <input type="checkbox"
                    checked={ checked }
                    onChange={ onSwitch }
                    disabled={ props.disabled || false }/><span></span>
            </label>)
}
export default Switch;
