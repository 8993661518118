import React, { useState, useCallback } from 'react';
import { Alert } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import FileSaver from 'file-saver';


export default function ExcelDropZone(props){

    const [invalidFile, setInvalidFile] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileExt, setFileExt] = useState('');

    const handleDismiss = () => {
        setInvalidFile(false);
    }

    const onDrop = useCallback(acceptedFiles => {
       
        if(acceptedFiles.length){
            
            let file = acceptedFiles[0];
            let ext = ['xls', 'xlsx', 'csv'];
            let fExt = file.name.split('.').slice(-1)[0];
            let fName = file.name.length > 53? file.name.slice(0, 50) + '...': file.name;

            if(ext.indexOf(fExt) + 1 ){  
                setInvalidFile(false);
                setFileName(fName);
                setFileExt(fExt) 
                props.pushFile(acceptedFiles[0]);
            } else {
                setInvalidFile({
                    title: 'Неверное расширение',
                    message: 'Загрузите файл с расширением .' + ext.join(', .')});
            }
        }
      
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: false})

    const onClickExample = evt => { 
        evt.stopPropagation();
        evt.preventDefault();
        let ext = props.fileExample.split('.').slice(-1)[0];
        FileSaver.saveAs(props.fileExample, `example.${ext}`);
    }
  
    return (
        <>
        <div className={'dropzone dz-clickable ' + (isDragActive? 'dz-drag-hover' : '' )} {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="dz-default dz-message">
                {fileName?
                    (<><p className="text-center">
                        <i className={'fi fi-' + fileExt} style={ {fontSize: '80px', opacity: '0.9'} }></i>
                    </p>
                    <span className="dz-message__subline">{fileName}</span></>):                     
                    (<><p className="text-center">
                        <i className="gi gi-download_alt" style={ {fontSize: '80px', opacity: '0.9'} }></i>
                    </p>
                    <span>Перетащите файл сюда <br/>или кликните для загрузки</span><br/>
                    <span className="dz-message__subline">файл должен иметь формат .xls, .xlsx или .csv</span>
                    {props.fileExample &&
                        <>
                            <br/>
                            <span className="dz-message__subline">
                                <a href="#" onClick={ onClickExample }>шаблон файла для загрузки</a>
                            </span>
                        </>
                    }

                    </>)
                }
            </div>
        </div>
        {invalidFile?
            <Alert bsStyle="danger" onDismiss={ handleDismiss } style={{marginTop: '20px'}}>
                <h4>{ invalidFile.title }</h4>
                <p>{ invalidFile.message }</p>
            </Alert>:''}
        </>
    )
}