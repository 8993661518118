import React, { useState, useEffect } from 'react';
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';
import {
        Modal, Button, OverlayTrigger, Tooltip, Form,
        Alert, FormGroup, ControlLabel
    } from 'react-bootstrap';
import FieldGroup from '../tools/fieldgroup';
import Datetime from 'react-datetime';
import DrivesList from '../tools/driveslist.js';


function SendToChoiceButton(props) {

    const [show, setShow] = useState(false);
    const [date, setDate] = useState('');
    const [dateValid, setDateValid] = useState(null);    
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [drives, setDrives] = useState([]);


    useEffect(() => {        
        const getDrivesData = async () => {
            let dr = props.getDrives();
            setDrives(dr);
        };  
        show && getDrivesData();
     }, [show]);
    
    const isAllValid = () => {
        let key = 'success';
        return dateValid === key && drives.length;
    }

    const handleClose = () => {      
        setShow(false);
        setDate('');
        setDateValid(null);
        setMessage('');   
    }
    
    const handleShow = evt => {
        evt.currentTarget.blur();
        setShow(true);
    }

    const getDateState = (value) => {
        let valstate = null;
        if(value === ''){
            valstate = null;
        } else if(/^\d\d\.\d\d\.\d\d\d\d$/.test(value)){
            valstate = 'success';
        } else {
            valstate = 'error';
        }
        return valstate;
    }

    const changeDateHandler = (date) => {
        var outdate = typeof date !== 'string'? date.add(12, 'hours').format('L') : '';
        if(outdate !== date){
            setDate(outdate);
            setDateValid( getDateState(outdate) );
        }
    }

    const messageHandler = evt => {
        setMessage(evt.target.value);
    }

    const sendRequest = (evt) => {
        let mes = `Желаемая дата готовности ${date}\r\n ${message}`;
        let data = {message: mes, ids: drives.map(d => d.id)};
        setLoading(true);
        setErrors([]);

        getDataRequest(
            props.cookies, `/api/drives/send_to_selection/`, '', 'POST', data
        ).then(
            resp => resp.json()   
        ).then(
            res => {                    
                props.onSend(data.ids);
                handleClose();                
            }
        ).catch(error => {            
                console.log('error', error);
            }
        ).finally(
            () => setLoading(false)
        );
    }

    return (
        <>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Отправить на подбор</Tooltip>}>
            <a href="#" className="btn btn-alt btn-sm btn-default" onClick={ handleShow }>
                <i className="fa fa-rocket"></i>
            </a>
        </OverlayTrigger>
        <Modal show={show} onHide={ handleClose }>
        <Modal.Header closeButton>
            <Modal.Title>Отправка приводов на подбор</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                {errors.map((error, i) => (
                        <Alert key={i} bsStyle='danger' >{ error }</Alert>
                    )
                )}
                {!drives.length &&
                    <Alert bsStyle='danger' >Внимание, не выбрано ни одного привода!</Alert>
                }
                <FormGroup controlId="" validationState={ dateValid||null }>
                    <ControlLabel>Желательная дата готовности</ControlLabel>
                    <Datetime 
                        locale="ru"
                        timeFormat={ false }
                        onChange={ changeDateHandler }
                        closeOnSelect={ true }
                        value={ date }
                    />                    
                </FormGroup>
                <FieldGroup 
                    id="formControlsMessage"
                    label="Сообщение"
                    componentClass="textarea"
                    value={ message }
                    onChange={ messageHandler }
                    validationState={ null }
                />

                <DrivesList drives={ drives }/>
                
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={ handleClose }>Отменить</Button>
            <Button bsStyle="primary" onClick={ sendRequest } disabled={ !isAllValid() || loading }>Отправить</Button>
        </Modal.Footer>
        </Modal>
        
        </>
    );
}
export default withCookies(SendToChoiceButton);