import React, { useState } from 'react';
import { Button, Panel, Table } from 'react-bootstrap';

const DrivesList = props => {
    const [showDrives, setShowDrives] = useState(false);

    return (
        <>
        {!!props.drives.length &&
            <>
                <Button bsStyle="link" onClick={() => setShowDrives(!showDrives)}>
                    Список приводов ({ props.drives.length })&nbsp;
                    <i className={'fa fa-eye' + (!showDrives?'':'-slash')}></i>
                </Button>
                <br/>
                <Panel onToggle={ a => (a)} expanded={ showDrives } style={{border: 'none'}}>
                    <Panel.Collapse>
                        <Panel.Body style={{maxHeight: '250px', overflow: 'auto'}}>
                            <Table striped condensed hover>
                                <thead>
                                    <tr>
                                        <th>#</th><th>Бренд</th><th>Серийный номер</th>
                                    </tr>
                                </thead>
                                <tbody>                                    
                                    {props.drives.map( (d, i) => (
                                        <tr key={d.id}>
                                            <td>{i + 1}</td><td>{d.brand_name}</td><td> {d.serial_number}</td>
                                        </tr>)
                                    )}
                                </tbody>
                            </Table>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            </>
        }
        </>
    )
}

export default DrivesList