import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    Row, Col, Form, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import ColumnsManager from './list-columns';


class FilterForm extends Component{
    
    changeActivHandler = evt => {        
        this.props.columnsMgr.getFilters()['is_active'](evt.target.value);
    }

    changeGroupHandler = evt => {   
        this.props.columnsMgr.getFilters()['is_admin'](evt.target.value);
    }
    
    render(){
        return (
            <Row style={ {marginBottom: '2rem'} }>
                <Col md={12}>
                    <Form>
                        <h3>Фильтрация</h3>
                        <Col xs={6} md={2}>
                            <FormGroup>
                                <ControlLabel>Пользователь активен</ControlLabel>
                                <FormControl componentClass="select" onChange={ this.changeActivHandler }>
                                    <option value="">без разницы</option>
                                    <option value="true">только активные</option>
                                    <option value="false">только неактивные</option>
                                </FormControl>
                            </FormGroup>
                        </Col>
                        <Col xs={6} md={2}>
                            <FormGroup>
                                <ControlLabel>Группа пользователей</ControlLabel>
                                <FormControl componentClass="select" onChange={ this.changeGroupHandler }>
                                    <option value="">все группы</option>
                                    {this.props.groups.map((g, i) => (<option key={ i } value={ g.value }>{ g.label }</option>))}                                    
                                </FormControl>
                            </FormGroup>
                        </Col>   
                    </Form>
                </Col>
            </Row>
        );
    }
}

FilterForm.propTypes = {
    columnsMgr: PropTypes.instanceOf(ColumnsManager),
};

export default FilterForm;