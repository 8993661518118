import React, { useState, useEffect } from 'react';
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';
import {
        Modal, Button, OverlayTrigger, Tooltip, Form,
        Alert, FormGroup, ControlLabel, Panel, Table, FormControl
    } from 'react-bootstrap';
import Datetime from 'react-datetime';
import { getWorkStatuses } from '../address/drive-status.js';
import DrivesList from '../tools/driveslist.js';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');


function AttachWorkButton(props) {

    const [show, setShow] = useState(false);
    const [date, setDate] = useState('');
    const [comment, setComment] = useState('');
    const [dateValid, setDateValid] = useState(null);
    const [kind, setKind] = useState('to3');
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false)
    const [drives, setDrives] = useState([]);

    useEffect(() => {        
        const getDrivesData = async () => {
            let dr = props.getDrives();
            setDrives(dr);
        };  
        show && getDrivesData();
     }, [show]);
    
    const isAllValid = () => {
        let key = 'success';
        return dateValid === key && drives.length;
    }

    const handleClose = () => {      
        setShow(false);
        setDate('');
        setDateValid(null);
        setKind('to3');
        setComment('');
    }
    
    const handleShow = evt => {
        evt.currentTarget.blur();
        setShow(true);
    }

    const getDateState = (value) => {
        let valstate = null;
        if(value === ''){
            valstate = null;
        } else if(/^\d\d\.\d\d\.\d\d\d\d$/.test(value)){
            valstate = 'success';
        } else {
            valstate = 'error';
        }
        return valstate;
    }

    const changeDateHandler = (date) => {
        var outdate = typeof date !== 'string'? date.add(12, 'hours').format('L') : '';
        if(outdate !== date){
            setDate(outdate);
            setDateValid( getDateState(outdate) );
        }
    }

    const kindHandler = evt => {
        setKind(evt.target.value);
    }

    const commentHandler = evt => {
        setComment(evt.target.value);
    }

    const sendRequest = (evt) => {
        let dt = moment(date, "L").format('YYYY-MM-DD');
        let data = {ids: drives.map(d => d.id), date: dt, kind: kind, comment: comment};
        
        setLoading(true);
        setErrors([]);

        getDataRequest(
            props.cookies, `/api/drives/addwork/`, '', 'POST', data
        ).then(
            resp => resp.json()   
        ).then(
            res => {  
                props.onAdd(data.ids);         
            }
        ).catch(error => {            
                console.log('error', error);
            }
        ).finally(
            () => {
                setLoading(false);
                handleClose();
            }
        );
    }

    const workStatuses = getWorkStatuses();

    return (
        <>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Добавить выполненные работы</Tooltip>}>
            <a href="#" className="btn btn-alt btn-sm btn-default" onClick={ handleShow }>
                <i className="gi gi-blacksmith"></i>
            </a>
        </OverlayTrigger>
        <Modal show={show} onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>Добавление выполненных работ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {errors.map((error, i) => (
                            <Alert key={i} bsStyle='danger' >{ error }</Alert>
                        )
                    )}
                    {!drives.length &&
                        <Alert bsStyle='danger' >Внимание, не выбрано ни одного привода!</Alert>
                    }
                    <FormGroup controlId="" validationState={ dateValid||null }>
                        <ControlLabel>Дата проведения работ</ControlLabel>
                        <Datetime 
                            locale="ru"
                            timeFormat={ false }
                            onChange={ changeDateHandler }
                            closeOnSelect={ true }
                            value={ date }
                        />                    
                    </FormGroup>

                    <FormGroup controlId="" validationState={ dateValid||null }>
                        <ControlLabel>Вид ТО</ControlLabel>
                        <FormControl componentClass="select" onChange={ kindHandler } value={ kind }>                                        
                            { workStatuses.map(c => (
                                        <option key={ c.value } value={ c.value }>
                                            { c.label }
                                        </option>
                                    )
                                )
                            }
                        </FormControl>                
                    </FormGroup>
                    <FormGroup controlId="" validationState={ dateValid||null }>
                        <ControlLabel>Комментарии</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            onChange={ commentHandler }
                            value={ comment }></FormControl>                
                    </FormGroup>
                    <DrivesList drives={ drives }/>                
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={ handleClose }>Отменить</Button>
                <Button bsStyle="primary" onClick={ sendRequest } disabled={ !isAllValid() || loading }>Добавить</Button>
            </Modal.Footer>
        </Modal>
        
        </>
    );
}
export default withCookies(AttachWorkButton);