import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, FormControl, Button } from 'react-bootstrap';


function ImportRow(props){

    const [data, setData] = useState(props.data);

    const changeFieldHandler = (evt, i) => {        
        let freshdata = [...data];
        freshdata[i]['value'] = evt.target.value;
        setData(freshdata);
        props.onChange(props.index, freshdata);
    }

    return (
        <tr>
            <td>{props.index + 1}</td>
            {data.map((item, i) => (
                <td key={i}>                
                    {item.field === 'text'?                  
                        <FormControl onChange={ (evt) => changeFieldHandler(evt, i) } value={item.value}/>                                                                  
                    :
                        <FormControl componentClass="select" onChange={ (evt) => changeFieldHandler(evt, i) } value={item.value}>
                            <option value="">------</option>
                            { props.brands.map(b => (
                                        <option key={ b.id } value={ b.name }>
                                            { b.name }
                                        </option>
                                    )
                                )
                            }
                        </FormControl>
                    }
                </td>
            ))}
        </tr>
    )
}

function ImportTable(props){

    const genEmptyRowData = () => {
        let row = Array(5).fill().map(i => ({field: 'text', value: ''}));
        row[4]['field'] = 'select';       
        return row;
    };
    const initialData = Array(props.initialRows || 5).fill().map(i => (genEmptyRowData()) );   
    const [tableStorage, setTableStorage] = useState(initialData);

    const getData = table => {
        table = table || tableStorage;
        return table.map(row => row.map(cell => cell.value));
    }

    const onChangeRow = (index, rowdata) => {
        let copy = [...tableStorage];
        copy[index] = rowdata;
        setTableStorage(copy);
        props.onDataChange(getData(copy));
    }

    const addRow = evt => {
        let copy = [...tableStorage];
        let row = genEmptyRowData();
        copy.push(row);
        setTableStorage(copy);    
        props.onDataChange(getData(copy));
    }

    return (
        <>
            <Table striped bordered={false} condensed>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Наименование / номер оборудования</th>
                        <th>Маркировка</th>
                        <th>Серийный номер</th>
                        <th>Описание (серия, мощность, артикул)</th>                        
                        <th>Бренд</th>                                            
                    </tr>                
                </thead>
                <tbody>
                    {tableStorage.map((item, i) => (<ImportRow key={i} index={i} data={item} onChange={onChangeRow} brands={props.brands} />))}
                </tbody>
            </Table>
            <Button className="pull-right" onClick={addRow}><i className="fa fa-plus"></i> строка</Button>
        </>
    )
}

ImportTable.propTypes = {
    initialRows: PropTypes.number,
    withDate: PropTypes.bool,
    onChange: PropTypes.func,
};

export default ImportTable;