import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Radio from 'react-bootstrap/lib/Radio';
import Datetime from 'react-datetime';
import { Comparator } from 'react-bootstrap-table2-filter';
import ColumnsManager from './list-columns';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');


class FilterForm extends Component{
    
    state = {
        dateComparatorValue: '>=',
        dateComparator: Comparator.GE,
        date: ''
    }

    changeBrandHandler = evt => {
        this.props.columnsMgr.getFilters()['brand_name'](evt.target.value);
    }

    changeBadgesHandler = evt => {
        this.props.columnsMgr.getFilters()['badges'](evt.target.value);
    }
    
    changeDateHandler = date => {
        var outdate = typeof date !== 'string'? date.add(12, 'hours') : '';
        if(outdate !== this.state.date){
            this.props.columnsMgr.getFilters()['updated'](
                {   
                    comparator: this.state.dateComparator,
                    date: (outdate?  outdate.toDate(): '')
                }
            );
            this.setState({ date: outdate });
        }          
    };

    changeComparatorHandler = evt => {
        let val = evt.target.value;
        if( val == "<" ){
            var dateComparator = Comparator.LT;
            var dateComparatorValue = '<';            
        } else {
            var dateComparator = Comparator.GE;
            var dateComparatorValue = '>=';
        }
        this.setState({ dateComparatorValue, dateComparator }); 
        let date = this.state.date? this.state.date.toDate(): '';
        this.props.columnsMgr.getFilters()['updated'](
            {   
                comparator: dateComparator,
                date: date
            }
        );
    }

    render(){
        return (
            <Row style={ {marginBottom: '2rem'} }>
                <Col md={12}>
                    <Form>
                        <h3>Фильтрация</h3>
                        <Col xs={6} md={2}>
                            <FormGroup>
                                <ControlLabel>Бренд</ControlLabel>
                                <FormControl componentClass="select" onChange={ this.changeBrandHandler }>
                                    <option value="">Все</option>
                                    { this.props.brands.map(b => (<option key={ b.id } value={ b.id }>{ b.name }</option>)) }
                                </FormControl>
                            </FormGroup>
                        </Col>
                        <Col xs={6} md={2}>
                            <FormGroup>
                                <ControlLabel>Статус</ControlLabel>
                                <FormControl componentClass="select" onChange={ this.changeBadgesHandler }>
                                    <option value="">без разницы</option>
                                    { this.props.badges.map(b => (<option key={ b.value } value={ b.value }>{ b.label }</option>)) }
                                </FormControl>
                            </FormGroup>
                        </Col>
                        <Col xs={6} md={4}>
                            <FormGroup>
                                <Col sm={12}>
                                    <ControlLabel>Дата обновления</ControlLabel>                            
                                </Col>
                                <Col sm={4}>
                                    <div>
                                    <Radio 
                                        inline
                                        id="comparator_ge"
                                        value=">="
                                        name="updated_comparator"
                                        checked={ this.state.dateComparatorValue === '>=' }
                                        onChange={ this.changeComparatorHandler }>
                                        От даты
                                    </Radio>
                                    </div>
                                    <Radio
                                        inline
                                        id="comparator_lt"
                                        value="<"                                        
                                        name="updated_comparator"
                                        checked={ this.state.dateComparatorValue === '<' }
                                        onChange={ this.changeComparatorHandler }>
                                        До даты
                                    </Radio>

                                </Col>                            
                                <Col sm={8}>
                                    <Datetime 
                                        locale="ru"
                                        timeFormat={ false }
                                        onChange={ this.changeDateHandler }
                                        closeOnSelect={ true }
                                    />
                                </Col>
                                
                            </FormGroup>
                        </Col>

                    </Form>
                </Col>
            </Row>
        );
    }
}

FilterForm.propTypes = {
    columnsMgr: PropTypes.instanceOf(ColumnsManager),
    brands: PropTypes.array.isRequired,
    badges: PropTypes.array.isRequired,
};

export default FilterForm;