import React from "react";


function Breadcrumbs(props){

    const crumbs = [(<i className="fa fa-home"></i>), ...props.crumbs]
    return(
        <ul className="breadcrumb breadcrumb-top">
            {crumbs.map((item, index) =>{
                return (<li key={index}>{item}</li>);
            })}
        </ul>
    );
}
export default Breadcrumbs;