import React from 'react';
import { withCookies } from 'react-cookie';
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';
import { Row, Col } from 'react-bootstrap';
import getDataRequest from '../tools/datarequest';
import EditableAvatar from "./avatar";


function Profile(props){

    const user = props.user;

    const handleCropped = (id, data) => {
        getDataRequest(
            props.cookies, '/api/users/myself/', '', 'PATCH', data
        ).then(
            resp => resp.json()
        ).then(
            res => props.onChangeProfile(res)
        ).catch(error => console.log('error', error));
    }

    return (
        <>
        <PageHeader title={"Профиль" + (user? ` (${ user.username })`: '')} subtitle="" icon="gi gi-user"/>
        <Breadcrumbs crumbs={['Профиль пользователя']}/>
        <Row>
            <Col xs={12} md={6} mdOffset={3}>
                <div className="block full">            
                    <div className="block-title">
                        <h2>Профиль пользователя</h2>
                    </div>
                    <Row>
                        <Col xs={3} md={4} lg={3}>
                            <EditableAvatar img={ user.photo_thumbnail } id={ user.id } onChange={ handleCropped }/>
                        </Col>
                        <Col xs={9} md={8} lg={9}>
                            {user?                    
                                <table className="table table-borderless table-striped">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '20%'}}><strong>Логин</strong></td>
                                            <td>{ user.username }</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Имя</strong></td>
                                            <td>{ user.first_name || "---"}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Фамилия</strong></td>
                                            <td>{ user.last_name || "---"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                :''}
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
        </>
    )
    
}

export default withCookies(Profile);