import React from 'react';
import { selectFilter } from 'react-bootstrap-table2-filter';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');

export default class ColumnsManager{
    
    filters = {}
    default_pm = '';

    getFilters = () => {
        return this.filters;
    }

    cleanFilters = () => {
        var filters = this.getFilters();
        for(var f in filters){
            if(filters.hasOwnProperty(f)){
                if(f !== 'material_type'){
                    filters[f]('');
                } else {
                    filters[f](this.default_pm);
                }                
            }
        }
    }

    getPmOptions = () => {
        return [
            {value: 'PM1', label: 'PM1'},
            {value: 'PM2', label: 'PM2'},
            {value: 'PM3', label: 'PM3'},
            {value: 'PM6', label: 'PM6'},
            {value: 'PM9', label: 'PM9'},
            {value: 'PM12', label: 'PM12'},
            {value: 'PM15', label: 'PM15'},
            {value: 'PM18', label: 'PM18'},
            {value: 'PM24', label: 'PM24'},
        ];
    }
    getColumns = (pm) => { 
        this.default_pm = ( pm ? pm : '' );
        var that = this;
        return [
            {   dataField: 'material_type',
                text: 'Тип ТО',
                editable: false,
                sort: true,
                filter: selectFilter({
                    options: this.getPmOptions(),
                    defaultValue: this.default_pm,
                    placeholder: 'Вce',
                    getFilter: (filter) => {
                        that.filters['material_type'] = filter;
                    }
                }),
            },

            {   dataField: 'code',
                text: 'Код',
                editable: false,
                sort: true,
                formatter: (cell, row) => {
                    if(!row.spare_part){
                        return (
                            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Нет в прайсе</Tooltip>}>
                                <span style={{color: 'red'}} >{cell}</span>
                            </OverlayTrigger>
                        )
                    }
                    return  (<span>{cell}</span>);
                }
            },

            {   dataField: 'kit_code',
                text: 'Код набора',
                editable: false,
                sort: false,
            },
            
            {   dataField: 'quantity',
                text: 'Кол-во',
                editable: false,
                sort: true,
            },

            {   dataField: 'unit',
                text: 'Ед.',
                editable: false,
            },
           
            
            {   dataField: 'description',
                text: 'Описание',
                searchable: true,
                editable: false,
                sort: true,                
            },

            {   dataField: 'type_designation',
                text: 'Обозначение',                
                searchable: true,
                editable: false,
            }, 

            {   dataField: 'technical_description',
                text: 'Техническое описание',                
                searchable: true,
                editable: false,
            },   
            
        ];
    }
}