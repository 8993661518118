import React, { useState } from 'react';
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';


const AddressSelect = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    
    const handleSearch = query => {
        setIsLoading(true);
        
        getDataRequest(
            props.cookies, '/api/addresses/', `?search=${query}&limit=100&offset=0`
        ).then(
            resp => resp.json()
        ).then(
            res => {
                setOptions(res['results']);
                setIsLoading(false);
            }
        ).catch(error => console.log('error', error));
    };

    const filterCallback = (option, props) => (true);

    return (
          <AsyncTypeahead
            className="rbt-input--select"
            filterBy={filterCallback}
            id="addsress-search"
            isLoading={isLoading}
            labelKey="name"
            minLength={0}
            onSearch={handleSearch}
            onFocus={ evt => handleSearch(evt.target.value)}
            options={options}
            placeholder="Начните вводить название клиента"
            searchText="Поиск..."
            emptyLabel="Нет совпадений"
            renderMenuItemChildren={(option, props) => (                           
                <div key={option.id}>{option.name} <br/><span>({option.client_name})</span></div>              
            )}
            {...props}
          />
    );
};


export default withCookies(AddressSelect);
