import React from 'react';
import { NavLink } from "react-router-dom";
import { selectFilter } from 'react-bootstrap-table2-filter';
import { Type } from 'react-bootstrap-table2-editor';
import { Label, OverlayTrigger, Tooltip } from 'react-bootstrap';
import EditableCell from '../table/editable-cell';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');

export default class ColumnsManager{
    
    filters = {}

    getFilters = () => {
        return this.filters;
    }

    cleanFilters = () => {
        var filters = this.getFilters();
        for(var f in filters){
            if(filters.hasOwnProperty(f)){
                filters[f]('');
            }
        }
    }

    getColumns = (is_senior_manager, managers) => {    
        const selectManagerOptions = managers.map(m => ({value: m.id, label: m.username}));
        const managersMap = managers.reduce((obj, m) => {
            obj[m.id] = m.username;
            return obj
        }, {});
        
        const minDate = moment().subtract(1, 'years').startOf('month');        
        const dangerEnd = moment().add(6, 'months').endOf('months');
        const warningEnd = moment().add(1, 'years').endOf('month');
        const maxDate = warningEnd;
        
        let columns = [
            {   dataField: 'client_name',
                text: 'Клиент',
                editable: false,
                sort: true
            },
            {   dataField: 'name',
                text: 'Адрес',
                editable: false,
                sort: false,
                formatter: (cell, row) => (<NavLink to={`/clients/${row.id}`}>{cell}</NavLink>)
            },
            {   dataField: 'comments_count',
                text: 'Коментарии',
                headerFormatter: () => (<i className="fa fa-comment"></i>),
                formatter: (cell, row) => {
                    if(cell == 0){
                        return '';
                    }
                    return (
                        <NavLink 
                            className="badge label-info comment_count"
                            to={`/clients/${row.id}/#comments_block`}>
                            {cell}
                        </NavLink>
                    )
                },
                searchable: false,
                editable: false,
            },
            {                
                dataField: 'ds',
                isDummyField: true,
                text: 'Приводы',
                editable: false,
                formatter: (cellContent, row) => {
                    return (
                            <div style={ {minWidth: '190px'} }>
                                                     
                                <OverlayTrigger placement='top' overlay={<Tooltip id={`drives${row.id}`}>Всего</Tooltip>}>
                                    <span className="badge label-primary label-gap">{ row.drives_count }</span>
                                </OverlayTrigger>
                                                 
                                <OverlayTrigger placement='top' overlay={<Tooltip id={`waiting${row.id}`}>Ожидают подбора</Tooltip>}>
                                    <span className="badge label-warning label-gap">{ row.waiting_count }</span>
                                </OverlayTrigger>
                                                     
                                <OverlayTrigger placement='top' overlay={<Tooltip id={`end${row.id}`}>Подобрано</Tooltip>}>
                                    <span className="badge label-success label-gap">{ row.end_count }</span>
                                </OverlayTrigger>
                            
                                <OverlayTrigger placement='top' overlay={<Tooltip id={`nowork${row.id}`}>Работы не предусмотрены</Tooltip>}>
                                    <span className="badge label-gap">{ row.nowork_count }</span>                                
                                </OverlayTrigger>
                            
                                <OverlayTrigger placement='top' overlay={<Tooltip id={`errors${row.id}`}>С ошибками</Tooltip>}>                                    
                                    {row.error_count ? 
                                        <span className="badge label-danger label-gap">{ row.error_count }</span>
                                    :
                                        <span className="badge label-gap">{ row.error_count }</span>
                                    }                                    
                                </OverlayTrigger>
                                           
                            </div>
                    )
                    
                }
                  
            },
            {   dataField: 'to_plans',
                text: 'Плановые работы',
                editable: false,
                sort: false,                
                formatter: (cell, row) => {                    
                    if(cell.length){
                        let items = cell.filter(it => {
                            let date = moment(it);                            
                            return minDate <= date && date <= maxDate;
                        });

                        return items.reduce((out, it, i) => {
                            let date = moment(it);
                            let mod = 'default';
                            if(date <= dangerEnd){
                                mod = 'danger';
                            } else if(date <= warningEnd){
                                mod = 'warning';
                            }
                            return (
                                <React.Fragment key={`${row.id}${i}`}>
                                    {out}
                                    <Label bsStyle={mod}>
                                        { moment(it).format('MMM YYYY') }
                                    </Label>
                                </React.Fragment>
                                );
                        }, '');                        
                    }                    
                    return '';
                }
            },                      
        ];
        if(is_senior_manager){
            var that = this;
            columns.push(
                {   dataField: 'responsible',
                    text: 'Ответственный',
                    editable: true,
                    sort: false,
                    formatter: (col, row) => {
                        let cell;
                        if(managersMap.hasOwnProperty(col)){
                            cell = managersMap[col];
                        } else {
                            cell = row['responsible_name'];
                        }
                        return (<EditableCell cell={cell}/>);      
                    },
                    editor: {
                        type: Type.SELECT,
                        options: selectManagerOptions
                    },
                    filter: selectFilter({
                        options: selectManagerOptions,
                        placeholder: 'Вce',
                        getFilter: (filter) => {
                            that.filters['responsible'] = filter;
                        }
                    }),   
                },
            )
        }
        return columns;
    }
}