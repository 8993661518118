import React from 'react';
import { selectFilter } from 'react-bootstrap-table2-filter';
import { NavLink } from 'react-router-dom';
import { Label, OverlayTrigger, Tooltip } from 'react-bootstrap';
import getPlanCellFormatter from '../table/chrpplan-cell';
import getWorkCellFormatter from '../table/chrpwork-cell';
import genBagesFormatter from '../table/badges-formatter-generator';
import { getDibStatuses, getDriveBadges } from '../address/drive-status.js';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');

export default class ColumnsManager{
    
    filters = {}

    getFilters = () => {
        return this.filters;
    }

    cleanFilters = () => {
        var filters = this.getFilters();
        for(var f in filters){
            if(filters.hasOwnProperty(f)){
                filters[f]('');
            }
        }
    }

    getColumns = (brands, statuses) => {    
        const selectBrandOptions = brands.map(b => ({value: b.id, label: b.name}));
        const stmap = {};
        statuses.map(s => { if(s.value){
            stmap[s.value] = s.label;
        }})
        
        const badges_formatter = genBagesFormatter(getDriveBadges());

        const sources_map = getDibStatuses().reduce((acc, item) => {
            if(item['value'] !== 'we'){
                acc[item['value']] = item['label'];
            }
            return acc;
        }, {});

        var that = this;
        let columns = [
            {   dataField: 'client_name',
                text: 'Клиент',
                editable: false,
                sort: true
            },
            {   dataField: 'obj_name',
                text: 'Наименование / номер оборудования',
                editable: false,
                sort: true
            },
            {   dataField: 'name',
                text: 'Маркировка',
                editable: false,
                formatter: (cell, row) => <NavLink to={`/chrp/${row.id}`} className="longlink" target="_blank">{cell.replace(/\+/ig, " +")}</NavLink>,
                sort: true
            },
            {   dataField: 'serial_number',
                text: 'Серийный номер',
                editable: false,
                sort: true
            },
            {   dataField: 'brand_name',
                text: 'Бренд',
                filter: selectFilter({
                    options: selectBrandOptions,
                    placeholder: 'Вce',
                    getFilter: (filter) => {
                        that.filters['brand_name'] = filter;
                    }
                }),              
                searchable: false,
                editable: false,
            },
            {   dataField: 'date_of_manufacture',
                text: 'Произведен',
                editable: false,
                sort: true,
                formatter: (cell, row) => {
                    if(cell){
                        return moment(cell).format('L');
                    }
                    return '---'
                }
            },
            {   dataField: 'dib_date',
                text: 'Дата в DIB',
                editable: false,
                sort: true,
                formatter: (cell, row) => {                    
                    if(row['dib_source'] in sources_map){
                        return sources_map[row['dib_source']];
                    }
                    if(cell){
                        return moment(cell).format('L');
                    }
                    return '---'
                }
            },
            {   dataField: 'description',
                text: 'Описание (серия, мощность, артикул)',
                editable: false,
                sort: false
            },
            {   dataField: 'state',
                text: 'Статус',
                editable: false,
                sort: false,
                filter: selectFilter({
                    options: statuses,
                    placeholder: 'Вce',
                    getFilter: (filter) => {
                        that.filters['state'] = filter;
                    }
                }),
                formatter: (cell, row) => {
                    let out;
                    if(cell === 'waiting_chose'){
                        out = (
                            <>
                                <Label bsStyle="warning">{ stmap[cell] }</Label>
                                <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Подобрать</Tooltip>}>
                                    <NavLink to={`/chrp/${row.id}`} className="btn btn-sm btn-warning btn-round" target="_blank">
                                        <i className="fa fa-level-up"></i>
                                    </NavLink>
                                </OverlayTrigger>
                            </>
                        );
                    } else {
                        out = stmap.hasOwnProperty(cell) ? stmap[cell] : '------';
                    }  

                    if(row['badges']){
                        out = ( <>{out} { badges_formatter(row['badges'], row) } </>);
                    }
                    return out;                  
                }
            },
            {   dataField: 'last_work_date',
                text: 'Последние работы',
                editable: false,
                sort: false,                
                formatter: getWorkCellFormatter()
            },
            {   dataField: 'to_plans',
                text: 'Плановые работы',
                editable: false,
                sort: false,                
                formatter: getPlanCellFormatter()
            },        
        ];        
        return columns;
    }
}