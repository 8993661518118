import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import avatar from '../../img/placeholders/avatars/avatar.jpg';
import moment from 'moment';
import 'moment/locale/ru';
import { Button } from 'react-bootstrap'


export default function CommentPageBlock(props){
    const [offset, setOffset] = useState(0);    
    const [loading, setLoading] = useState(false);
    const step = 5;

    const nextButtonHandler = evt => {
        setLoading(true);
        setTimeout(() => {
            setOffset(offset + step);
            setLoading(false);
        }, 800);
    }

    const replacer = text => (text.replace(/\r\n/gi, '<br/>'));
    
    return (
        <div>
            {!props.items || !props.items.length?
                <h2 className="text-center"><i className="fa fa-comment-o text-info"></i> Здесь пока пусто</h2>
            :
                <table className="table table-borderless table-striped">
                    <tbody>                    
                        {props.items.slice(-(offset + step)).reverse().map( (item, i) => (
                                    <React.Fragment key={i}>                           
                                        <tr>
                                            <td className="text-center"><strong>
                                                {item.user? 
                                                    (<a href={'mailto:' + item.user.username}>{item.user.username}</a>)
                                                : 
                                                    'Неизвестный'}
                                                </strong>
                                            </td>
                                            <td>{ moment(item.created).format('L') }</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center" style={{width: '12%'}}>
                                                <div className="push-bit">                                                    
                                                    {item.user && item.user.photo_icon?
                                                        <img src={ `${process.env.REACT_APP_API_URL}${item.user.photo_icon}` } alt="avatar" className="img-circle"/>
                                                    :
                                                        <img src={ avatar } alt="avatar" className="img-circle"/>
                                                    }
                                                </div>                                    
                                            </td>
                                            <td>
                                                <div>{ ReactHtmlParser(replacer(item.text)) }</div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                            )
                        }                                             
                    </tbody>
                </table>                                
            }
            {props.items.length > offset &&
                <div className="text-center">
                    <Button 
                        bsStyle="primary"
                        onClick={ nextButtonHandler }
                        disabled={ loading }>
                        <i className={'fa fa-refresh ' + (loading? 'fa-spin': '')}></i> Смотреть еще
                    </Button>
                </div>
            }
        </div>        
    );
}
