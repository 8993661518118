import React from 'react';

export default function Page404(props){
    return (
        <div id="error-container">            
            <div className="row">
                <div className="col-sm-8 col-sm-offset-2 text-center">
                    <h1 className="animation-pulse"><i className="fa fa-exclamation-circle text-warning"></i> 404</h1>
                    <h2 className="h3">Такой страницы не существует.</h2>
                </div>                
            </div>
        </div>
    )
}
