import React, { useState, useEffect } from 'react';
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

const ManagerSelect = (props) => {
    const [managers, setManagers] = useState([]);

    useEffect(() => {
        if(!managers.length){
            managersRequest();
        }
    });

    const managersRequest = () => {
        getDataRequest(
            props.cookies, '/api/users/', '?groups__name__icontains=manager&is_active=true'
        ).then(
            resp => resp.json()
        ).then(
            res => setManagers(res['results'])
        ).catch(error => console.log('error', error));        
    }

    return (
        <FormControl componentClass="select" disabled={ props.disabled } onChange={ props.onChange } value={props.value}>                                        
            <option value="">------</option>
            { managers.map(b => (
                        <option key={ b.id } value={ b.username }>{ b.username }</option>
                    )
                )
            }
        </FormControl>        
    );
};

ManagerSelect.propTypes = {
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.string
};

export default withCookies(ManagerSelect);