import React from 'react';

export default function AccordeonCell(props){

    let pathparts = window.location.pathname.split('/');
    
    let open = 'open';
    if(!(pathparts.length > 1 && pathparts[1] === props.name)){
        open = props.open? 'open' : '';
    }

    return(
        <a href="#" className={'sidebar-nav-menu ' + open} onClick={ props.onClick }>
            <i className="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
            <i className={"sidebar-nav-icon " + ( props.icon ? props.icon : "gi gi-cogwheel")}></i>
            <span className="sidebar-nav-mini-hide">{ props.text }</span>
        </a>
    );
}