export default function initUpdateWatcher(cookies, callback = () => {}){
    //watch drives update signals from server
    const socket = new WebSocket(
        `${process.env.REACT_APP_WS_URL}/drive/renewal/?token=${cookies.get('token')}`
    );

    var resp_list = [];
    var timer = '';
    socket.onmessage = function(e) {
        const data = JSON.parse(e.data);
        resp_list.push(data.responsible);
        if(timer === ''){
            timer = setTimeout(() => {
                callback(resp_list);
                timer = '';
                resp_list = [];
            }, 200);
        }
    };

    socket.onclose = e => {
        console.error('Drive update watcher socket closed unexpectedly');
    };

    socket.sendSignal = value => {
        socket.send(JSON.stringify({'responsible': value}));
    };

    return socket;
}