
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { 
        Alert, Button, InputGroup,
        FormGroup, HelpBlock, Form, Row, Col, Grid
    } from 'react-bootstrap';
import { getPasswordState, getConfirmPasswordState } from '../user/passvalidator';

class PasswordResetConfirm extends Component {

    state = {
        password: '',
        passwordStatus: null,
        passwordConfirm: '',
        passwordConfirmStatus: null,
        inputError: false,
        tokenValid: false, 
        token: '',
        loading: true,
        sent: false
    }

    componentDidMount(){
        this.checkToken();
    }

    passwordChanged = evt => {
        this.setState({
            password: evt.target.value,
            passwordStatus: getPasswordState(evt.target.value) 
        })
    }

    passwordConfirmChanged = evt => {
        this.setState({
            passwordConfirm: evt.target.value,
            passwordConfirmStatus: getConfirmPasswordState(evt.target.value, this.state.password) 
        })
    }

    inputChanged = evt => {
        let credentials = this.state.credentials;
        credentials[evt.target.name] = evt.target.value;
        this.setState({ credentials: credentials });
    }

    checkToken = () => {
        
        const token = this.props.match.params.token;
        fetch(`${process.env.REACT_APP_API_URL}/api/password_reset/validate_token/`, {
            method: 'POST',
            headers: {'Content-type': 'application/JSON'},
            body: JSON.stringify({token: token})
        }).then(resp => {
            this.setState({loading: false});
            if( resp.status == 200 ){
                this.setState({tokenValid: true, token: token})
                return resp.json();
            } else {
                throw 'Invalid token';
            }
        }).catch(() => this.setState({tokenValid: false}));        
    }

    send = evt => {   
        evt.preventDefault();
        this.setState({inputError: false});
        fetch(`${process.env.REACT_APP_API_URL}/api/password_reset/confirm/`, {
            method: 'POST',
            headers: {'Content-type': 'application/JSON'},
            body: JSON.stringify({ password: this.state.password, token: this.state.token })
        }).then(resp => {
            if( resp.status == 200 ){
                return resp.json();
            } else {
                throw 'oyoyoyoy';
            }
        }).then(res => {    
            this.setState({sent: true});
        })
        .catch(() => this.setState({inputError: true}));
    }

    isAllValid = () => {
        let key = 'success';
        return this.state.passwordStatus == key && this.state.passwordConfirmStatus == key;
    }

    render(){
        return (            
            <Grid>
                <Row>            
                    <Col>
                        <div id="login-container">
                            <div className="login-title text-center">
                                <h1><strong>Ввод нового пароля</strong></h1>
                            </div>
                            
                            <div className="block push-bit">
                                {this.state.loading ?
                                    <p className="text-center"><i className="fa fa-spinner fa-4x fa-spin"></i></p> :
                                    
                                    <>
                                    {this.state.inputError &&                                        
                                        <Alert bsStyle="danger text-center">                                            
                                            <p>Не удалось сменить пароль. Что-то пошло не так</p>                                            
                                        </Alert>
                                    }
                                    {!this.state.tokenValid &&
                                        <Alert bsStyle="danger">                                            
                                            <p>Ваша ссылка устарела</p>                                            
                                        </Alert>
                                    }
                                    <Form bsStyle="horizontal" onSubmit={ this.send } className="form-horizontal form-bordered">
                                    
                                    
                                    
                                    {!this.state.sent && this.state.tokenValid &&
                                        <>
                                            <FormGroup validationState={ this.state.passwordStatus }>
                                                <Col xs={12}>
                                                    <InputGroup>
                                                        <span className="input-group-addon"><i className="gi gi-asterisk"></i></span>
                                                        <input 
                                                            type="password" id="password"
                                                            name="password" className="form-control input-lg"
                                                            value={ this.state.password } placeholder="Новый пароль"
                                                            autoComplete="new-password"
                                                            onChange={this.passwordChanged}/>                                                        
                                                    </InputGroup>
                                                    <HelpBlock>
                                                            Пароль должен быть длиной не менее 9 символов,
                                                            содержать минимум одну цифру и символы разного регистра
                                                    </HelpBlock>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup validationState={ this.state.passwordConfirmStatus }>
                                                <Col xs={12}>
                                                    <InputGroup>
                                                        <span className="input-group-addon"><i className="gi gi-asterisk"></i></span>
                                                        <input 
                                                            type="password" id="password_confirm"
                                                            name="password" className="form-control input-lg"
                                                            value={ this.state.passwordConfirm } placeholder="Повторите пароль"
                                                            autoComplete="new-password"
                                                            onChange={this.passwordConfirmChanged}/>                                                        
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            
                                            <FormGroup className="form-actions">
                                                <Col xs={12} className="text-right">
                                                    <Button bsStyle="primary" bsSize="sm" type="submit" disabled={ !this.isAllValid() }>
                                                        <i className="fa fa-angle-right"></i> Сбросить пароль
                                                    </Button>
                                                </Col>                        
                                            </FormGroup>
                                        </>                                                                    
                                    }
                                    {this.state.sent && <FormGroup className="form-actions">
                                            <Col xs={12} className="text-center">
                                                <p style={{fontSize: '14px'}}>Поздравляю, вы успешно сменили пароль</p>
                                            </Col>
                                        </FormGroup>
                                    }

                                        <FormGroup>
                                            <Col xs={12} className="text-center">
                                                <NavLink to="/login">
                                                    <small>Вернуться на страницу входа</small>
                                                </NavLink>
                                            </Col>
                                        </FormGroup>
                                    
                                </Form>
                                </>
                                }           
                                                
                            </div>            
                        </div>
                    </Col>
                </Row>
            </Grid>    
        );
    }
}
export default withCookies(PasswordResetConfirm);