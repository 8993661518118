import React, { useState, useEffect } from 'react';
import { withCookies } from 'react-cookie';
import { NavLink } from 'react-router-dom';
import { 
    Row, Col, Button, Table, Label,
    FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import getDataRequest from '../tools/datarequest';
import { getDriveStatuses } from '../address/drive-status';
import TOPartsList from './detail-toparts';
import DateField from './detail-date';
import { getWorkStatuses } from '../address/drive-status';
import moment from 'moment';
import 'moment/locale/ru';

const DriveInfo = props => {

    const [filteredTo, setFilteredTo ] = useState([]);
    const [excludetTo, setExcludetTo ] = useState([]);
    const [currentPlan, setCurrentPlan ] = useState(props.toFilter || '');
    const [loadWorkCleaner, setLoadWorkCleaner] = useState(false);
    const [isWorkCleaned, setIsWorkCleaned] = useState(false);

    useEffect(() => {
        handlePlanFilter(currentPlan);
    }, [props.drive]);

    const statuses = getDriveStatuses().reduce((obj, item) => {
        obj[item.value] = item.label;
        return obj
    }, {});

    const handlePlanFilter = (value, dr=props.drive) => {
        setCurrentPlan(value);
        let actualPlan = dr.to_plans.reduce((init, p) => {
                if(!init && p.work_kind === value){
                    return p;
                }
                return init;
            }
        , '');

        let pms = actualPlan? actualPlan.material_types : [];
        setFilteredTo(dr.to_parts.filter(
                tp => !pms.length || !!(pms.indexOf(tp.material_type) + 1)
            )
        )

        pms = actualPlan? actualPlan.exclude_material_types : [];
        setExcludetTo(dr.to_parts.filter(
                tp => {
                    if(!pms.length){
                        return false;
                    } else{
                        return !!(pms.indexOf(tp.material_type) + 1)
                    }
                }
            )
        )
    }

    const work_kinds = getWorkStatuses().reduce((obj, it) => {
        obj[it.value] = it.label;
        return obj;
    }, {});

    const prepare_shchedule = plans => {
        let pl = plans.filter(it => it.work_kind.slice(0, 2) == 'to');
        pl.sort((a, b) => (parseInt(a.work_kind.slice(2)) - parseInt(b.work_kind.slice(2))));
        return pl;
    }

    const sorter = (a, b) => { return parseInt(a.slice(2)) - parseInt(b.slice(2)); };

    const to_kinds = props.drive.to_plans.filter(
            it => { return it.work_kind.slice(0, 2) === 'to';}
        ).map(it => it.work_kind).sort(sorter);
    
    const cleanWorks = evt => {
        let data = {ids: [props.drive.id]};
        
        setLoadWorkCleaner(true);

        getDataRequest(
            props.cookies, `/api/drives/cleanwork/`, '', 'POST', data
        ).then(
            resp => resp.json()   
        ).then(
            res => { setIsWorkCleaned(true) }
        ).catch(error => console.log('error', error)
        ).finally(
            () => {
                setLoadWorkCleaner(false);
            }
        );        
    }

    return(
        <>
            <Row>
                <Col md={12}>
                    <p className="">
                        Клиент: {props.drive.client_name}
                    </p>
                </Col>
                <Col md={12}>
                    <p className="">
                        Адрес: {props.drive.address_name}
                    </p>
                </Col>
                <Col md={12}>
                    <p className="lead">
                        Состояние: {props.drive.state ? statuses[props.drive.state] : '...'}
                    </p>
                </Col>
                
                {props.showFullData &&
                    <>
                        <DateField 
                            value={ props.drive.date_of_manufacture }
                            label='Дата производства:'                            
                            editable={ false }
                        />
                        <DateField 
                            value={ props.drive.dib_date }
                            label='Дата регистрации в DIB:'                            
                            editable={ false }
                        />
                    {props.drive.last_work_date && !isWorkCleaned &&
                        <Col md={12}>
                            <h3>Последние выполненные работы 
                                {(props.user.is_engineer || props.user.is_senior_engineer || props.user.is_admin) &&
                                    <Button onClick={ cleanWorks } style={ {marginLeft: '10px'} }>
                                        {loadWorkCleaner?
                                            <i className="fa fa-spinner fa-spin"></i>
                                        :
                                            <i className="gi gi-remove_2"></i>
                                        } сбросить
                                    </Button>
                                }
                            </h3>
                            <p>Дата: {moment(props.drive.last_work_date).format('L')}</p>
                            <p>Вид работ: { work_kinds[props.drive.last_work_kind] }</p>                                                     
                        </Col>
                    }
                    
                    {props.drive.planned_retro_modern &&
                        <Col md={6}>
                            <h3>Запланирована модернизация/ретрофит</h3>
                            <p>Дата: {moment(props.plans.retro_modern.date).format('L')}</p>
                            <p>Комментарий: <br/>{ props.plans.retro_modern.description }</p>                            
                        </Col>
                    }
                    {props.drive.planned_replacement &&
                        <Col md={6}>
                            <h3>Запланирована замена</h3>
                            <p>Дата: {moment(props.plans.replacement.date).format('L')}</p>
                            <p>Комментарий: <br/>{ props.plans.replacement.description }</p>                                                     
                        </Col>
                    }
                    {props.drive.has_to &&                        
                        <Col md={12}>
                            
                            {props.drive.to_plans && !!props.drive.to_plans.length &&
                                <>                                
                                <h3>План ТО</h3>
                                <Row>
                                    <Col md={12}>
                                        <Table striped bordered condensed>
                                            <tbody>
                                                <tr>
                                                    <th>Вид ТО</th>
                                                    {prepare_shchedule(props.drive.to_plans).map((plan, ind) => {
                                                            return (<td key={ind}>{ work_kinds[plan.work_kind] }</td>);
                                                        }
                                                    )}
                                                </tr>
                                                <tr>
                                                    <th>Дата</th>
                                                    {prepare_shchedule(props.drive.to_plans).map((plan, ind) => {
                                                            return (<td key={ind}>{ moment(plan.date).format('MMM YYYY') }</td>);
                                                        }
                                                    )}
                                                </tr>
                                                <tr>
                                                    <th>Комплекты</th>
                                                    {prepare_shchedule(props.drive.to_plans).map((plan, ind) => {
                                                            return (
                                                                <td key={ind}>
                                                                    { typeof plan.material_types == 'string'? 
                                                                        plan.material_types
                                                                    : 
                                                                        plan.material_types.sort(sorter).join(', ') }
                                                                    
                                                                    {plan.exclude_material_types && plan.exclude_material_types.length > 0 &&
                                                                        <React.Fragment>
                                                                            , 
                                                                            <span style={{color: 'red'}}>
                                                                                { typeof plan.exclude_material_types == 'string'? 
                                                                                    ' -' + plan.exclude_material_types
                                                                                : 
                                                                                    plan.exclude_material_types.sort(sorter).join(', -')
                                                                                }
                                                                            
                                                                            </span>
                                                                        </React.Fragment>
                                                                    }
                                                                </td>
                                                            );
                                                        }
                                                    )}
                                                    
                                                </tr>
                                            </tbody>
                                                
                                        </Table>
                                    </Col>                    
                                </Row> 
                                </>               
                            }
                            <h3>Подбор</h3>
                            <p>
                            Пакет ТО: <NavLink to={ `/packages/${props.drive.mp_package}` } target="_blank">{ props.drive.mp_package_name }</NavLink>
                            </p>
                            <p>
                            {(props.drive.badges.indexOf('info_obsolete_parts') > -1) &&
                                <Label bsStyle="primary" style={{marginRight: '5px'}}>outdated</Label>
                            }
                            {(props.drive.badges.indexOf('info_mark_mismatch') > -1) &&
                                <Label bsStyle="primary" style={{marginRight: '5px'}}>Несовпадение маркировок</Label>
                            }
                            </p>
                            {props.drive.to_plans && !!props.drive.to_plans.length &&
                                <Row>
                                    <Col md={3}>
                                        <FormGroup controlId="formControlsSelect">
                                            <ControlLabel>Вид ТО</ControlLabel>
                                            <FormControl 
                                                componentClass="select"
                                                placeholder="select"
                                                value={ currentPlan }
                                                onChange={ evt => handlePlanFilter(evt.target.value) }
                                            >
                                                <option value="">Все ТО</option>
                                                {to_kinds.map((kind, ind) => {
                                                        return (<option key={ind} value={ kind }>{ work_kinds[kind] }</option>)
                                                    }
                                                )}
                                            </FormControl>
                                        </FormGroup>
                                    </Col>                    
                                </Row>                
                            }
                            <TOPartsList
                                parts={ filteredTo }          
                                user={ props.user }
                                canSave={ false }                             
                            />
                            {excludetTo.length > 0 &&
                                <>
                                    <h4>Исключения из подбора</h4>
                                    <TOPartsList
                                        parts={ excludetTo }          
                                        user={ props.user }
                                        canSave={ false }                             
                                    />
                                </>
                            }
                                                                               
                        </Col>
                    }
                    </>
                }
            </Row>
        </>
    )
}
export default withCookies(DriveInfo);