import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';

function getDataRequest(cookies, url, qargs = '', method = "GET", data = null){
    // send GET request with auth data and redirect if auth token is bad.
    if(data){
        var formData = new FormData();
        for(const name in data) {
            if(data[name] && data[name].hasOwnProperty('filename')){
                // for sending unnamed blobs
                formData.append(name, data[name]['blob'], data[name]['filename']);   
            } else if (Array.isArray(data[name])){
                //for sending multiple files
                for(let i=0; i < data[name].length; i++){
                    formData.append(name, data[name][i]);
                }                
            } else {
                // sending normal files
                formData.append(name, data[name]);
            }
        }
    }

    const token = cookies.get('token');
    let args = {
        method: method,
        headers: {
            'Authorization': `Token ${token}`,
        },
        body: formData ? formData : null
    }
    if(method === "GET"){
        // use undefinned Content-Type instead 'multipart/form-data'
        // https://stackoverrun.com/ru/q/10822451
        args['Content-Type'] = 'application/json';
    }

    return fetch(
            `${process.env.REACT_APP_API_URL}${url}${qargs}`, args
        ).then(resp => {
            if(resp.status === 401){
                cookies.set('token', '', { path: '/' });
                window.location.href = '/login';
            }
            return resp;
        });
}

getDataRequest.propTypes = {
    cookies: Cookies,
    url: PropTypes.string.isRequired,
    qargs: PropTypes.string,
    method: PropTypes.string
};

export default getDataRequest;