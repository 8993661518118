import React from 'react';
import { NavLink } from 'react-router-dom';
import { selectFilter } from 'react-bootstrap-table2-filter';
import { Label } from 'react-bootstrap';
import EditableCell from '../table/editable-cell';
import { getWorkStatuses, getDriveBadges } from './drive-status';
import getPlanCellFormatter from '../table/chrpplan-cell';
import getWorkCellFormatter from '../table/chrpwork-cell';
import genBagesFormatter from '../table/badges-formatter-generator';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');

export default class ColumnsManager{
    
    filters = {}

    getFilters = () => {
        return this.filters;
    }

    cleanFilters = () => {
        var filters = this.getFilters();
        for(var f in filters){
            if(filters.hasOwnProperty(f)){
                filters[f]('');
            }
        }
    }

    is_editable = row => {
        return 'waiting_chose' !== row.state;
    }

    getColumns = (brands, statuses) => {    
        const selectBrandOptions = brands.map(b => ({value: b.id, label: b.name}));
        const stmap = {};
        statuses.map(s => { if(s.value){
            stmap[s.value] = s.label;
        }})

        var that = this;
        const to_kinds = getWorkStatuses().reduce((obj, it) => {
            obj[it.value] = it.label;
            return obj;
        }, {});

        const badges_formatter = genBagesFormatter(getDriveBadges());

        let columns = [            
            {   dataField: 'obj_name',
                text: 'Наименование / номер оборудования',
                editable: (col, row) => {
                    return this.is_editable(row);
                },
                formatter: (col, row) => {                    
                    if(this.is_editable(row)){
                        return (<EditableCell cell={col}/>);
                    }
                    return col;         
                },
                sort: true
            },
            {   dataField: 'name',
                text: 'Маркировка',
                editable: (col, row) => {
                    return this.is_editable(row);
                },
                sort: true,
                formatter: (col, row) => {
                    let exp = /[а-яёА-ЯЁ\s]/gi;
                    let out = (<>{col.replace(/\+/ig, " +")} <NavLink to={`/chrp/${row.id}`} target="_blank"><i className="fa fa-external-link"></i></NavLink></>);
                    if(exp.test(col)){
                        out = (                            
                            <div className="animation-pulse">
                                <i className="fa fa-2x fa-exclamation-circle text-warning"></i>&nbsp;
                                <span style={{verticalAlign: 'super'}}>
                                    {ReactHtmlParser(col.replace(exp, "<b style='color: red'>$&</b>").replace(/\+/ig, " +"))}
                                </span>                                
                            </div>
                        );
                    }
                    if(this.is_editable(row)){
                        out = (<EditableCell className="longlink" cell={out}/>);
                    }
                    return out;         
                }
            },
            {   dataField: 'serial_number',
                text: 'Серийный номер',
                editable: (col, row) => {
                    return this.is_editable(row);
                },
                formatter: (col, row) => {    
                    let out = col;
                    if(row['brand_name'] == "ABB" && col.length !== 10){
                        out = (                            
                            <div className="animation-pulse">
                                <i className="fa fa-2x fa-exclamation-circle text-warning"></i>&nbsp;
                                <span style={{verticalAlign: 'super'}}>{out}</span>                                
                            </div>
                        );
                    }         
                    if(this.is_editable(row)){
                        return (<EditableCell cell={out}/>);
                    }
                    return out;         
                },
                sort: true
            },
            {   dataField: 'brand_name',
                text: 'Бренд',
                filter: selectFilter({
                    options: selectBrandOptions,
                    placeholder: 'Вce',
                    getFilter: (filter) => {
                        that.filters['brand_name'] = filter;
                    }
                }),              
                searchable: false,
                editable: false,
            },
            {   dataField: 'date_of_manufacture',
                text: 'Произведен',
                editable: false,
                sort: true,
                formatter: (cell, row) => {
                    if(cell){
                        return moment(cell).format('L');
                    }
                    return '---'
                }
            },
            {   dataField: 'description',
                text: 'Описание (серия, мощность, артикул)',
                sort: false,
                editable: (col, row) => {
                    return this.is_editable(row);
                },
                formatter: (col, row) => {                    
                    if(this.is_editable(row)){
                        return (<EditableCell cell={col}/>);
                    }
                    return col;         
                },
            },
            {   dataField: 'state',
                text: 'Статус',
                editable: false,
                sort: true,
                filter: selectFilter({
                    options: statuses,
                    placeholder: 'Вce',
                    getFilter: (filter) => {
                        that.filters['state'] = filter;
                    }
                }),
                formatter: (cell, row) => {
                    let out = stmap.hasOwnProperty(cell) ? stmap[cell] : '------';
                    if(cell.indexOf('error') + 1){
                        out = (<Label bsStyle='danger'>{out}</Label>);
                    }
                    if(row['badges']){
                        out = ( <>{out} { badges_formatter(row['badges'], row) } </>);
                    }
                    return out;
                }
            },
            {   dataField: 'to_plans_list',
                isDummyField: true,
                text: 'Типы работ',
                editable: false,
                sort: false,                
                formatter: (cell, row) => {
                    if(row.to_plans.length && row.state == 'chose'){
                        return row.to_plans.reduce((out, it, i) => {                            
                            return (
                                <React.Fragment key={`${row.id}${i}`}>
                                    {out} 
                                    <NavLink to={`/chrp/${row.id}/${it.work_kind}`} target="_blank" style={{marginRight: '5px', display: 'inline-block'}}>
                                        { to_kinds[it.work_kind] }
                                    </NavLink>
                                </React.Fragment>
                                );
                        }, '');                        
                    }                    
                    return '';
                }
            },
            {   dataField: 'last_work_date',
                text: 'Последние работы',
                editable: false,
                sort: false,                
                formatter: getWorkCellFormatter()
            },
            {   dataField: 'to_plans',
                text: 'Плановые работы',
                editable: false,
                sort: false,                
                formatter: getPlanCellFormatter()
            },             
        ];        
        return columns;
    }
}