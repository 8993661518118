
export default function genCsvBagesFormatter(badgesOptions){
    // Generate badges formatter for csv export
    var badges = {};
    for(let b in badgesOptions){
        let opt = badgesOptions[b];
        badges[opt.value] = opt.label
    }

    return (cell) => {
        if(cell.length){
            return cell.map( item => ( badges.hasOwnProperty(item) ? badges[item] : item)).join(', ');
        }
        return '';
    }
} 

