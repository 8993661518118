import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Form,
        FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import ColumnsManager from './detail-columns';
import MonthPicker from '../tools/monthpicker';
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');


class FilterForm extends Component{

    state = {
        date: {year: 0, month: 0} 
    }

    pickAMonth = React.createRef();

    changeBrandHandler = evt => {
        this.props.columnsMgr.getFilters()['brand_name'](evt.target.value);
    }

    changeStateHandler = evt => {
        this.props.columnsMgr.getFilters()['state'](evt.target.value);
    }
    
    render(){        
        
        return (
            <Row style={ {marginBottom: '2rem'} }>
                <Col md={12}>
                    <Form>
                        <h3>Фильтрация</h3>
                        <Row>
                            <Col xs={6} md={3}>
                                <FormGroup>
                                    <ControlLabel>Бренд</ControlLabel>
                                    <FormControl componentClass="select" onChange={ this.changeBrandHandler }>
                                        <option value="">все</option>
                                        { this.props.brands.map(b => (<option key={ b.id } value={ b.id }>{ b.name }</option>)) }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            <Col xs={6} md={3}>
                                <FormGroup>
                                    <ControlLabel>Статус</ControlLabel>
                                    <FormControl componentClass="select" onChange={ this.changeStateHandler }>
                                        <option value="">без разницы</option>
                                        { this.props.statuses.map((s, i) => (<option key={ i } value={ s.value }>{ s.label }</option>)) }
                                    </FormControl>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>                       
                            <Col xs={12} sm={6} md={6} lg={5}>
                                <FormGroup>
                                    <ControlLabel>Дата производства</ControlLabel>
                                    <MonthPicker 
                                        onChange={ value => this.props.onExtraFilterChange('date_of_manufacture', value) }
                                        yearOffset={ {start: -30, end: 2} }
                                    />                                    
                                </FormGroup>
                            </Col> 
                            <Col xs={12} sm={6} md={6} lg={5}>
                                <FormGroup>
                                    <ControlLabel>Месяц планируемых работ</ControlLabel>
                                    <MonthPicker 
                                        onChange={ value => this.props.onExtraFilterChange('to_plans__date', value) }
                                        yearOffset={ {start: -1, end: 2} }
                                    />                                    
                                </FormGroup>
                            </Col>
                        </Row>                   
                    </Form>
                </Col>
            </Row>
        );
    }
}

FilterForm.propTypes = {
    columnsMgr: PropTypes.instanceOf(ColumnsManager),
    brands: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
};

export default FilterForm;