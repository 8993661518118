import React from 'react';
import { selectFilter } from 'react-bootstrap-table2-filter';
import Switch from '../table/switch';
import EditableCell from '../table/editable-cell';
import EditableAvatar from './avatar';
import moment from 'moment';
import 'moment/locale/ru';
import ChangePasswordButton from './list-passwordform';

moment.locale('ru');

export default class ColumnsManager{
    
    filters = {}

    getFilters = () => {
        return this.filters;
    }

    cleanFilters = () => {
        var filters = this.getFilters();
        for(var f in filters){
            if(filters.hasOwnProperty(f)){                
                filters[f]('');
            }
        }
    }

    getColumns = (onSwitchGroup, onSwitchActive, onChangeAvatar ,currentUser, groups) => {
            
        const lenvalidator = (str, len=200) => {
            if(str.length <= len ){
                return true;
            }
            return {valid: false, message: 'Длина строки не должна быть более ' + len + ' символов'};
        }
        
        const boolCsvFormatter = (cell) => (cell ? 1 : 0)

        const onChangePhoto = (id, data) => {            
            onChangeAvatar(id, data);
        }

        var that = this;
        return [
            {   dataField: 'photo_icon',
                text: 'Фото',
                headerFormatter: () => (<i className="gi gi-user"></i>),
                formatter: (cell, row) => (<EditableAvatar img={ cell } id={ row['id'] } mode="icon" onChange={onChangePhoto}/>),     
                searchable: false,
                editable: false,
                sort: false,
                classes: 'text-center',
                headerClasses: 'text-center',
                headerStyle: {fontSize: '18px'}
            },

            {   dataField: 'username',
                text: 'Email',                
                searchable: true,
                editable: true,
                formatter: (cell, row) => (<EditableCell cell={cell}/>),
                sort: true,
                validator: (newValue, row, column) => {
                    let lenout = lenvalidator(newValue);
                    if( lenout !== true) return lenout;

                    //https://www.w3resource.com/javascript/form/email-validation.php
                    let mailexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                    if (mailexp.test(newValue)) return true;
                    return {valid: false, message: 'Введите корректный email'};         
                }
            },

            {   dataField: 'last_name',
                text: 'Фамилия',
                searchable: true,
                editable: true,
                formatter: (cell, row) => (<EditableCell cell={cell}/>),
                sort: true,
                validator: (newValue, row, column) => {
                    return lenvalidator(newValue);                                 
                }
            },

            {   dataField: 'first_name',
                text: 'Имя',
                searchable: true,
                editable: true,
                formatter: (cell, row) => (<EditableCell cell={cell}/>),
                sort: true,
                validator: (newValue, row, column) => {
                    return lenvalidator(newValue);                                 
                },
            },
            
            {   dataField: 'is_active',
                text: 'Активен',
                formatter: (cell, row) => (
                        <Switch name='is_active'
                            id={ row.id }
                            disabled={ currentUser.id == row.id }
                            checked={ cell } onChange={ onSwitchActive } variant="success"/>
                    ),
                csvFormatter: boolCsvFormatter,
                filter: selectFilter({
                    options: [
                        {value: '', label: 'без разницы'},
                        {value: 'true', label: 'только активные'},
                        {value: 'false', label: 'только неактивные'}
                    ],
                    getFilter: (filter) => {
                        that.filters['is_active'] = filter;
                    }
                }),
                editable: false,
                sort: false,
                classes: 'text-center',                
            },

            {   dataField: 'is_admin',
                text: 'Администратор',
                formatter: (cell, row) => (
                        <Switch name='admin'
                            id={ row.id }
                            disabled={ currentUser.id == row.id }
                            checked={ cell }
                            onChange={ onSwitchGroup }/>
                    ),
                csvFormatter: boolCsvFormatter,
                filter: selectFilter({
                        options: groups,
                        getFilter: (filter) => {
                            that.filters['is_admin'] = filter;
                        }
                     }),
                editable: false,
                sort: false,
                classes: 'text-center',
            },

            {   dataField: 'is_senior_manager',
                text: 'Старший менеджер',
                formatter: (cell, row) => (<Switch name='senior_manager' id={ row.id } checked={ cell } onChange={ onSwitchGroup }/>),
                csvFormatter: boolCsvFormatter,
                editable: false,
                sort: false,
                classes: 'text-center',
            },

            {   dataField: 'is_manager',
                text: 'Менеджер',
                formatter: (cell, row) => (<Switch name='manager' id={ row.id } checked={ cell } onChange={ onSwitchGroup }/>),
                csvFormatter: boolCsvFormatter,
                editable: false,
                sort: false,
                classes: 'text-center',
            },

            {   dataField: 'is_senior_engineer',
                text: 'Старший инженер',
                formatter: (cell, row) => (<Switch name='senior_engineer' id={ row.id } checked={ cell } onChange={ onSwitchGroup }/>),
                csvFormatter: boolCsvFormatter,
                editable: false,
                sort: false,
                classes: 'text-center',
            },

            {   dataField: 'is_engineer',
                text: 'Инженер',
                formatter: (cell, row) => (<Switch name='engineer' id={ row.id } checked={ cell } onChange={ onSwitchGroup }/>),
                csvFormatter: boolCsvFormatter,
                editable: false,
                sort: false,
                classes: 'text-center',
            },

            //<i class="fa fa-key"></i>
            {   dataField: 'actions',
                isDummyField: true,
                text: 'Действия',
                formatter: (cell, row) => (<ChangePasswordButton id={ row.id } username={ row.username } />),
                csvExport: false,
                editable: false,
                sort: false,
                classes: 'text-center',
            },
            
        ];
    }
}