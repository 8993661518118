import React, { useState } from 'react';
import FieldGroup from '../tools/fieldgroup';
import { Modal, Row, Col, Button } from 'react-bootstrap';


const FinalWindow = (props) => {
    const [comment, setComment] = useState('')

    const handleChangeComment = evt => {
        setComment(evt.target.value);
    }

    return (
        <Modal show={ props.show } onHide={ props.onHide }>
            <Modal.Header closeButton>
                <Modal.Title>Завершение подбора</Modal.Title>
            </Modal.Header>
            <Modal.Body>                            
                <Row>
                    <Col xs={12} md={12}>
                        <FieldGroup
                            id="comment"
                            label="Комментарий"
                            componentClass="textarea"
                            value={ comment }
                            onChange={ handleChangeComment }/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle="danger" disabled={props.saving} style={ {marginLeft: '5px'} } onClick={ e => props.onSubmit('chose_error', comment) }>
                    <i className={props.saving ? "fa fa-spinner fa-spin" : "gi gi-warning_sign"} ></i> Ошибка подбора</Button>
                
                <Button bsStyle="primary" disabled={props.saving} style={ {marginLeft: '5px'} } onClick={ e => props.onSubmit('no_work_provided', comment) }>
                    <i className={props.saving ? "fa fa-spinner fa-spin" : "gi gi-ban" }></i> Работы не предусмотрены</Button>
                
                <Button bsStyle="success" disabled={props.saving || !props.choseEnabled} style={ {marginLeft: '5px'} } onClick={ e => props.onSubmit('chose', comment) }>
                    <i className={props.saving ? "fa fa-spinner fa-spin" : "gi gi-ok"}></i> Подобрано</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default FinalWindow;