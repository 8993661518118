import React, {Component} from "react";

class PageHeader extends Component{
    
    render(){
        return(
            <div className="content-header">
                <div className="header-section">
                    <h1>
                        <i className={this.props.icon?this.props.icon: "gi gi-table"}></i>
                        {this.props.title}
                        <br/>
                        <small>{this.props.subtitle}</small>
                    </h1>
                </div>
            </div>
        );
    }    
}
export default PageHeader;