import React from 'react';
import Label from 'react-bootstrap/lib/Label';


export default function genBagesFormatter(badgesOptions, ignore_unnamed=false){
    // Generate badges formatter for tables
    var badges = {};
    for(let b in badgesOptions){
        let opt = badgesOptions[b];
        let mod = 'warning';
        if(opt.value.indexOf('info') === 0){
            mod = 'primary';
        } else if(opt.value.indexOf('danger') === 0){
            mod = 'danger';
        } else if(opt.value.indexOf('default') === 0){
            mod = 'default';
        }
        badges[opt.value] = (<Label bsStyle={mod}>{ opt.label }</Label>)
    }

    return (cell, row) => {
        if(cell.length){
            return (cell.map( (item, ind) => ( 
                        badges.hasOwnProperty(item) ?
                        <React.Fragment key={item + (row['id'] || ind)}>
                            <span >{badges[item]}</span><br/>
                        </React.Fragment> :
                        <React.Fragment key={item + (row['id'] || ind)}>
                            {!ignore_unnamed &&
                                <React.Fragment key={item + (row['id'] || ind)}>
                                    <span >{item}</span><br/>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    )
                )
            );
        }
        return '';
    }
} 

