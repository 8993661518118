import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Form from 'react-bootstrap/lib/Form';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Radio from 'react-bootstrap/lib/Radio';
import Datetime from 'react-datetime';
import { Comparator } from 'react-bootstrap-table2-filter';
import ColumnsManager from './parts-columns';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');


class FilterForm extends Component{
    
    state = {
        dateComparatorValue: '>=',
        dateComparator: Comparator.GE,
        date: ''
    }

    changeMaterialTypeHandler = evt => {
        this.props.columnsMgr.getFilters()['material_type'](evt.target.value);
    }
    
    render(){
        return (
            <Row style={ {marginBottom: '2rem'} }>
                <Col md={12}>
                    <Form>
                        <h3>Фильтрация</h3>
                        <Col xs={6} md={2}>
                            <FormGroup>
                                <ControlLabel>Вид технического обслуживания</ControlLabel>
                                <FormControl componentClass="select"
                                    onChange={ this.changeMaterialTypeHandler }
                                    defaultValue={ this.props.pmDefault }>
                                    <option value="">все</option>
                                    { this.props.pmOptions.map((o, i) => (
                                            <option key={ i } value={ o.value }>{ o.label }</option>
                                        ))
                                    }
                                </FormControl>
                            </FormGroup>
                        </Col>
                        
                    </Form>
                </Col>
            </Row>
        );
    }
}

FilterForm.propTypes = {
    columnsMgr: PropTypes.instanceOf(ColumnsManager),
};

export default FilterForm;