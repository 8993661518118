
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { 
        Alert, Well, Button, InputGroup,
        FormGroup, Form, Row, Col, Grid
    } from 'react-bootstrap';


class PasswordReset extends Component {
    state = {
        credentials: {
            email: '',
        },
        inputError: false,
        sent: false
    }

    inputChanged = evt => {
        let credentials = this.state.credentials;
        credentials[evt.target.name] = evt.target.value;
        this.setState({ credentials: credentials });
    }

    reset = evt => {   
        evt.preventDefault();
        this.setState({inputError: false});
        fetch(`${process.env.REACT_APP_API_URL}/api/password_reset/`, {
            method: 'POST',
            headers: {'Content-type': 'application/JSON'},
            body: JSON.stringify(this.state.credentials)
        }).then(resp => {
            if( resp.status == 200 ){
                return resp.json();
            } else {
                throw 'oyoyoyoy';
            }
        }).then(res => {    
            this.setState({sent: true});
        })
        .catch(() => this.setState({inputError: true}));
    }

    render(){
        return (            
            <Grid>
                <Row>            
                    <Col>
                        <div id="login-container">
                            <div className="login-title text-center">
                                <h1><strong>Сброс пароля</strong></h1>
                            </div>
                            <div className="block push-bit">                
                                <Form bsStyle="horizontal" onSubmit={ this.reset } className="form-horizontal form-bordered">
                                    
                                    {this.state.inputError &&
                                        <FormGroup className="form-actions">
                                            <Col xs={12} className="text-center">
                                                <p style={{fontSize: '14px'}}>Для смены пароля обратитесь к администратору по адресу admin@chrp.szemo-in.ru</p>
                                            </Col>
                                        </FormGroup>
                                    }
                                    
                                    {!this.state.sent && !this.state.inputError &&
                                        <>
                                            <FormGroup>
                                                <Col xs={12}>
                                                    <InputGroup>
                                                        <span className="input-group-addon"><i className="gi gi-user"></i></span>
                                                        <input
                                                            type="text" id="username"
                                                            name="email" className="form-control input-lg"
                                                            value={ this.state.credentials.email } placeholder="Имя пользователя"
                                                            onChange={ this.inputChanged }/>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            
                                            <FormGroup className="form-actions">
                                                <Col xs={12} className="text-right">
                                                    <Button bsStyle="primary" bsSize="sm" type="submit">
                                                        <i className="fa fa-angle-right"></i> Сбросить пароль
                                                    </Button>
                                                </Col>                        
                                            </FormGroup>
                                        </>                                                                    
                                    }
                                    {this.state.sent && <FormGroup className="form-actions">
                                            <Col xs={12} className="text-center">
                                                <p style={{fontSize: '14px'}}>Проверьте почту, туда отправлено сообщение с инструкциями</p>
                                            </Col>
                                        </FormGroup>
                                    }

                                        <FormGroup>
                                            <Col xs={12} className="text-center">
                                                <NavLink to="/login">
                                                    <small>Вернуться на страницу входа</small>
                                                </NavLink>
                                            </Col>
                                        </FormGroup>
                                    
                                </Form>                
                            </div>            
                        </div>
                    </Col>
                </Row>
            </Grid>    
        );
    }
}
export default withCookies(PasswordReset);