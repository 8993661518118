import React from 'react';
import { Table, Button } from 'react-bootstrap';
import genBagesFormatter from '../table/badges-formatter-generator';


const TOPartsList = (props) => {
    const badges = [
        {value: 'warning_need_update', label: 'outdated'}
    ]
    const badges_formatter = genBagesFormatter(badges, true);

    return (        
        <>         
            <Table condensed striped bordered>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Тип ТО</th>
                        <th>Код</th>
                        <th>Код наб.</th>
                        <th>Кол-во</th>
                        <th>Ед.</th> 
                        <th>Описание</th>
                        {(props.user.is_engineer || props.user.is_senior_engineer || props.user.is_admin) &&
                            <>
                                <th>Обозначение</th>
                                <th>Техническое описание</th>
                            </>
                        }
                        <th>Пометки</th>               
                    </tr>
                </thead>
                <tbody>
                    {props.parts.map((it, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{it.material_type}</td>
                            <td style={ {color: it.spare_part? 'inherit': 'red' } }>
                                {it.code}
                            </td>
                            <td>
                                {it.kit_code}
                            </td>
                            <td>{it.quantity}</td>
                            <td>{it.unit}</td>
                            <td>{it.description}</td>
                            {(props.user.is_engineer || props.user.is_senior_engineer || props.user.is_admin) &&
                                <>
                                    <td>{it.type_designation}</td>
                                    <td>{it.technical_description}</td>
                                </>
                            }
                            <td>
                                {it.badges && badges_formatter(it.badges, it)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {props.canSave &&
                <div className="pull-right">
                    
                    {!!props.parts.length &&
                        <Button bsStyle="default" disabled={ props.saving } onClick={ props.onReset }>
                            <i className="gi gi-restart"></i> Сбросить
                        </Button>
                    }
                    {!!props.parts.length &&
                        <Button bsStyle="default" disabled={ props.saving } onClick={ props.onEdit } style={ {marginLeft: '5px'} }>
                            <i className="gi gi-edit"></i> Редактировать
                        </Button>
                    }
                    <Button bsStyle="primary" disabled={ props.saving } onClick={ evt => props.onSave() } style={ {marginLeft: '5px'} }>
                    {props.saving? <i className="fa fa-spinner fa-spin"></i> :<i className="hi hi-floppy_disk"></i>} Сохранить
                    </Button>
                </div>
            }
        </>
    );
}
export default TOPartsList;
