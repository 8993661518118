import React from 'react';
import {FormControl, FormGroup, HelpBlock, ControlLabel} from 'react-bootstrap';

export default function FieldGroup({ id, label, help, validationState, ...props }) {
    return (
      <FormGroup controlId={id} validationState={ validationState||null }>
        <ControlLabel>{label}</ControlLabel>
        <FormControl {...props} />
        {help && <HelpBlock>{help}</HelpBlock>}
      </FormGroup>
    );
}