import React, {Component} from 'react';
import { withCookies } from 'react-cookie';
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';
import RemoteAll from '../table';
import ColumnsManager from './list-columns';
import prepareQueryArgs from './list-argshandler';
import FilterForm from './list-filter';

import {ToolsSlidedown} from '../tools/slidedown';
import getDataRequest from '../tools/datarequest';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';


class SparePartsList extends Component {

    state = {
        items: [],
        brands: [],
        sizePerPage: 20,
        page: 1,
        totalSize: 100,
        loading: false,
        filterVisible: false,
        filterClassname: '',
        isExportClicked: false,
    }

    extraFilters = {
        'mp_parts__exists': '',
    }

    componentDidMount(){   
        this.brandsRequest();
        this.dataRequest(this.state.page, this.state.sizePerPage);
    }

    dataRequest = (page, sizePerPage, filters, searchText, sortField, sortOrder) => {
        
        filters = filters || {};
        //add extra filters
        for(let field in this.extraFilters){
            filters[field] = {'filterVal': this.extraFilters[field]};
        }
        var qargs = prepareQueryArgs(page, sizePerPage, filters, searchText, sortField, sortOrder);
        this.setState({loading: true});

        getDataRequest(
            this.props.cookies, '/api/spareparts/', qargs
        ).then(
            resp => resp.json()
        ).then(
            res => this.setState({
                    items: res['results'],
                    totalSize: res['count'],
                    loading: false
                })
        ).catch(error => console.log('error', error));
    }
    
    brandsRequest = () => {
        getDataRequest(
            this.props.cookies, '/api/brands/', ''
        ).then(
            resp => resp.json()
        ).then(
            res => this.setState({brands: res})
        ).catch(error => console.log('error', error));
    }

    editRequest = (cellEdit) => {
        const id = cellEdit['rowId'];
        let data = {}
        data[cellEdit['dataField']] = cellEdit['newValue'];
        getDataRequest(
            this.props.cookies, `/api/spareparts/${id}/`, '', 'PATCH', data
        ).then(
            resp => resp.json()
        ).then(
            res => {
                let items = this.state.items;
                for(let i = 0; i < items.length; i++){
                    if(items[i]['id'] == res.id){
                        items[i] = res;
                        break;
                    }
                }
                this.setState({items: items});
            }
        ).catch(error => console.log('error', error));
    }

    tabletimer = ''
    tableargs = []
    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {        
        if(type == 'cellEdit'){
            this.editRequest(cellEdit);            
        } else {
            if(type != 'pagination'){
                page = 1;
            }
            this.setState({ sizePerPage: sizePerPage, page: page });
    
            // Slow down remote requests to avoid duplicate requests when clearing filters.
            this.tableargs = [page, sizePerPage, filters, searchText, sortField, sortOrder];
            if(this.tabletimer == ''){
                this.tabletimer = setTimeout(() => {
                    this.dataRequest.apply(this, this.tableargs);
                    this.tabletimer = '';
                }, 50);
            }
        }
        
    }

    extraFilterChangeHandler = (field, value) => {
        this.extraFilters[field] = value;
        //use last request table args for fictive filtering 
        this.dataRequest.apply(this, this.tableargs);
    }
   
    setExportClicked = (value) => {
        this.setState({isExportClicked: value})
    }

    exportCSV = evt => {
        evt.currentTarget.blur();
        evt.preventDefault();        
        this.setExportClicked(true);
    }
    
    render(){
        
        const badges = [
            {value: 'info_change_name', label: 'changed'},
            {value: 'warning_need_update', label: 'outdated'}
        ]

        const columnsMgr = new ColumnsManager();
        const columns = columnsMgr.getColumns(this.state.brands, badges);

        const toggleFilter = evt => {
            evt.currentTarget.blur();
            evt.preventDefault();
            columnsMgr.cleanFilters();
            let visible = this.state.filterVisible;
            this.setState({filterVisible: !visible});
            if(!visible){
                setTimeout(
                    () => {
                        // Ensures the visibility of the nested calendar
                        this.setState({filterVisible: !visible});
                    },
                    300
                );
            }
        }
        
        return (
            <React.Fragment>
            <PageHeader title="Таблица ЗИП" subtitle="Весь список ЗИП" icon="gi gi-table"/>
            <Breadcrumbs crumbs={['ЗИП']}/>
            <div className="block full">            
                <div className="block-title">
                    <div className="block-options pull-right">
                        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Фильтрация</Tooltip>}>
                            <a href="#" 
                                className="btn btn-alt btn-sm btn-default"
                                onClick={ toggleFilter }
                            ><i className="fa fa-filter"></i></a>
                        </OverlayTrigger>                    
                        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Экспорт в csv</Tooltip>}>
                            <a href="#"
                                className="btn btn-alt btn-sm btn-default"
                                onClick={ this.exportCSV }
                            ><i className="gi gi-file_import"></i></a>
                        </OverlayTrigger>
                    </div>                  
                    <h2>Весь ЗИП</h2>
                </div>

                <ToolsSlidedown open={this.state.filterVisible}>
                    <FilterForm
                        columnsMgr={ columnsMgr }
                        brands={ this.state.brands }
                        badges={ badges }
                        onExtraFilterChange={ this.extraFilterChangeHandler }
                    />                   
                </ToolsSlidedown>

                <RemoteAll
                    data = { this.state.items }
                    columns = { columns }
                    page = { this.state.page }
                    sizePerPage = { this.state.sizePerPage }
                    totalSize = { this.state.totalSize }
                    onTableChange = { this.handleTableChange }
                    loading = { this.state.loading }
                    exportCSV = {{
                            fileName: 'ЗИП.csv',
                            separator: ';',
                            isExportClicked: this.state.isExportClicked,
                            setExportClicked: this.setExportClicked,
                            onlyExportSelection: true,
                            exportAll: false
                        }
                    }
                    selectRow = {{mode: 'checkbox', clickToSelect: false}}
                    
                />
                
            </div>
            </React.Fragment>
        );
    }
}

export default withCookies(SparePartsList);