import React, {Component} from 'react';
import { withCookies } from 'react-cookie';
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';
import RemoteAll from '../table';
import ColumnsManager from './list-columns';
import prepareQueryArgs from './list-argshandler';
import FilterForm from './list-filter';

import {ToolsSlidedown} from '../tools/slidedown';
import getDataRequest from '../tools/datarequest';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import ChrpPlanLegend from '../table/chrpplan-legend';


class AddressList extends Component {

    state = {
        items: [],
        managers: [],
        is_senior_manager: !!(this.props.user.permissions.indexOf('access_to_all_client') + 1),
        sizePerPage: 100,
        page: 1,
        totalSize: 100,
        loading: false,
        filterVisible: false,
        filterClassname: '',
        isExportClicked: false,
    };
    extraFilters = {
        'to_plans__date_before': '',
        'to_plans__date_after': '',
    };   

    componentDidMount(){
        this.managersRequest();
        this.dataRequest(this.state.page, this.state.sizePerPage);
    }

    dataRequest = (page, sizePerPage, filters, searchText, sortField, sortOrder) => {
        
        if(!this.state.is_senior_manager){
            filters = filters || {};
            filters['responsible'] = {'filterVal': this.props.user.id};
        }

        filters = filters || {};
        //add exrta filters
        for(let field in this.extraFilters){
            filters[field] = {'filterVal': this.extraFilters[field]};
        }

        var qargs = prepareQueryArgs(page, sizePerPage, filters, searchText, sortField, sortOrder);
        this.setState({loading: true});

        getDataRequest(
            this.props.cookies, '/api/addresses/', qargs
        ).then(
            resp => resp.json()
        ).then(
            res => this.setState({
                    items: res['results'],
                    totalSize: res['count'],
                    loading: false
                })
        ).catch(error => console.log('error', error));
    }

    managersRequest = () => {
        
        if(this.state.is_senior_manager){           
            
            getDataRequest(
                this.props.cookies, '/api/users/', '?groups__name__icontains=manager&is_active=true'
            ).then(
                resp => resp.json()
            ).then(
                res => this.setState({
                        managers: res['results'],                        
                    })
            ).catch(error => console.log('error', error));
        }
    }    

    editRequest = (cellEdit) => {
        const id = cellEdit['rowId'];
        let data = {}
        data[cellEdit['dataField']] = cellEdit['newValue'];
        getDataRequest(
            this.props.cookies, `/api/addresses/${id}/`, '', 'PATCH', data
        ).then(
            resp => resp.json()
        ).then(
            res => {
                let items = this.state.items;
                for(let i = 0; i < items.length; i++){
                    if(items[i]['id'] == res.id){
                        for(let j in items[i]){
                            if(items[i].hasOwnProperty(j) && res.hasOwnProperty(j)){
                                items[i][j] = res[j];
                            }    
                        }                        
                        break;
                    }
                }
                this.setState({items: items});
            }
        ).catch(error => console.log('error', error));
    }


    tabletimer = ''
    tableargs = []
    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {        
        if(type == 'cellEdit'){
            this.editRequest(cellEdit);            
        } else {
            if(type != 'pagination'){
                page = 1;
            }
            this.setState({ sizePerPage: sizePerPage, page: page });
    
            // Slow down remote requests to avoid duplicate requests when clearing filters.
            this.tableargs = [page, sizePerPage, filters, searchText, sortField, sortOrder];
            if(this.tabletimer == ''){
                this.tabletimer = setTimeout(() => {
                    this.dataRequest.apply(this, this.tableargs);
                    this.tabletimer = '';
                }, 50);
            }
        }
        
    }
   
    setExportClicked = (value) => {
        this.setState({isExportClicked: value})
    }

    exportCSV = evt => {
        evt.currentTarget.blur();
        evt.preventDefault();        
        this.setExportClicked(true);
    }

    handleExtraFilter = (field, value) => {
        if(field === 'to_plans__date'){
            if(value){
                this.extraFilters[field + '_after'] = value[0];
                this.extraFilters[field + '_before'] = value[1];
            } else {
                this.extraFilters[field + '_after'] = '';
                this.extraFilters[field + '_before'] = '';
            }
        } else {
            this.extraFilters[field] = value;
        }
        //use last request table args for fictive filtering 
        this.dataRequest.apply(this, this.tableargs);
    }
    
    render(){
        
        const badges = [
            {value: 'info_change_name', label: 'Имя изменено'},
            {value: 'warning_need_update', label: 'Обновить!'}
        ]

        const columnsMgr = new ColumnsManager();
        const columns = columnsMgr.getColumns(this.state.is_senior_manager, this.state.managers);

        const toggleFilter = evt => {
            evt.currentTarget.blur();
            evt.preventDefault();
            columnsMgr.cleanFilters();
            let visible = this.state.filterVisible;
            this.setState({filterVisible: !visible});
            if(!visible){
                setTimeout(
                    () => {
                        // Ensures the visibility of the nested calendar
                        this.setState({filterVisible: !visible});
                    },
                    300
                );
            }
        }
        
        return (
            <React.Fragment>
            <PageHeader title="Таблица клиентов" subtitle="" icon="gi gi-suitcase"/>
            <Breadcrumbs crumbs={['Клиенты']}/>
            <div className="block full">            
                <div className="block-title">
                    <div className="block-options pull-right">
                        {this.state.is_senior_manager &&
                            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Фильтрация</Tooltip>}>
                                <a href="#" 
                                    className="btn btn-alt btn-sm btn-default"
                                    onClick={ toggleFilter }
                                ><i className="fa fa-filter"></i></a>
                            </OverlayTrigger>
                        }     
                        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Экспорт в csv</Tooltip>}>
                            <a href="#"
                                className="btn btn-alt btn-sm btn-default"
                                onClick={ this.exportCSV }
                            ><i className="gi gi-file_import"></i></a>
                        </OverlayTrigger>
                    </div>                  
                    <h2>Полный список клиентов</h2>
                </div>
                
                {this.state.is_senior_manager &&
                    <ToolsSlidedown open={this.state.filterVisible}>
                        <FilterForm 
                            columnsMgr={ columnsMgr }
                            managers={ this.state.managers }
                            badges={ badges }
                            onExtraFilterChange={ this.handleExtraFilter }
                        />                   
                    </ToolsSlidedown>
                }

                <ChrpPlanLegend />

                <RemoteAll
                    data = { this.state.items }
                    columns = { columns }
                    page = { this.state.page }
                    sizePerPage = { this.state.sizePerPage }
                    totalSize = { this.state.totalSize }
                    onTableChange = { this.handleTableChange }
                    loading = { this.state.loading }
                    exportCSV = {{
                            fileName: 'Клиенты.csv',
                            separator: ';',
                            isExportClicked: this.state.isExportClicked,
                            setExportClicked: this.setExportClicked,
                            onlyExportSelection: true,
                            exportAll: false
                        }
                    }
                    selectRow = {{mode: 'checkbox', clickToSelect: false}}
                    
                />
                
            </div>
            </React.Fragment>
        );
    }
}

export default withCookies(AddressList);