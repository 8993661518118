import React, { useState } from 'react';
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';
import {
        Alert, Button, ControlLabel, Form, FormControl, FormGroup, Modal,
        OverlayTrigger, Tooltip
    } from 'react-bootstrap';
import FileSaver from 'file-saver';


function ExportButton(props) {

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [year, setYear] = useState("");
    const [errors, setErrors] = useState([]);

    const handleClose = () => {      
        setShow(false);
        setYear("");  
        setErrors([]); 
    }
    
    const handleShow = evt => {
        evt.currentTarget.blur();
        setShow(true);
    }
    
    const sendRequest = evt => {
        setErrors([]);
        evt.currentTarget.blur();
        evt.preventDefault();
        let dr = props.getDrives().filter(d => d.state == 'chose');
        if(!dr.length){            
            setErrors(['Выберите хотябы один привод с подбором']);
            return false;
        }
        let data = {ids: dr.map(d => d.id), year: year};

        setLoading(true);
        getDataRequest(
            props.cookies, `/api/drives/genprice/`, '', 'POST', data
        ).then(
            resp => {
                if(resp.status >= 400){
                    throw `Error ${resp.status}`;
                }
                return resp.blob();
            }   
        ).then(
            res => {
                FileSaver.saveAs(res, 'drives_export.xlsx');
                handleClose();
            }
        ).catch(error => {
                console.log('error', error)
            }
        ).finally(
            () => setLoading(false)
        );
    }

    const curYear = (new Date()).getFullYear();

    return (
        <>
            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Экспорт&nbsp;в&nbsp;xlsx</Tooltip>}>
                <a href="#" className="btn btn-alt btn-sm btn-default" onClick={ handleShow }>
                    {loading?
                        <i className="fa fa-spinner fa-spin"></i>
                    :
                        <i className="fi fi-xlsx"></i>
                    }
                </a>
            </OverlayTrigger>
            <Modal show={show} onHide={ handleClose }>
                <Modal.Header closeButton>
                    <Modal.Title>Экспорт в xlsx</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormGroup controlId="">
                            <ControlLabel>Год первого предлагаемого ТО</ControlLabel>
                            <FormControl componentClass="select" onChange={ e => {setYear(e.target.value)} } value={year}>
                                <option value="">работы не проводились</option>
                                { Array(20).fill(curYear).map((y, i) => (<option key={ i + curYear - 10 } value={ i + curYear - 10 }>{ i + curYear - 10 }</option>)) }
                            </FormControl>                   
                        </FormGroup>          
                    </Form>
                    {errors.map((error, i) => (
                            <Alert key={i} bsStyle='danger' >{ error }</Alert>
                        )
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={ handleClose }>Отменить</Button>
                    <Button bsStyle="primary" onClick={ sendRequest } disabled={ loading }>Экспорт</Button>
                </Modal.Footer>
            </Modal>       
        </>
    );
}
export default withCookies(ExportButton);