import React from 'react'
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';

function SparePartComplectsList(props) {

    return (
        <>
        <PageHeader title="Комплекты ЗИП" subtitle="Таблица комплектов ЗИП" icon="gi gi-table"/>
        <Breadcrumbs crumbs={['Комплекты ЗИП']}/>
        <div className="block">            
            <div className="block-title">
                <div className="block-options pull-right">
                    <a href="#" className="btn btn-alt btn-sm btn-default" data-toggle="tooltip" title="Settings"><i className="fa fa-cog"></i></a>
                    <div className="btn-group btn-group-sm">
                        <a href="#" className="btn btn-alt btn-sm btn-default dropdown-toggle enable-tooltip" data-toggle="dropdown" title="Options"><span className="caret"></span></a>
                        <ul className="dropdown-menu dropdown-custom dropdown-menu-right">
                            <li>
                                <a href="#"><i className="gi gi-cloud pull-right"></i>Simple Action</a>
                                <a href="#"><i className="gi gi-airplane pull-right"></i>Another Action</a>
                            </li>
                            <li className="divider"></li>
                            <li>
                                <a href="#"><i className="fa fa-wrench fa-fw pull-right"></i>Separated Action</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <h2>Block</h2>
            </div>
            <p>...</p>
            
        </div>
        </>
    )
}

export default SparePartComplectsList;