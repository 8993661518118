import React, { useRef, Component } from "react";
import { withCookies } from 'react-cookie';
import { NavLink } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import getDataRequest from './tools/datarequest';


class CustomToggle extends Component {
    constructor(props, context) {
      super(props, context);
  
      this.handleClick = this.handleClick.bind(this);
    }
  
    handleClick(e) {
      e.preventDefault();
  
      this.props.onClick(e);
    }

    render(){
        return (
            <a href="#" onClick={ this.handleClick }>
                {this.props.children}
            </a>
        )
    }
}

function Header(props){
    const buttonEl = useRef(null);
    
    const sidebarControlClicked = () => {
        props.toggleSidebar();
    }

    const logout = evt => {
        evt.stopPropagation();
        evt.preventDefault();
        getDataRequest(
            props.cookies, '/api/users/logout/', ''
        ).then(
            resp => { 
                props.cookies.set('token', '', { path: '/' });
                window.location.href = '/login';
            }
        ).catch(error => console.log('error', error));
    }

    const onLinkClick = evt => {
        buttonEl.current.click();
    }

    return(
        <>
        <header className="navbar navbar-default">            

            <ul className="navbar-nav-custom nav">
                <li>
                    <a onClick={ sidebarControlClicked }>
                        <i className="fa fa-bars fa-fw"></i>
                    </a>
                </li>
            </ul>

            <ul className="pull-right navbar-nav-custom nav">
                <Dropdown componentClass="li" id="dropdown-profile">
                    <CustomToggle bsRole="toggle">
                        <img ref={buttonEl} src={ props.user.photo_icon } alt="avatar"/> <i className="fa fa-angle-down"></i>
                    </CustomToggle>
                    <Dropdown.Menu className="dropdown-custom dropdown-menu-right" onClick={ onLinkClick }>
                        <li className="dropdown-header text-center">{ props.user.username || '' }</li>
                        <li>
                            <NavLink exact to="/profile" 
                                activeClassName="active"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Профиль">
                                <i className="gi gi-user fa-fw pull-right"></i>
                                Профиль
                            </NavLink>
                            <a href="#" onClick={ logout }>
                                <i className="gi gi-exit fa-fw pull-right"></i>
                                Выйти
                            </a>
                                                            
                        </li>
                    </Dropdown.Menu>
                </Dropdown>
            </ul>
        </header>
        </>
    );
    
}

export default withCookies(Header);