import React, { useState } from 'react';
import {Button, InputGroup, FormControl } from 'react-bootstrap';
import Picker from 'react-month-picker';
import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');


export default function MonthPicker(props) {
    
    const [dateRange, setDateRange] = useState({from: {year: 0, month: 0}, to: {year: 0, month: 0}});
    const [year, setYear] = useState('');
    const pickAMonth = React.createRef();


    const handleMonthChange = (yr, mt, ind) => {
        const keys = ['from', 'to'];
        let rng = {...dateRange}
        
        rng[keys[ind]] = {year: yr, month: mt};
        
        if(rng.from.year !== 0 && rng.to.year === 0){
            rng.to = {...rng.from};
        }
        setDateRange(rng);
        setYear(getYearSelection(rng));
        const formatmt = (m) => m < 10 ? '0' + m : m + '';
        
        const startDay = [rng.from.year, formatmt(rng.from.month), 1].join('-');
        const end = [rng.to.year, formatmt(rng.to.month), 1].join('-');
        const endDay = moment(end).endOf('month').format('YYYY-MM-DD');
        props.onChange([startDay, endDay]);
    }

    const cleanMonth = evt => {
        setDateRange({from: {year: 0, month: 0}, to: {year: 0, month: 0}});
        setYear('');
        props.onChange('');
    }

    const handleClickMonthBox = evt => {
        pickAMonth.current.show();
    }

    const getDateText = () => {
        const {year, month} = dateRange.from;
        const year2 = dateRange.to.year;
        const month2 = dateRange.to.month;
        if(year && month && year2 && month2){
            let fr = moment([year, month, '15'].join('-')).format('MMM YYYY');
            let to = moment([year2, month2, '15'].join('-')).format('MMM YYYY');
            return [fr, to].join(' - ');
        } else {
            return '';
        }        
    }

    const getYearSelection = (rng) => {
        rng = rng || dateRange;
        console.log(rng);
        const {year, month} = rng.from;
        const year2 = rng.to.year;
        const month2 = rng.to.month;
        if(year == year2 && month == 1 && month2 == 12){
            return year;
        }
        return '';
    }

    const changeYear = ev => {
        let value = ev.target.value;
        if(value){
            setDateRange({from: {year: value, month: 1}, to: {year: value, month: 12}});
            setYear(value);
            const startDay = [value, '01', 1].join('-');
            const end = [value, 12, 1].join('-');
            const endDay = moment(end).endOf('month').format('YYYY-MM-DD');            
            props.onChange([startDay, endDay]);
        } else {
            cleanMonth();            
        }   
    }

    const months = Array(12).fill().map((it, i) => (moment('2020-' + (i < 9? '0' + (i + 1): i + 1) + '-15' ).format('MMM')));
    const years = {
        min: parseInt(moment().format('YYYY')) + props.yearOffset.start,
        max: parseInt(moment().format('YYYY')) + props.yearOffset.end
    };

    const yearsList = [];
    for(let i = years.min; i <= years.max; i++){
        yearsList.push(i);
    }

    return (
        <div className="monthpicker_wrap">
            <Picker  
                ref={ pickAMonth }                                     
                years={ years }
                lang={ months }
                value={ dateRange }
                onChange={ handleMonthChange }
            >                                        
                <InputGroup>                                            
                    <FormControl value={ getDateText() } onClick={ handleClickMonthBox } onChange={ () => {} }/>
                    <InputGroup.Button>
                        <Button onClick={ cleanMonth }><i className="gi gi-remove_2"></i></Button>
                    </InputGroup.Button>
                </InputGroup>            
            </Picker>
            <div className="monthpicker_years">                                                           
                <FormControl componentClass="select" value={ year } onChange={ changeYear }>
                    <option value="">год не выбран</option>
                    {yearsList.map(y => (<option key={y} value={y}>{y}</option>))}
                </FormControl>
            </div>
        </div>
    )

}