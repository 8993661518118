import React from 'react';
import { Panel } from 'react-bootstrap'
 
export function ToolsSlidedown(props) {
  return (
    <Panel expanded={props.open} style={{border: 'none'}} onToggle={ () => {} }>
      <Panel.Collapse>
        <Panel.Body style={{padding: 0}}>
          {props.open? props.children: ''}
        </Panel.Body>
      </Panel.Collapse>
    </Panel>
  )
}