import React from "react";
import { NavLink } from "react-router-dom";
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';


function UserInfo(props) {

    const logout = evt => {
        evt.stopPropagation();
        evt.preventDefault();
        getDataRequest(
            props.cookies, '/api/users/logout/', ''
        ).then(
            resp => {
                props.cookies.set('token', '', { path: '/' });
                window.location.href = '/login';
            }
        ).catch(error => console.log('error', error));
    }

    return (
        <div className="sidebar-section sidebar-user clearfix sidebar-nav-mini-hide">
            <div className="sidebar-user-avatar">
                <NavLink exact to="/profile">
                    <img src={ props.user.photo_icon } alt="avatar"/>
                </NavLink>
            </div>
            <div className="sidebar-user-name">
                { props.user.first_name||'Имя'} { props.user.last_name||'Фамилия'}</div>
            <div className="sidebar-user-links">
                <NavLink exact to="/profile" 
                    activeClassName="active"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Профиль"
                ><i className="gi gi-user"></i></NavLink>                    
                <a href="" data-toggle="tooltip" data-placement="bottom" title="Выйти" onClick={ logout }><i className="gi gi-exit"></i></a>
            </div>
        </div>
    );
    
}
export default withCookies(UserInfo);
