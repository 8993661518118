import React from 'react';
import {Row, Col, Panel, Label} from 'react-bootstrap';


const ChrpPlanLegend = props => {
    return (
        <Row>
            <Col md={12}>
                <Panel>
                    <Panel.Body>
                        <Row>
                            <Col md={12}><p>Плановые работы</p></Col>
                            <Col md={4} sm={6}>
                                <Label bsStyle="default">ТОn</Label> - до работ более года или они уже выполнены
                            </Col>
                            <Col md={4} sm={6}>
                                <Label bsStyle="warning">ТОn</Label> - до работ от 6 месяцев до года
                            </Col>
                            <Col md={4} sm={6}>
                                <Label bsStyle="danger">ТОn</Label> - до работ менее 6 месяцев или они уже просрочены
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </Col>
        </Row>
    )
}

export default ChrpPlanLegend;