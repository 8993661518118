import React from 'react'; 


export function paginationTotalRenderer (from, to, dataSize){
    return (
        <div className="dataTables_info" role="status" aria-live="polite">
            <strong>{from}</strong>&ndash;<strong>{to}</strong> из&nbsp;<strong>{dataSize}</strong>
        </div>
    );
};

export function paginationButtonRenderer({page, active, disable, title, onPageChange }){
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const liClass = ["page-item"];
    if(active) {
        liClass.push("active");
    }
    if(disable){
        liClass.push("disabled")
    }
    var outpage = page;
    if( title.indexOf('next') + 1){
        outpage = (<i className={'fa fa-chevron-right'}></i>);
    } else if( title.indexOf('prev') + 1){
        outpage = (<i className={'fa fa-chevron-left'}></i>);
    } else if( title.indexOf('first') + 1){
        outpage = (<i className="fa fa-angle-double-left"></i>);
    } else if( title.indexOf('last') + 1){
        outpage = (<i className="fa fa-angle-double-right"></i>);
    }
    return (
      <li key={page} className={liClass.join(' ')}>
        <a href="#" onClick={ handleClick }>{ outpage }</a>
      </li>
    );
};