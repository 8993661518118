import React, { useState } from 'react';
import getDataRequest from '../tools/datarequest';
import { withCookies } from 'react-cookie';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import Comment from './index';


function CommentTableCell(props){
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [content, setContent] = useState(null);

    const toggleComments = event => {
        const oldshow = show;        
        setShow(!show);
        if(!oldshow){
            setContent(
                <div className="text-center" style={{height: '100px', width: '250px'}}>
                    <i className="fa fa-spinner fa-4x fa-spin"></i>
                </div>
            )
            setTarget(event.target);
           getDataRequest(
                props.cookies, `/api/${ props.source }/${ props.id }`, ''
            ).then(
                resp => resp.json()
            ).then(
                res => { setContent(<Comment items={ res.comments }/>) }
            ).catch(error => console.log('error', error));
        }              
    }

    const hideComments = evt => {        
        setShow(false);
    }

    if(props.cell == 0){
        return '';
    }
    return (
        <ButtonToolbar className="text-center">
            <span className="badge label-info comment_count" onClick={ toggleComments } >{ props.cell }</span>
            <Overlay
                show={ show }
                target={ target }
                placement="left"
                container={ this }
                containerPadding={ 20 }
                rootClose={ true }
                onHide={ hideComments }
                >
                <Popover className="comments" id={'comment-contained-' + props.id} title="Коментарии">                    
                    { content }
                </Popover>
            </Overlay>
        </ButtonToolbar>
    );
}
export default withCookies(CommentTableCell);