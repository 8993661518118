import React, { useState } from 'react';
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';
import FileSaver from 'file-saver';
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';
import { Row, Col, Button, FormGroup, ProgressBar, Alert} from 'react-bootstrap';
import ExcelDropZoneMultiple from '../tools/exceldropzonemultiple';


const BOLParser = (props) => {
    
    const [isLoading, setIsloading] = useState(false);
    const [packages, setPackages] = useState([]);
    const [parsedPercent, setParsedPercent] = useState(0);
    const [logMes, setLogMes] = useState([]);
    
    const isAllValid = () => {
        return !!packages.length;
    }

    const changeFilesHandler = (files) => {
        setPackages(files);
    }

    const buttonHandler = (ev) => {
        if(isAllValid()){
            setLogMes([]);
            sendToParsing();
        }
    }

    const sendToParsing = () => {
        let data = {'packages': packages};
        setIsloading(true);
        setParsedPercent(0);
        
        getDataRequest(
            props.cookies, '/api/packages/parse/', '', 'POST', data
        )
        .then(
            resp => {
                if(resp.status >= 500){
                    throw "Произошла непредвиденная ошибка.";
                }
                if(resp.status === 403){
                    throw "У вас нет прав на совершение данной операции.";
                }
                return resp.json()
            }
        )
        .then(res => traceProgress(res['message']))
        .catch(error => console.log(error))
    }

    const traceProgress = (code) => {
        getDataRequest(
            props.cookies, `/api/packages/parsing_progress/?key=${code}`, '', 'GET'
        )
        .then(resp => resp.json())
        .then(res => {
                let percent = parseInt(res['message']);
                setParsedPercent(percent);
                if(percent < 100){
                    setTimeout(() => {traceProgress(code)}, 3000);
                } else {
                    printLog(code);
                    downloadResult(code);
                }                
            }
        )
    }

    const printLog = (code) => {
        getDataRequest(
            props.cookies, `/api/packages/parsing_log/?key=${code}`, '', 'GET'
        )
        .then(resp => resp.json())
        .then(res => {
                let messages = res['message'];
                setLogMes(messages);
            }
        )
    }

    const downloadResult = (code) => {
        getDataRequest(
            props.cookies, `/api/packages/download_parsed/?key=${code}`, '', 'GET'
        )
        .then(resp => {
                if(resp.status >= 400){
                    throw `Error ${resp.status}`;
                }
                return resp.blob();
            }
        )
        .then(res => {
                FileSaver.saveAs(res, 'output.zip');
            }
        )
        .finally(setIsloading(false));
    }

    return (
        <>
            <PageHeader title="Парсинг паркетов ТО из BOL" subtitle="" icon="gi gi-dog"/>
            <Breadcrumbs crumbs={['Пакеты ТО', 'Парсер']}/>
            <Row>
                <Col xs={12} md={12} >
                    <div className="block full">            
                        <div className="block-title">
                            <h2>Парсинг пакетов ТО из BOL</h2>
                        </div>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <ExcelDropZoneMultiple pushFiles={ (files) => changeFilesHandler(files) }/>
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <ProgressBar active={isLoading} now={parsedPercent} label={`${parsedPercent}%`}/>
                            </Col>
                            {logMes.length > 0 &&
                                <Col xs={12}>
                                    <Alert bsStyle="warning">
                                        <h4>Обратите внимание</h4>
                                        {logMes.map((mes, i) => (
                                                <p key={i}>{mes}</p>
                                            )
                                        )}
                                    </Alert>
                                </Col>
                            }
                            <Col xs={12} className="text-center">
                                <Button bsSize="large" bsStyle="info"  disabled={ isLoading || !isAllValid() } onClick={ buttonHandler }>
                                    {isLoading ?
                                        <i className="fa fa-spinner fa-spin"></i>
                                    :
                                        <i className="fa fa-upload"></i>
                                    } Парсить
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
                
            </Row>
        </>
    )
    
};

export default withCookies(BOLParser);