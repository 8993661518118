import React from 'react';
import { NavLink } from 'react-router-dom';
import Label from 'react-bootstrap/lib/Label';
import { getWorkStatuses } from '../address/drive-status';

import moment from 'moment';
import 'moment/locale/ru'

moment.locale('ru');

export default function getPlanCellFormatter(){

    const minDate = moment().subtract(1, 'years').startOf('month');
    const maxDate = moment().add(2, 'years').endOf('month');
    const dangerEnd = moment().add(6, 'months').endOf('months');
    const warningEnd = moment().add(1, 'years').endOf('month');
    const to_kinds = getWorkStatuses().reduce((obj, it) => {
        obj[it.value] = it.label;
        return obj;
    }, {});

    return function(cell, row){
        if(cell.length && row.state === 'chose'){                        
            let items = cell.filter(it => {
                let date = moment(it['date']);                            
                return minDate <= date && date <= maxDate;
            });

            return items.reduce((out, it, i) => {
                let date = moment(it['date']);
                let mod = 'default';
                if(date <= dangerEnd){
                    mod = 'danger';
                } else if(date <= warningEnd){
                    mod = 'warning';
                }
                
                const lwk = row.last_work_kind;
                if (lwk === 'replacement' || lwk === 'retro_modern') {
                    mod = 'default';
                } else if(lwk && it.work_kind.slice(0, 2) === 'to' 
                            && parseInt(lwk.slice(2)) >= parseInt(it.work_kind.slice(2))){
                    mod = 'default';
                }

                return (
                    <React.Fragment key={`${row.id}${i}`}>
                        {out}
                        <NavLink to={`/chrp/${row.id}/${it.work_kind}`}>
                            <Label bsStyle={mod}>
                                { date.format('MMM YYYY') } { to_kinds[it.work_kind] }
                            </Label>
                        </NavLink>
                    </React.Fragment>
                    );
            }, '');                        
        }                    
        return '';
    }
}