import React from 'react';

export default function Footer(){
    return (
        <footer className="clearfix">
            <div className="pull-right">
                
            </div>
            <div className="pull-left"></div>
        </footer>
    );
}
