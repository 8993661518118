import React, { useState, useEffect } from 'react';
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';
import {
        Modal, Button, OverlayTrigger, Tooltip, Form,
        Alert, FormGroup, ControlLabel
    } from 'react-bootstrap';
import FieldGroup from '../tools/fieldgroup';
import Datetime from 'react-datetime';
import DrivesList from '../tools/driveslist.js';
import moment from 'moment';
import { getDibStatuses } from '../address/drive-status.js';
import 'moment/locale/ru'


moment.locale('ru');


function AddDibButton(props) {

    const dib_sources = getDibStatuses();
    const [show, setShow] = useState(false);
    const [date, setDate] = useState('');
    const [dateValid, setDateValid] = useState(null);
    const [message, setMessage] = useState('');
    const [source, setSource] = useState(dib_sources[0]['value']);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false)
    const [drives, setDrives] = useState([]);

    useEffect(() => {        
        const getDrivesData = async () => {
            let dr = props.getDrives();
            setDrives(dr);
        };  
        show && getDrivesData();
     }, [show]);
    
    const isAllValid = () => {
        let key = 'success';
        return dateValid === key && drives.length;
    }

    const handleClose = () => {      
        setShow(false);
        setDate('');
        setDateValid(null);
        setMessage('');
        setSource(dib_sources[0]['value']);
    }
    
    const handleShow = evt => {
        evt.currentTarget.blur();
        setShow(true);
    }

    const getDateState = (value) => {
        let valstate = null;
        if(value === ''){
            valstate = null;
        } else if(/^\d\d\.\d\d\.\d\d\d\d$/.test(value)){
            valstate = 'success';
        } else {
            valstate = 'error';
        }
        return valstate;
    }

    const changeDateHandler = (date) => {
        var outdate = typeof date !== 'string'? date.add(12, 'hours').format('L') : '';
        if(outdate !== date){
            setDate(outdate);
            setDateValid( getDateState(outdate) );
        }
    }

    const messageHandler = evt => {
        setMessage(evt.target.value);
    }

    const dibSourceHandler = evt => {
        let val = evt.target.value;
        setSource(evt.target.value);
        if(val !== dib_sources[0]['value']){
            setDateValid('success');
        } else {
            setDateValid( getDateState(date) );
        }
    }

    const sendRequest = (evt) => {
        let dt = moment(date, "L").format('YYYY-MM-DD');
        let data = {
            ids: drives.map(d => d.id),
            date: dt,
            message: message,
            source: source
        };
        
        setLoading(true);
        setErrors([]);

        getDataRequest(
            props.cookies, `/api/drives/adddib/`, '', 'POST', data
        ).then(
            resp => resp.json()   
        ).then(
            res => {  
                props.onAdd(data.ids);         
            }
        ).catch(error => {            
                console.log('error', error);
            }
        ).finally(
            () => {
                setLoading(false);
                handleClose();
            }
        );
    }


    return (
        <>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Добавить регистрацию в DIB</Tooltip>}>
            <a href="#" className="btn btn-alt btn-sm btn-default" onClick={ handleShow }>
                <i className="gi gi-pushpin"></i>
            </a>
        </OverlayTrigger>
        <Modal show={show} onHide={ handleClose }>
        <Modal.Header closeButton>
            <Modal.Title>Добавление даты регистрации в DIB</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                {errors.map((error, i) => (
                        <Alert key={i} bsStyle='danger' >{ error }</Alert>
                    )
                )}
                {!drives.length &&
                    <Alert bsStyle='danger' >Внимание, не выбрано ни одного привода!</Alert>
                }
                <FieldGroup componentClass="select"
                    value={ source }
                    onChange={ dibSourceHandler }
                    validationState={ null }
                    label="Кем зарегистрирован"
                >
                    { dib_sources.map((s, i) => (<option key={ i } value={ s.value }>{ s.label }</option>)) }
                </FieldGroup>
                {source === dib_sources[0]['value'] &&
                    <FormGroup controlId="" validationState={ dateValid||null }>
                        <ControlLabel>Дата регистрации в DIB</ControlLabel>
                        <Datetime 
                            locale="ru"
                            timeFormat={ false }
                            onChange={ changeDateHandler }
                            closeOnSelect={ true }
                            value={ date }
                        />                    
                    </FormGroup>
                }
                
                <FieldGroup 
                    id="formControlsMessage"
                    label="Сообщение"
                    componentClass="textarea"
                    value={ message }
                    onChange={ messageHandler }
                    validationState={ null }
                />
                
                <DrivesList drives={ drives }/>                
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={ handleClose }>Отменить</Button>
            <Button bsStyle="primary" onClick={ sendRequest } disabled={ !isAllValid() || loading }>Добавить</Button>
        </Modal.Footer>
        </Modal>
        
        </>
    );
}
export default withCookies(AddDibButton);