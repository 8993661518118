import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import Header from './components/header';
import Sidebar from './components/sidebar';
import Footer from './components/footer';
import Home from './components/home';
import Page404 from './components/404';
import SparePartsList from './components/spare_parts/list';
import SparePartsImport from './components/spare_parts/import';
import MPackagesList from './components/m_packages/list';
import MPackagesImport from './components/m_packages/import';
import MPackagesPartsList from './components/m_packages/parts-list';
import BOLParser from './components/m_packages/parser';
import SparePartComplectsList from './components/spare_part_complects/list';
import AddressList from './components/address/list';
import AddressDetail from './components/address/detail';
import DriveImport from './components/address/import';
import UsersList from './components/user/list';
import Profile from './components/user/profile';
import ChrpDetail from './components/chrp/detail';
import ChrpList from './components/chrp/list';
import getDataRequest from './components/tools/datarequest';
import ProxyChild from './components/tools/proxyrouterchild';
import avatar from './img/placeholders/avatars/avatar.jpg';

import './styles/app.less';

class App extends Component{

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
 
    const { cookies } = props;

    this.state = {
        sidebarMode: 'sidebar-visible-lg',
        activeAccordeonCell: null,
        token: cookies.get('token'),
        brands: [],
        user: {
          first_name: 'Имя',
          last_name: 'Фамилия',
          photo_icon: avatar,
          photo_thumbnail: avatar,
          permissions: []
        }
    };
  }

  getWindowWidth = () => {
    return window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
  }

  componentDidMount(){
      this.userRequest();
      this.brandsRequest();
  }

  brandsRequest = () => {        
    getDataRequest(
        this.props.cookies, '/api/brands/', ''
    ).then(
        resp => resp.json()
    ).then(
        res => this.setState({brands: res})
    ).catch(error => console.log('error', error));
  }

  callSidebar = mode => {    
    var windowW = this.getWindowWidth();
    mode = mode || 'toggle-sidebar';
    
    if (mode === 'close-sidebar' || (mode === 'toggle-sidebar' && this.state.sidebarMode) ) {
      this.setState({sidebarMode: ''});
    } else {
      if ( windowW > 991) {
        this.setState({sidebarMode: 'sidebar-visible-lg'});
      } else {
        this.setState({sidebarMode: 'sidebar-visible-xs'});         
      }
    }
  }

  prepareProfile = rawdata => {
    let user = {...rawdata};
    ['icon', 'thumbnail'].forEach((size) => {
      if(user['photo_' + size]){
        let icon = user['photo_' + size];
        user['photo_' + size] = `${process.env.REACT_APP_API_URL}${icon}`
      } else {
        user['photo_' + size] = avatar;
      } 
    });
    return user;
  }

  userRequest = () => {
    const { cookies } = this.props;
    getDataRequest(
        cookies, '/api/users/myself/', ''
    ).then(
        resp => resp.json()
    ).then(
        res => this.setState({ user: this.prepareProfile(res) })
    ).catch(error => console.log('error', error));
  }

  fireChangeProfile = (data) => {
    let newuser = this.prepareProfile(data);
    newuser.permissions = this.state.user.permissions;
    this.setState({ user: newuser } );
  }

  hasPermission = (permission) => {
    return !!(this.state.user.permissions.indexOf(permission) + 1);
  }

  render(){
    const routes = [      
      {name: "spare_parts", path: "/spare_parts", exact: true, component: SparePartsList},
      
      {name: "spare_parts_import", path: "/spare_parts/import", exact: true, component: SparePartsImport, 
        props: {user: this.state.user, brands: this.state.brands}, permission: 'import_sparepart'},

      {name: "spare_parts_complects", path: "/spare_part_complects", exact: true, component: SparePartComplectsList},
      
      {name: "packages", path: "/packages", exact: true, component: MPackagesList},

      {name: "packages_import", path: "/packages/import", exact: true, component: MPackagesImport,
        props: {brands: this.state.brands}, permission: 'import_maintenance_package'},
      
      {name: "packages_parse", path: "/packages/parse", exact: true, component: BOLParser,
        props: {brands: this.state.brands}, permission: 'parse_bol'},

      {name: "packages_parts", path: "/packages/:mpId", exact: true, component: MPackagesPartsList,
        props: {brands: this.state.brands}
      },

      {name: "packages_parts_filtered", path: "/packages/:mpId/:pmNum", exact: true, component: MPackagesPartsList,
        props: {brands: this.state.brands}
      },
      
      {name: "clients", path: "/clients", exact: true, component: AddressList, permission: 'change_client',
        props: {user: this.state.user}
      },      
      {name: "drives_add", path: "/clients/add_drives", exact: true, component: DriveImport, permission: 'change_client',
        props: {user: this.state.user, brands: this.state.brands}
      },
      {name: "drives", path: "/clients/:addressId", exact: true, component: AddressDetail, permission: 'change_client',
        props: {user: this.state.user, brands: this.state.brands}
      },

      {name: "chrp", path: "/chrp", exact: true, component: ChrpList, permission: 'select_drive_sp',
        props: {user: this.state.user, brands: this.state.brands}
      },

      {name: "chrp_details", path: "/chrp/:driveId", exact: true, component: ChrpDetail,
        props: {user: this.state.user, brands: this.state.brands}
      },
      {name: "chrp_details_filtered", path: "/chrp/:driveId/:toFilter", exact: true, component: ChrpDetail,
        props: {user: this.state.user, brands: this.state.brands}
      },     
      
      {name: "users", path: "/users", exact: true, component: UsersList,
        props: {currentUser: this.state.user, onChangeMyself: this.fireChangeProfile}, permission: 'change_user'},
  
      {name: "profile", path: "/profile", exact: true, component: Profile,
          props: {user: this.state.user, onChangeProfile: this.fireChangeProfile}},

      {name: "home", path: "/", exact: true, component: Home},
    ].filter(route => (!route.permission || this.hasPermission(route.permission)));
    
    let allowed_path = [];
    routes.forEach( route => { allowed_path[route.name] = route.path });
  
    if(!this.state.token){
      // Redirect anonymous users
      return (<Redirect to="/login"/>);
    }

    return (
      <BrowserRouter>
      
      <div id="page-container" className={'sidebar-partial sidebar-no-animations ' + this.state.sidebarMode}>
        
        <Sidebar user={ this.state.user } allowed={ allowed_path }/>
      
        <div id="main-container">
            <Header toggleSidebar={this.callSidebar} user={ this.state.user }/>
            
            {this.state.user.permissions.length?
              <div id="page-content">            
                <Switch>
                  {routes.map((route, index) => (
                      <Route
                        key={ index }
                        path={ route.path }
                        exact={ route.exact }                      
                        children={ <ProxyChild component={route.component} props={route.props}/> }              
                      />
                    ))
                  }
                  
                  <Route key="p404" component={ Page404 }/>
                </Switch>            
              </div>
              :
              <div id="page-content">
                <div id="error-container">            
                    <div className="row">
                        <div className="col-sm-8 col-sm-offset-2 text-center">
                            <div className="animation-pulse"><i className="fa fa-spinner fa-spin fa-4x"></i></div>
                            <h2 className="h3">...</h2>
                        </div>                
                    </div>
                </div>                
              </div>

            }

            <Footer/>
        </div>
        
      </div>
      </BrowserRouter>
    );
  }
}

export default withCookies(App);