import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { withCookies } from 'react-cookie';
import UserInfo from './userinfo';
import AccordeonCell from './accordeoncell.js';
import logo from '../../img/logo.svg';


function Sidebar(props) {

    const [activeCell, setActiveCell] = useState(null)

    const accordeonClick = (value, evt) => {        
        evt.preventDefault();
        if(activeCell === value){          
            setActiveCell(null);
        } else {
            setActiveCell(value);
        }    
    }

    const reinitActiveCell = () => {
        const variants = ['emptycell1', 'emptycell2'];
        setActiveCell(activeCell === variants[0] ? variants[1]: variants[0]);        
    }
   
    const is_allowed = (name) => {
        return props.allowed.hasOwnProperty(name);
    }

    const get_path = (name) => {
        return props.allowed[name];
    }

    function SidebarLink(props){
        return (<NavLink exact activeClassName="active" onClick={ reinitActiveCell } {...props}/>);
    }

    return (
        <div id="sidebar">
          <div id="sidebar-scroll">
            <div className="sidebar-content">
              
                <NavLink to="/" className="sidebar-brand" onClick={ reinitActiveCell }>
                    <img className="logo" src={logo} />
                </NavLink>
                
                <UserInfo user={ props.user }/>

                <ul className="sidebar-nav">
                    {is_allowed('users') &&
                        <li>
                            <SidebarLink to={ get_path('users') }><i className="gi gi-group sidebar-nav-icon"></i>
                            <span className="sidebar-nav-mini-hide">Пользователи</span></SidebarLink>
                        </li>
                    }
                    
                    {is_allowed('spare_parts_import')?
                        <li>
                            <AccordeonCell 
                                name='spare_parts'
                                open={ 'spare_parts' === activeCell }
                                text="Прайс ЗИП"
                                icon="gi gi-cogwheel"
                                onClick={ accordeonClick.bind(this, 'spare_parts') }                                
                            />
                            <ul> 
                                <li><SidebarLink to={ get_path('spare_parts') }>Весь прайс ЗИП</SidebarLink></li>                                
                                <li><SidebarLink to={ get_path('spare_parts_import') }>Импорт</SidebarLink></li>
                            </ul>
                        </li>
                        :
                        <li><SidebarLink to={ get_path('spare_parts') }><i className="gi gi-cogwheel sidebar-nav-icon"></i>
                            <span className="sidebar-nav-mini-hide">Прайс ЗИП</span></SidebarLink>
                        </li>
                    }
                    
                    {is_allowed('packages_import')?
                        <li>
                            <AccordeonCell 
                                name='packages'
                                open={ 'packages' === activeCell }
                                text="Комплекты ТО"
                                icon="gi gi-table"
                                onClick={ accordeonClick.bind(this, 'packages') }
                                
                            />
                            
                            <ul>
                                <li><SidebarLink to={ get_path('packages') }>Все комплекты</SidebarLink></li>
                                <li><SidebarLink to={ get_path('packages_import') }>Импорт</SidebarLink></li>
                                {is_allowed('packages_parse') &&
                                    <li><SidebarLink to={ get_path('packages_parse') }>Парсер bol</SidebarLink></li>
                                }                                
                            </ul>
                        </li>
                        :
                        <li><SidebarLink to={ get_path('packages') }><i className="gi gi-table sidebar-nav-icon"></i>
                            <span className="sidebar-nav-mini-hide">Комплекты ТО</span></SidebarLink>
                        </li>
                    }
                    {is_allowed('clients') &&
                        <li>
                            <AccordeonCell 
                                name='clients'
                                open={ 'clients' === activeCell }
                                text="Клиенты"
                                icon="gi gi-suitcase"
                                onClick={ accordeonClick.bind(this, 'clients') }                                
                            />

                            <ul>
                                <li><SidebarLink to={ get_path('clients') }>Таблица клиентов</SidebarLink></li>
                                <li><SidebarLink to={ get_path('drives_add') }>Добавить приводы</SidebarLink></li>
                            </ul>
                        </li>
                    }
                    {is_allowed('chrp') &&
                        <li>
                            <SidebarLink to={ get_path('chrp') }><i className="fa fa-server sidebar-nav-icon"></i>
                            <span className="sidebar-nav-mini-hide">ЧРП</span></SidebarLink>
                        </li>
                    }
                </ul>
            </div>
          </div>
        </div>
    );
    
}
export default withCookies(Sidebar);