import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import PageHeader from '../page_header';
import Breadcrumbs from '../breadcrumbs';
import { Row, Col} from 'react-bootstrap';
import MPackagesImportBody from './import-body';


class MPackagesImport extends Component{

    render(){
    
        return (
            <>
                <PageHeader title="Импорт пакетов ТО" subtitle="Форма импорта пакетов ТО" icon="gi gi-file_import"/>
                <Breadcrumbs crumbs={['Пакеты ТО', 'Импорт']}/>
                <Row>
                    <Col xs={12} md={12} >
                        <div className="block full">            
                            <div className="block-title">
                                <h2>Импорт пакетов ТО</h2>
                            </div>
                            <MPackagesImportBody brands={ this.props.brands }/>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

export default withCookies(MPackagesImport);