import React, { useState } from 'react';
import { withCookies } from 'react-cookie';
import getDataRequest from '../tools/datarequest';
import {
        Modal, Button, OverlayTrigger, Tooltip, Form,Alert
    } from 'react-bootstrap';
import { getPasswordState, getConfirmPasswordState } from './passvalidator';
import FieldGroup from '../tools/fieldgroup';


function AddUserButton(props) {

    const [show, setShow] = useState(false);
    
    const [username, setUsername] = useState('');
    const [usernameValid, setUsernameValid] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(null);
    const [confirm, setConfirm] = useState('');
    const [confirmValid, setConfirmValid] = useState(null);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false)

    const isAllValid = () => {
        let key = 'success';
        return usernameValid === key && passwordValid === key && confirmValid === key;
    }

    const handleClose = () => {      
        setShow(false);
        setUsername('');
        setUsernameValid(null);
        setPassword('');
        setPasswordValid(null);
        setConfirm('');
        setConfirmValid(null);
    }
    
    const handleShow = evt => {
        evt.currentTarget.blur();
        setShow(true);
    }

    const getUsernameState = (value) => {
        let valstate = null;
        if(value === ''){
            valstate = null;
        } else if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)){
            valstate = 'success';
        } else {
            valstate = 'error';
        }
        return valstate;
    }

    const usernameHandler = evt => {        
        setUsername(evt.target.value);
        setUsernameValid( getUsernameState(evt.target.value) );
    }

    const passwordHandler = evt => {    
        setPassword( evt.target.value );
        setPasswordValid( getPasswordState(evt.target.value) );
        setConfirmValid( getConfirmPasswordState(confirm, evt.target.value) ); 
    }

    const confirmHandler = evt => {        
        setConfirm( evt.target.value );
        setConfirmValid( getConfirmPasswordState(evt.target.value, password) );
    }

    const addRequest = (evt) => {
        let data = {username, password, is_active: true};
        setLoading(true);
        setErrors([]);
        getDataRequest(
            props.cookies, `/api/users/`, '', 'POST', data
        ).then(
            resp => resp.json()   
        ).then(
            res => {
                if(res.hasOwnProperty('id')){
                    props.onAddUser(res);
                    handleClose();
                } else {                       
                    let ers = [];
                    for(let i in res){
                        ers.push(res[i]);
                    }
                    setErrors(ers); 
                }
                setLoading(false);
            }
        ).catch(error => {
            setLoading(false);
            console.log('error', error)
        });
    }

    return (
        <>
        <OverlayTrigger placement='left' overlay={<Tooltip id='tooltip-adduser'>Добавить пользователя</Tooltip>}> 
        <a href="#" className="btn btn-alt btn-sm btn-default" onClick={ handleShow }>
            <i className="gi gi-user_add"></i>
        </a>
        </OverlayTrigger>
        <Modal show={show} onHide={ handleClose }>
        <Modal.Header closeButton>
            <Modal.Title>Создание нового пользователя</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                {errors.map((error, i) => (
                        <Alert key={i} bsStyle='danger' >{ error }</Alert>
                    )
                )}
                <FieldGroup
                    id="formControlsEmail"
                    type="email"
                    label="Email"
                    placeholder="Введите email сотрудника"
                    value={ username }
                    onChange={ usernameHandler }
                    validationState={ usernameValid }
                />
                <FieldGroup 
                    id="formControlsPassword"
                    label="Пароль"
                    type="password"
                    value={ password }
                    onChange={ passwordHandler }
                    validationState={ passwordValid }
                    help="Пароль должен быть длиной не менее 9 символов,
                            содержать минимум одну цифру и символы разного регистра"
                />
                <FieldGroup 
                    id="formControlsPasswordConfirm"
                    label="Подтверждение пароля"
                    type="password"
                    value={ confirm }
                    onChange={ confirmHandler }
                    validationState={ confirmValid }
                />
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={ handleClose }>Отменить</Button>
            <Button bsStyle="primary" onClick={ addRequest } disabled={ !isAllValid() || loading }>Создать</Button>
        </Modal.Footer>
        </Modal>
        
        </>
    );
}
export default withCookies(AddUserButton);