/**
 * Rerurn bootstrap form group state for password field
 * 
 * @param {string} value
 */
export function getPasswordState(value){
    let valstate = null;
    let smallet = value.toLowerCase().replace(/[^a-za-яёA-ZА-ЯЁ]/g, '');
    let upperlet = smallet.toUpperCase();
    if(value === ''){
        valstate = null;
    } else if(!/^.*\d.*$/.test(value)){//check numbers
        valstate = 'error';
    } else if(value.length < 9){//check length
        valstate = 'error';
    } else {
        valstate = 'success';
    }
    //check upper and small case
    let hassmall = false, hasupper = false; 
    for(let i in value){
        hassmall = hassmall || !!(smallet.indexOf(value[i]) + 1);
        hasupper = hasupper || !!(upperlet.indexOf(value[i]) + 1);
        if(hasupper && hassmall) break;
    }
    if(!hassmall || !hasupper){
        valstate = 'error';
    }
    return valstate;
}

/**
 * Return bootstrap form group state for password confirm field
 * 
 * @param {string} value 
 * @param {string} pass 
 */
export function getConfirmPasswordState(value, pass){
    let valstate = null;
    if(value === ''){
        valstate = null;
    } else if(value === pass){
        valstate = 'success';
    } else {
        valstate = 'error';
    }
    return valstate;
}